<template>
  <transition
    enter-active-class="transition-transform md:transition duration-200"
    leave-active-class="transition-transform md:transition duration-150"
    enter-from-class="translate-y-full md:translate-y-1/2 md:scale-150 md:opacity-0"
    leave-to-class="translate-y-full md:translate-y-1/2 md:scale-150 md:opacity-0"
  >
    <slot />
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ModalTransition',
})
</script>
