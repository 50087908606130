<template>
  <td :class="{ 'px-1 sm:px-2 py-1': !compact, 'p-1 text-sm': compact }">
    <slot />
  </td>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TableCell',
  props: {
    compact: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
