import { App, ref } from 'vue'

const show = ref(false)
const message = ref('')

const alert = (alertMessage: string) => {
  message.value = alertMessage
  show.value = true
}

export default {
  install: (app: App) => {
    app.config.globalProperties.$alert = alert
  },
}

export function useAlert() {
  return alert
}

export function useAlertItems() {
  return { show, message }
}
