<template>
  <Button :icon="$icons.DELETE" class="text-red-600">
    <slot>Delete</slot>
  </Button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CloseButton',
})
</script>

<style scoped></style>
