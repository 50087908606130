import { AxiosResponse } from 'axios'
import { App, computed, inject, Ref, ref } from 'vue'
import LogRocket from 'logrocket'
import User from '~/database/models/User'
import { useDatabase } from './database'
import Storage from '~/assets/constants/Storage'
import Model from '~/database/Model'

interface LoginPayload {
  email: string
  password: string
}

export interface AuthUser extends User {
  permissions: {
    [key: string]: {
      create: boolean
      view: boolean
      edit: boolean
      delete: boolean
    }
  }
}

const user: Ref<AuthUser | null> = ref(null)

const gettingUser = ref(false)

async function getUser(force = false) {
  const database = useDatabase()
  if (!force && user.value?.id) return user.value
  gettingUser.value = true
  if (!force && database.connection.offline.value) {
    const localUser: AuthUser | undefined = await Storage.local.getItem('user')
    gettingUser.value = false
    if (!localUser) return
    user.value = localUser
    return user.value
  }
  const response: void | AxiosResponse = await window.$axios
    .get('/api/user')
    .catch((error) => console.warn(error))
  if (!response || response.status != 200) {
    database.connection.goOffline()
    if (force) return false
    const localUser: AuthUser | undefined = await Storage.local.getItem('user')
    gettingUser.value = false
    if (!localUser) return
    user.value = localUser
    return user.value
  }
  if (response && response?.data) user.value = <AuthUser>response.data
  if (user.value?.id && import.meta.env.PROD) {
    LogRocket.identify(user.value.id, {
      name: user.value.name,
      email: user.value.email,
      role: user.value.role,
    })
  }
  gettingUser.value = false
  Storage.local.setItem('user', user.value)
  return user.value
}

async function getToken() {
  const response: void | AxiosResponse = await window.$axios
    .get('/sanctum/csrf-cookie')
    .catch((error) => console.warn(error))
}

async function login(payload: LoginPayload) {
  const response = await window.$axios.post('/login', payload)
  if (
    response?.status == 200 ||
    response?.status == 204 ||
    response.status == 302
  ) {
    return true
  }
  return false
}

async function logout() {
  const database = useDatabase()
  await database.pendingRequests.clear()
  await database.deleteDatabase()
  localStorage.removeItem('preferrences')
  localStorage.removeItem('offline-sync-settings')
  localStorage.removeItem('auth')
  const response = await window.$axios.post('/logout')
  user.value = null
  window.location.replace(`${import.meta.env.VITE_APP_URL}/#/welcome`)
}

const auth = {
  getUser,
  login,
  logout,
  getToken,
  gettingUser,
}

function can(
  action: 'create' | 'edit' | 'view' | 'delete',
  resource: string,
  model: Model | null = null,
) {
  if (model && 'user_id' in model && model.user_id == user.value?.id) {
    return true
  }
  const permissions = user.value?.permissions
  return permissions && permissions[resource] && permissions[resource][action]
}

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $auth: typeof auth
    $can: typeof can
    $guest: typeof guest
  }
}

const guest = computed(() => {
  return !user.value?.id
})

export const authInstall = {
  install: (app: App) => {
    app.config.globalProperties.$auth = auth
    app.config.globalProperties.$can = can
    app.config.globalProperties.$guest = guest
    app.provide('$auth', auth)
    app.provide('$user', user)
    app.provide('$can', can)
    // auth.getUser()
  },
}

export function useAuth() {
  return auth
}

export function useUser() {
  return user
}

export function useCan() {
  return can
}

export function useGuest() {
  return guest
}
