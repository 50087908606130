import { ref } from 'vue'
import { SafeParseError } from 'zod'
import { useAlert } from '~/plugins/alert'

export default function useErrorHandling(
  errorFields: string[] | undefined = undefined,
) {
  const errors = ref(
    !errorFields
      ? {}
      : errorFields.reduce((errors: Record<string, string>, key) => {
          return {
            ...errors,
            [key]: '',
          }
        }, {}),
  )
  const alert = useAlert()
  function handleLocalErrors(error: SafeParseError<any>['error']) {
    if (!error) return
    if (!error.issues?.length) {
      throw error
    }
    let message = ''
    error.issues.forEach((issue) => {
      const error = errors.value[issue.path[0]]
      if (!error && issue.path[0] in errors.value) {
        errors.value[issue.path[0]] = issue.message
      } else if (!error) {
        message += `\n ${issue.path[0]}: ${issue.message}`
      }
      if (message.length) {
        alert(message)
      }
    })
  }
  function handleApiErrors(error: any) {
    console.warn(error)
    if (error.response) {
      let response = error.response
      let status = response.status
      const data = <any>response?.data
      const message: string = <string>data?.message || ''
      if (status == 403 && message) return alert(message)
      const responseErrors = data?.errors
      if (responseErrors && Object.keys(errors.value)?.length) {
        let messageErrors = ''
        Object.keys(responseErrors).forEach((key) => {
          if (!errors?.value) return
          if (!(key in errors.value)) {
            messageErrors += `\n${responseErrors[key][0]}`
          }
          errors.value[key] = responseErrors[key][0]
        })
        if (messageErrors) alert(messageErrors)
        return
      }
      if (responseErrors) {
        const message = Object.keys(responseErrors)
          .map((key) => {
            return responseErrors[key][0]
          })
          .join('\n')
        if (message) {
          alert(message)
          return
        }
      }
      if (message) {
        alert(message)
      }
    }
  }
  return {
    handleLocalErrors,
    handleApiErrors,
    errors,
  }
}
