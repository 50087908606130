<template>
  <button
    class="rounded flex flex-row items-center font-semibold"
    :class="{
      'bg-secondary': !red,
      'bg-red-600': red,
      'text-lg': !small,
      'text-base': small,
      'py-2 px-4': !inline && !small,
      'py-1 px-2': small,
      'p-1': inline,
      'bg-opacity-0 focus:bg-opacity-25': !raised,
      'hover:bg-opacity-10': !raised && !disabled,
      'text-on-secondary border-2 focus:border-black': raised,
      'hover:border-black': raised && !disabled,
      'text-gray-500 cursor-default': disabled,
      'transition-colors duration-300 ease-in-out': !disabled,
    }"
    :disabled="disabled"
    ref="button"
  >
    <Icon
      v-if="icon && iconLeft"
      :class="{
        'text-xl': small,
        'text-tertiary': !raised,
        'text-on-secondary': raised,
        'text-gray-600': disabled,
      }"
      >{{ icon }}</Icon
    >
    <span class="mx-1">
      <slot />
    </span>
    <Icon
      v-if="icon && !iconLeft"
      :class="{
        'text-xl': small,
        'text-tertiary': !raised,
        'text-on-secondary': raised,
        'text-gray-600': disabled,
      }"
      >{{ icon }}</Icon
    >
  </button>
</template>

<script lang="ts">
import { ref, defineComponent } from 'vue'
export default defineComponent({
  name: 'Button',
  props: {
    icon: {
      type: String,
      default: '',
    },
    inline: {
      type: Boolean,
      default: false,
    },
    raised: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Boolean,
      default: false,
    },
    iconLeft: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    red: {
      type: Boolean,
      default: false,
    },
  },
  // mounted() {
  //   if (this.focus) {
  //     this.$nextTick(() => {
  //       this.$nextTick(() => this.$refs.button.focus())
  //     })
  //   }
  // },
})
</script>

<style scoped>
button:focus {
  outline: none;
}
</style>
