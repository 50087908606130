<template>
  <span
    class="flex flex-col justify-center"
    :style="{
      width: !placeholder ? 'fit-content' : small ? '40px' : '56px',
      height: !placeholder ? '' : small ? '40px' : '56px',
    }"
  >
    <button
      v-if="!placeholder"
      class="material-icons rounded-full bg-opacity-0 bg-secondary"
      :class="{
        'hover:bg-opacity-10 focus:bg-opacity-25 transition-colors duration-300 ease-in-out cursor-pointer':
          !disabled,
        'text-gray-500 cursor-default': disabled,
        'p-4': !large && !small && !extraSmall,
        'large p-3': large,
        'p-2': small,
        'p-1 extra-small': extraSmall,
      }"
      :disabled="!!disabled"
    >
      {{ icon }}
    </button>
    <span
      v-if="$slots.default"
      class="text-center text-xs -mt-3"
      :class="{ 'cursor-pointer': !disabled, 'text-gray-500': disabled }"
    >
      <slot />
    </span>
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'IconButton',
  props: {
    raised: {
      type: [Boolean, Number],
      default: false,
    },
    large: {
      type: [Boolean, Number],
      default: false,
    },
    small: {
      type: [Boolean, Number],
      default: false,
    },
    extraSmall: {
      type: [Boolean, Number],
      default: false,
    },
    disabled: {
      type: [Boolean, Number],
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    placeholder: {
      type: Boolean,
      default: false,
    },
  },
  setup: () => {},
})
</script>

<style scoped>
.material-icons {
  font-size: 24px;
}

.material-icons.large {
  font-size: 36px;
}

.material-icons.extra-small {
  font-size: 16px;
}

button:focus {
  outline: none;
}
</style>
