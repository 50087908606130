<template>
  <tr class="border-t border-gray-300 hover:bg-gray-100 cursor-pointer">
    <slot />
  </tr>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TableRow',
})
</script>
