import { IDBPDatabase, IDBPTransaction, IDBPObjectStore, openDB } from 'idb'
import { ref, Ref } from 'vue'
import Model from '../Model'
import models from '../models'
import { dbName, dbVersion } from '../constants'
import { AxiosError } from 'axios'
import { connection } from './connection'
import debounce from 'just-debounce-it'
import { array } from 'zod'

export const PENDING_REQUESTS = 'pending-requests'
export const PENDING_REQUEST_ERRORS = 'pending-request-errors'

export interface PendingRequestQueueItem<T extends Model> {
  item: Partial<T> | Partial<T>[]
  modelName: string
  method: 'post' | 'patch' | 'delete'
  key: string
}

export interface PendingRequestError<T extends Model>
  extends PendingRequestQueueItem<T> {
  error: AxiosError
}

class PendingRequests {
  syncing: Ref<boolean> = ref(false)
  hasErrors: Ref<boolean> = ref(false)
  db: IDBPDatabase | null = null

  async init() {
    const errors = await this.getPendingRequestErrors()
    if (errors.length) {
      this.hasErrors.value = true
    }
    this.performPendingRequests()
  }

  async getDB(): Promise<IDBPDatabase> {
    if (this.db) return this.db
    this.db = await openDB(dbName, dbVersion)
    return this.db
  }

  getModelClass(modelName: string): typeof Model | undefined {
    const model = models.find((model) => {
      return model.name == modelName
    })
    if (!model) {
      console.warn(`Model ${modelName} not found`)
      return undefined
    }
    return model
  }

  async addItemToPendingRequestsQueue<T extends Model>(
    payload: PendingRequestQueueItem<T>,
  ) {
    if (Array.isArray(payload.item)) {
      return this.addStoreManyRequestToPendingRequestsQueue(payload)
    } else {
      return this.addSingleItemToPendingRequestsQueue(payload)
    }
  }

  async addSingleItemToPendingRequestsQueue<T extends Model>(
    payload: PendingRequestQueueItem<T>,
  ) {
    if (Array.isArray(payload.item)) return
    //if item has not been saved to db we do not need to add to pending requests
    if (
      payload.method == 'delete' &&
      typeof payload.item.id == 'string' &&
      payload.item.id.includes('temp')
    ) {
      this.removePendingRequest(payload)
      return
    }
    const db = await this.getDB()
    const tx = db.transaction(PENDING_REQUESTS, 'readwrite')
    const store = tx.objectStore(PENDING_REQUESTS)
    const model = this.getModelClass(payload.modelName)
    if (!model) return
    const pendingRequest = {
      ...payload,
    }
    await this.removePendingRequestFromStoreManyRequest(payload, store)
    await store.put(pendingRequest)
    await tx.done
    this.performPendingRequests()
  }

  async addStoreManyRequestToPendingRequestsQueue<T extends Model>(
    payload: PendingRequestQueueItem<T>,
  ) {
    if (!Array.isArray(payload.item)) return
    const db = await this.getDB()
    const tx = db.transaction(PENDING_REQUESTS, 'readwrite')
    const store = tx.objectStore(PENDING_REQUESTS)
    const model = this.getModelClass(payload.modelName)
    if (!model) return
    //get existing store-many request if we are not saving images
    console.log('payload.modelName', payload.modelName)
    const existingStoreManyRequest: PendingRequestQueueItem<T> | null =
      payload.modelName == 'Image'
        ? null
        : await store.get(`${model.name}-store-many`)
    const existingItems =
      existingStoreManyRequest?.item &&
      Array.isArray(existingStoreManyRequest.item)
        ? existingStoreManyRequest.item
        : []
    //combine items
    let items = [...payload.item, ...existingItems].reduce(
      (items: Partial<T>[], item) => {
        if (
          !items.find(
            (i) =>
              i[model.primaryKey] &&
              i[model.primaryKey] == item[model.primaryKey],
          )
        ) {
          items.push(item)
        }
        return items
      },
      [],
    )
    //check for any items that are queued independtly, update them, and remove them from the store-many request
    if (payload.modelName != 'Image') {
      const pendingRequests = await this.getPendingRequests(tx)
      const promises = pendingRequests.map(async (request) => {
        if (request.key != `${model.name}-store-many`) {
          const storeManyItem = items.find((item) => {
            return item[model.primaryKey] == request.item[model.primaryKey]
          })
          if (storeManyItem) {
            request = {
              ...request,
              item: {
                ...request.item,
                ...storeManyItem,
              },
            }
            await store.put(request)
            items = items.filter((i) => {
              return i[model.primaryKey] != request.item[model.primaryKey]
            })
          }
        }
      })
      await Promise.all(promises)
    }
    const key =
      payload.modelName == 'Image'
        ? `Image-store-many-${new Date().valueOf()}-${Math.random()}`
        : `${model.name}-store-many`
    if (items?.length) {
      const pendingRequest =
        existingStoreManyRequest && Array.isArray(existingStoreManyRequest.item)
          ? {
              ...existingStoreManyRequest,
              ...payload,
              key: key,
              item: items,
            }
          : {
              ...payload,
              key: key,
              item: items,
            }
      await store.put(pendingRequest)
    }
    await tx.done
    this.performPendingRequests()
  }

  async checkIfItemIsInStoreManyRequest<T extends Model>(
    payload: PendingRequestQueueItem<T>,
    store: IDBPObjectStore<
      unknown,
      [typeof PENDING_REQUESTS],
      typeof PENDING_REQUESTS,
      'readwrite'
    >,
  ) {
    const model = this.getModelClass(payload.modelName)
    if (model && !payload.key.includes('store-many')) {
      //remove item from store-many request
      const storeManyKey = `${payload.modelName}-store-many`
      const storeManyRequest: PendingRequestQueueItem<T> = await store.get(
        storeManyKey,
      )
      const payloadItem = payload.item
      if (
        storeManyRequest &&
        Array.isArray(storeManyRequest.item) &&
        !Array.isArray(payloadItem)
      ) {
        storeManyRequest.item = storeManyRequest.item.filter((item) => {
          return item[model.primaryKey] != payloadItem[model.primaryKey]
        })
      }
      await store.put(storeManyRequest)
    }
  }

  async getPendingRequests(
    passedInTransaction: IDBPTransaction<
      unknown,
      [typeof PENDING_REQUESTS],
      'readwrite' | 'readonly'
    > | null = null,
  ) {
    const transaction =
      passedInTransaction ||
      (await this.getDB()).transaction(PENDING_REQUESTS, 'readonly')
    const store = transaction.objectStore(PENDING_REQUESTS)
    const pendingRequests = await store.getAll()
    if (!passedInTransaction) await transaction.done
    return pendingRequests
  }

  async getPendingRequestErrors() {
    const db = await this.getDB()
    const tx = db.transaction(PENDING_REQUEST_ERRORS, 'readonly')
    const store = tx.objectStore(PENDING_REQUEST_ERRORS)
    const pendingRequests = await store.getAll()
    await tx.done
    return pendingRequests
  }

  async removePendingRequest<T extends Model>(
    payload: PendingRequestQueueItem<T>,
  ) {
    const db = await this.getDB()
    const tx = db.transaction(PENDING_REQUESTS, 'readwrite')
    const store = tx.objectStore(PENDING_REQUESTS)
    await store.delete(payload.key)
    await this.removePendingRequestFromStoreManyRequest(payload, store)
    await tx.done
  }

  async removePendingRequestError<T extends Model>(key: string) {
    const db = await this.getDB()
    const tx = db.transaction(PENDING_REQUEST_ERRORS, 'readwrite')
    const store = tx.objectStore(PENDING_REQUEST_ERRORS)
    await store.delete(key)
    await tx.done
  }

  async removePendingRequestFromStoreManyRequest<T extends Model>(
    payload: PendingRequestQueueItem<T>,
    store: IDBPObjectStore<
      unknown,
      [typeof PENDING_REQUESTS],
      typeof PENDING_REQUESTS,
      'readwrite'
    >,
  ) {
    const model = this.getModelClass(payload.modelName)
    if (model && !Array.isArray(payload.item)) {
      //remove item from store-many request
      const storeManyKey = `${payload.modelName}-store-many`
      const storeManyRequest: PendingRequestQueueItem<T> = await store.get(
        storeManyKey,
      )
      if (!storeManyRequest) return
      const payloadItem = payload.item
      if (Array.isArray(storeManyRequest.item) && !Array.isArray(payloadItem)) {
        storeManyRequest.item = storeManyRequest.item.filter((item) => {
          return item[model.primaryKey] != payloadItem[model.primaryKey]
        })
      }
      await store.put(storeManyRequest)
    }
  }

  async addPendingRequestToErrorQueue<T extends Model>(
    pendingRequestItem: PendingRequestQueueItem<T>,
    error: AxiosError,
  ) {
    const db = await this.getDB()
    const tx = db.transaction(PENDING_REQUEST_ERRORS, 'readwrite')
    const store = tx.objectStore(PENDING_REQUEST_ERRORS)
    const pendingRequestError = {
      ...pendingRequestItem,
      error: {
        ...JSON.parse(JSON.stringify(error)),
        response: {
          ...JSON.parse(JSON.stringify(error.response)),
        },
      },
    }
    await store.put(pendingRequestError)
    await tx.done
    this.removePendingRequest(pendingRequestItem)
    //if our item is new, we need to remove it from indexeddb
    const ModelClass = this.getModelClass(pendingRequestItem.modelName)
    if (!ModelClass) return 'error'
    if (
      !Array.isArray(pendingRequestItem.item) &&
      String(pendingRequestItem.item[ModelClass.primaryKey]).includes('temp')
    ) {
      const item = new ModelClass(pendingRequestItem.item)
      await item.delete()
    } else if (Array.isArray(pendingRequestItem.item)) {
      const items = pendingRequestItem.item
        .filter((item) => {
          return String(item[ModelClass.primaryKey]).includes('temp')
        })
        .map((i) => new ModelClass(i))
      if (items?.length) {
        await Promise.all(items.map((item) => item.delete()))
      }
    }

    this.hasErrors.value = true
    return 'success'
  }

  async performRequest<T extends Model>(
    payload: PendingRequestQueueItem<T>,
  ): Promise<'success' | 'error' | 'offline'> {
    const ModelClass = this.getModelClass(payload.modelName) as typeof Model
    if (!ModelClass) return 'error'
    const handleErrors = (error: AxiosError) => {
      //if we get an error that is not from being offline, add to error queue
      if (error && error.message !== 'Network Error') {
        this.addPendingRequestToErrorQueue(payload, error)
        return 'error'
      } else {
        //if we get an error from being offline, do nothing
        return 'offline'
      }
    }
    if (Array.isArray(payload.item)) {
      //store many
      const result = await ModelClass.saveManyToApi(payload.item).catch(
        handleErrors,
      )
      if (result === 'offline') return this.handleOffline()
      if (result === 'error') return 'error'
    } else if (payload.method == 'delete') {
      const model = new ModelClass(payload.item)
      const result = await model.removeFromApi().catch(handleErrors)
      if (result === 'offline') return this.handleOffline()
      if (result === 'error' || result == 'fail') return 'error'
    } else {
      const model = new ModelClass(payload.item)
      const result = await model.saveToApi().catch(handleErrors)
      if (result === 'offline') return this.handleOffline()
      if (result === 'error' || result == 'fail') return 'error'
    }
    this.removePendingRequest(payload)
    return 'success'
  }

  finishSyncing() {
    this.syncing.value = false
  }

  handleOffline(): 'offline' {
    this.syncing.value = false
    connection.goOffline()
    return 'offline'
  }

  performPendingRequests = debounce(
    async () => {
      if (connection.offline.value || this.syncing.value) return
      this.syncing.value = true
      //get pending requests
      const pendingRequests = await this.getPendingRequests()
      if (!pendingRequests?.length) return this.finishSyncing()
      //run first request
      const firstRequest = pendingRequests[0]
      const result = await this.performRequest(firstRequest)
      //if first request is offline, stop
      if (result === 'offline') return this.finishSyncing()
      //run remaining requests
      const remainingRequests = pendingRequests.slice(1)
      await Promise.all(
        remainingRequests.map(async (request) => {
          await this.performRequest(request)
        }),
      )
      this.finishSyncing()
    },
    100,
    false,
  )

  async clear() {
    this.hasErrors.value = false
    const db = await this.getDB()
    const errorsTx = db.transaction(PENDING_REQUEST_ERRORS, 'readwrite')
    const errorsStore = errorsTx.objectStore(PENDING_REQUEST_ERRORS)
    errorsStore.clear()
    const requestsTx = db.transaction(PENDING_REQUESTS, 'readwrite')
    const requestsStore = requestsTx.objectStore(PENDING_REQUESTS)
    requestsStore.clear()
    await Promise.all([errorsTx.done, requestsTx.done])
  }
}

export const pendingRequests = new PendingRequests()
