<template>
  <div class="flex flex-col relative">
    <template v-if="!!loaded">
      <slot />
    </template>

    <Teleport to="#router-container" :disabled="!fullScreenStatus">
      <div
        :class="{
          'absolute top-0 left-0 w-screen h-screen z-30': fullScreenStatus,
          'h-96': !fullScreenStatus,
        }"
      >
        <div ref="mapdiv" class="w-full h-full" />
      </div>
      <IconButton
        @click="toggleFullScreen"
        :icon="$icons.FULLSCREEN"
        :class="{
          'absolute top-2 right-2 z-40': fullScreenStatus,
          'absolute top-20 right-1 z-10': !fullScreenStatus,
        }"
        class="text-gray-500 bg-white"
        small
        :large="fullScreenStatus"
      />
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType, ref } from 'vue'
import { provideGoogleMapsData } from '~/composables/useGoogleMaps'

const props = defineProps({
  apiKey: {
    type: String as PropType<string>,
    default: import.meta.env.VITE_GOOGLE_KEY || '',
  },
  mapOptions: {
    type: Object,
    default: () => ({}),
  },
  mapId: {
    type: String as PropType<string>,
    required: true,
  },
})

const mapdiv = ref<HTMLDivElement>()

const googleData = provideGoogleMapsData(
  props.apiKey || '',
  props.mapId,
  mapdiv,
  props.mapOptions,
)

const fullScreenStatus = ref(false)
function toggleFullScreen() {
  fullScreenStatus.value = !fullScreenStatus.value
}
const loaded = computed(() => {
  return googleData.loaded.value
})
</script>
