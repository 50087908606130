import { App, ref } from 'vue'

const showLoader = ref(false)
let showingLoader = false

const show = () => {
  showingLoader = true
  setTimeout(() => {
    if (showingLoader) showLoader.value = true
  }, 250)
}

const hide = () => {
  showLoader.value = false
  showingLoader = false
}

const loader = {
  show,
  hide,
}

export default {
  install: (app: App) => {
    app.config.globalProperties.$loader = loader
  },
}

export function useLoader() {
  return loader
}

export function useLoaderItems() {
  return { showLoader, hide }
}
