<template>
  <Modal width-class="max-w-5xl">
    <template #title>{{
      model.id ? 'Edit Labor Hours' : 'Add Labor Hours'
    }}</template>
    <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
      <div>
        <SelectMenu
          v-model="model.hours_type_id"
          @input="errors.hours_type_id = ''"
          :error="errors.hours_type_id"
          :options="filteredHoursTypes"
          valueKey="id"
          labelKey="name"
          subtitle="Labor Hours"
        >
          Type
        </SelectMenu>
        <div class="grid w-full grid-cols-2 gap-2">
          <Textfield
            v-if="showEstimated"
            v-model="model.estimated_hours"
            @keydown="errors.estimated_hours = ''"
            id="estimated_hours"
            :error="errors.estimated_hours"
            type="number"
            >Estimated Hours</Textfield
          >
          <Textfield
            v-model="model.actual_hours"
            @keydown="errors.actual_hours = ''"
            id="actual_hours"
            :error="errors.actual_hours"
            type="number"
            >Total Hours</Textfield
          >
        </div>
        <Textarea
          v-model="model.details"
          @update:model-value="errors.details = ''"
          :error="errors.details"
          >Details</Textarea
        >
      </div>
      <div>
        <div
          class="flex flex-row items-end justify-between font-semibold border-b"
        >
          Employees
        </div>
        <div class="overflow-y-auto" style="max-height: 20rem">
          <ListItem
            v-for="user in filteredUsers"
            @click="toggleUser(user)"
            :key="user.id"
            class="flex flex-row items-center"
          >
            <Checkbox :modelValue="isSelected(user)" />
            <div class="mx-2 font-semibold">
              {{ user.name }}
            </div>
          </ListItem>
        </div>
      </div>
    </div>
    <template #actions>
      <CloseButton @click="$modals.close()" />
      <DeleteButton v-if="showDelete" @click="deleteModel()" />
      <SaveButton @click="save()" />
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { useCan, useUser } from '~/plugins/auth'
import { useSettings } from '~/composables/useSettings'
import Modal from '~/components/Modal.vue'
import SelectMenu from '~/components/SelectMenu.vue'
import Textfield from '~/components/Textfield.vue'
import Textarea from '~/components/Textarea.vue'
import ListItem from '~/components/ListItem.vue'
import Checkbox from '~/components/Checkbox.vue'
import CloseButton from '~/components/CloseButton.vue'
import DeleteButton from '~/components/DeleteButton.vue'
import SaveButton from '~/components/SaveButton.vue'
import JobLaborHour from '~/database/models/JobLaborHour'
import { AxiosError } from 'axios'
import Job from '~/database/models/Job'
import { useModelEdits } from '~/database'
import { useStore } from '~/plugins/store'
import useOffline from '~/composables/useOffline'
import User from '~/database/models/User'

const props = defineProps({
  jobLaborHour: {
    type: Object as PropType<Partial<JobLaborHour>>,
    default: () => ({}),
  },
  resolve: {
    type: Function as PropType<() => JobLaborHour | undefined | 'deleted'>,
    required: true,
  },
  axiosError: {
    type: [Object, undefined] as PropType<AxiosError | undefined>,
    default: () => undefined,
  },
  bypassQueue: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  job: {
    type: Object as PropType<Job>,
    required: true,
  },
})

const { model, errors, deleteModel, save } = useModelEdits<JobLaborHour>(
  props.jobLaborHour,
  JobLaborHour,
  ['hours_type_id', 'estimated_hours', 'actual_hours', 'details'],
  props.resolve,
  {
    axiosError: props.axiosError,
    bypassQueue: props.bypassQueue,
    deleteMessage: 'Delete these labor hours?',
  },
)

if (props.job.id) {
  model.value.job_id = props.job.id
}

//hours types
const store = useStore()
const filteredHoursTypes = computed(() => {
  return store.hoursTypes.set.value.filter((type) => {
    return (
      type.id == props.jobLaborHour.hours_type_id ||
      (type.active &&
        type.category_list.some((id) => {
          return id == props.job?.category_id
        }))
    )
  })
})
if (filteredHoursTypes.value.length == 1) {
  model.value.hours_type_id = filteredHoursTypes.value[0].id
}

const offline = useOffline()

const showDelete = computed(() => {
  return !offline.value && !!model.value.id
})

//users
const authUser = useUser()
const filteredUsers = computed(() => {
  return store.users.set.value.filter((user) => {
    return isSelected(user) || user.active
  })
})
function isSelected(user: User) {
  return model.value.user_list.some((id) => id == user.id)
}
function toggleUser(user: User) {
  const index = model.value.user_list.findIndex((user_id) => {
    return user_id == user.id
  })
  if (index < 0) model.value.user_list.push(user.id)
  else model.value.user_list.splice(index)
}
if (!model.value.id && authUser.value?.id) {
  model.value.user_list = [authUser.value.id]
}

//estimated qty
const settings = useSettings()
const can = useCan()
const showEstimated = computed(() => {
  const setting = settings.getSetting('show_estimated_labor_hours')
  return setting == 'all' || (setting == 'admin' && can('edit', 'jobs_admin'))
})
</script>

<style scoped></style>
