import { z } from 'zod'
import Model from '../Model'

export default class Status extends Model {
  static readonly tableName: string = 'statuses'
  static readonly apiEndPoint: string = 'api/statuses'
  static readonly sortKey: string = 'sort'

  name: string = ''
  description: string = ''
  color: string = ''
  job_active: boolean = true
  active: boolean = true
  sort: number = 0

  constructor(payload: Partial<Status> = {}) {
    super(payload)
    Object.assign(this, payload)
  }
}
