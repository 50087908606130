<template>
  <div class="flex flex-row items-center">
    <Icon v-if="$slots.icon">
      <slot name="icon" />
    </Icon>
    <div
      class="flex items-baseline"
      :class="{
        'text-lg md:text-xl': !small && !large && !extraSmall,
        'text-base md:text-lg': small,
        'text-sm': extraSmall,
        'text-2xl md:text-5xl': large,
      }"
    >
      <span
        class="text-gray-400 mr-1"
        :class="{
          'text-xs': extraSmall,
          'text-sm': !large && !extraSmall && !extraSmall,
          'text-base': large && !extraSmall,
        }"
      >
        <slot name="label" />
      </span>
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ValuePair',
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    extraSmall: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style scoped></style>
