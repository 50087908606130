import { useDatabase } from '~/plugins/database'
import Model from '../Model'
import models from '../models'
import { Ref, ref, onUnmounted, computed } from 'vue'
import Dates from '~/assets/constants/Dates'

export default function useModel<T extends Model>(
  model: (typeof models)[number],
  initialData: T[] | 'fromIndexedDB' | 'fromApi' | 'none' = 'none',
  options?: {
    standalone?: boolean
  },
) {
  const set: Ref<T[]> = ref([])

  const record = computed(() => {
    return set.value.reduce((record: Record<string, T>, item) => {
      record[item.id] = item
      return record
    }, {})
  })

  function sortMethod(a: T, b: T) {
    const sortKey = model.sortKey as keyof T
    const sortA = a[sortKey]
    const sortB = b[sortKey]
    const multiplier = model.sortDirection == 'desc' ? -1 : 1
    if (sortA < sortB) return -1 * multiplier
    if (sortA > sortB) return 1 * multiplier
    return 0
  }

  const database = useDatabase()
  async function fetchLocalData() {
    if (!database.initialized) {
      database.onInitialized(fetchLocalData)
      return
    }
    const result = await model.fetchAllDataFromIndexedDB<T>()
    if (!result) return
    set.value = result
  }
  if (Array.isArray(initialData)) set.value = initialData
  else if (initialData == 'fromIndexedDB') fetchLocalData()
  else if (initialData == 'fromApi') fetchData()

  async function fetchData(
    payload?: Record<string, string | boolean | number | Date>,
  ) {
    //convert any date objects to strings
    if (payload) {
      Object.entries(payload).forEach(([key, value]) => {
        if (value instanceof Date) payload[key] = Dates.formatISO(value, 'date')
      })
    }
    const result = await model.fetchData<T>(payload as any)
    if (!result) return
    const remoteData = await result.remoteData
    if (!remoteData || !remoteData?.length) return
  }

  function onFetched(item?: Model) {
    if (!item) return
    set.value = [...set.value.filter((i) => i.id != item.id), item as T].sort(
      sortMethod,
    )
  }
  function onFetchedData(items?: Model[]) {
    if (!items) return
    set.value = [...items, ...set.value]
      .reduce((items: T[], item) => {
        if (items.find((i) => i.id == item.id)) return items
        return [...items, item as T]
      }, [])
      .sort(sortMethod)
  }
  function onSaved(item?: Model) {
    if (!item) return
    set.value = [...set.value.filter((i) => i.id != item.id), item as T].sort(
      sortMethod,
    )
  }
  function onSavedMany(items?: Model[]) {
    if (!items) return
    set.value = [...items, ...set.value]
      .reduce((items: T[], item) => {
        if (items.find((i) => i.id == item.id)) return items
        return [...items, item as T]
      }, [])
      .sort(sortMethod)
  }
  function onDeleted(id?: string) {
    if (!id) return
    set.value = set.value.filter((i) => i.id != id)
  }
  function onDeletedMany(ids?: string[]) {
    if (!ids?.length) return
    set.value = set.value.filter((i) => !ids.includes(i.id))
  }

  model.onFetched(onFetched)
  model.onFetchedData(onFetchedData)
  model.onSaved(onSaved)
  model.onSavedMany(onSavedMany)
  model.onDeleted(onDeleted)
  model.onDeletedMany(onDeletedMany)

  if (!options?.standalone) {
    onUnmounted(() => {
      model.removeOnSaved(onSaved)
      model.removeOnSavedMany(onSavedMany)
      model.removeOnFetched(onFetched)
      model.removeOnFetchedData(onFetchedData)
      model.removeOnDeleted(onDeleted)
      model.removeOnDeletedMany(onDeletedMany)
    })
  }

  return {
    set,
    record,
    fetchData,
    fetchLocalData,
  }
}
