import { IDBPDatabase, IDBPTransaction, openDB } from 'idb'
import { dbName, dbVersion } from './constants'
import { z, SafeParseError, SafeParseSuccess } from 'zod'
import { pendingRequests } from './plugins/pendingRequests'
import { connection } from './plugins/connection'
import { AxiosError } from 'axios'
import { useUser } from '~/plugins/auth'
import Dates from '~/assets/constants/Dates'

export type ConstructorPayload = Record<string, any>

export type SearchPayload = Record<string, any>

function getTheObservers() {
  return {
    saved: [],
    savedMany: [],
    deletedMany: [],
    deleted: [],
    fetchedData: [],
    fetched: [],
    updatedData: [],
  }
}

interface Observers {
  saved: ((item?: Model) => void)[]
  savedMany: ((item?: Model[]) => void)[]
  deletedMany: ((ids?: string[]) => void)[]
  fetched: ((item?: Model) => void)[]
  deleted: ((id?: string) => void)[]
  fetchedData: ((data?: Model[]) => void)[]
  updatedData: ((data?: undefined) => void)[]
}

interface FetchPaginatedDataResult<T> {
  data: T[]
  current_page: number
  last_page: number
}

interface FetchDataResult<T> {
  localData: T[]
  remoteData: Promise<T[]>
}

interface FetchItemResult<T> {
  localItem: T | null
  remoteItem: Promise<T | null>
}

export interface StoreManyError<T> {
  resourceName: string
  item: Partial<T>
  errors: Record<string, string>
}
export type StoreManyErrors<T> = StoreManyError<T>[]

type Constructor<T> = { new (): T }

export default class Model {
  static readonly tableName: string = 'models'
  static readonly apiEndPoint: string = ''
  static readonly primaryKey: keyof Model = 'id'
  static readonly sortKey: string = ''
  static readonly sortDirection: 'asc' | 'desc' = 'asc'
  static readonly foreignKeys: string[] = []
  static readonly alwaysResetData: boolean = false
  private static observers: Record<string, Observers> = {}
  static readonly bypassPendingRequests:
    | 'all'
    | ('saveMany' | 'create' | 'update' | 'delete')[] = 'all'
  static readonly pusherChannel: string = ''
  static readonly pusherSavedEvent: string = ''
  static readonly pusherDeletedEvent: string = ''
  static isSubscribedToPusherSaved: boolean = false
  static isSubscribedToPusherDeleted: boolean = false
  static initializeData: boolean = false
  static db: IDBPDatabase | null = null

  static ZodValidator = z.object({})
  static ZodStoreManyValidator: ReturnType<typeof z.object> | null = null

  // Key will be applied to anything that uses pending requests to ensure
  // We do not have duplicate entries in our db
  key: string = ''

  id: string = ''
  created_at?: Date | string
  updated_at?: Date | string
  deleted_at?: Date | string

  constructor(payload: Partial<Model> = {}) {
    Object.assign(this, payload)
  }

  private static async getDB(): Promise<IDBPDatabase> {
    if (!this.db) {
      this.db = await openDB(dbName, dbVersion)
    }
    return this.db
  }

  static subscribeToPusherSaved() {
    //uncomment for auto updates from pusher
    // if (this.isSubscribedToPusherSaved || !this.pusherChannel) {
    //   return
    // }
    // //for some reason, echo is not setting the socket id correctly which causes broadcast(...)->toOthers() to not work on the server
    // window.$axios.defaults.headers['X-Socket-Id'] = window.Echo.socketId()
    // window.Echo.private(this.pusherChannel).listen(
    //   this.pusherSavedEvent,
    //   (e: { model?: Model }) => {
    //     const model = e.model
    //     if (!model) return
    //     this.notifyObservers(['saved', 'updatedData'], model)
    //   },
    // )
    // this.isSubscribedToPusherSaved = true
  }

  static subscribeToPusherDeleted() {
    //uncomment for auto updates from pusher
    // if (this.isSubscribedToPusherDeleted || !this.pusherChannel) {
    //   return
    // }
    // //for some reason, echo is not setting the socket id correctly which causes broadcast(...)->toOthers() to not work on the server
    // window.$axios.defaults.headers['X-Socket-Id'] = window.Echo.socketId()
    // window.Echo.private(this.pusherChannel).listen(
    //   this.pusherDeletedEvent,
    //   (e: { id?: number | string }) => {
    //     const id = e.id
    //     if (!id) return
    //     this.notifyObservers(['deleted', 'updatedData'], id)
    //   },
    // )
    // this.isSubscribedToPusherDeleted = true
  }

  static unsubscribeFromPusher() {
    //uncomment for auto updates from pusher
    // window.Echo.leaveChannel(this.pusherChannel)
    // this.isSubscribedToPusherSaved = false
    // this.isSubscribedToPusherDeleted = false
  }

  static onSaved(callback: Observers['saved'][number]) {
    if (!this.observers[this.name]) {
      this.observers[this.name] = getTheObservers()
    }
    this.observers[this.name].saved.push(callback)
    if (this.observers[this.name].saved?.length) {
      this.subscribeToPusherSaved()
    }
  }

  static removeOnSaved(callback: Observers['saved'][number]) {
    if (!this.observers[this.name]) {
      this.observers[this.name] = getTheObservers()
    }
    this.observers[this.name].saved = this.observers[this.name].saved.filter(
      (c) => c !== callback,
    )
    if (
      !this.observers[this.name].saved?.length &&
      !this.observers[this.name].deleted?.length
    ) {
      this.unsubscribeFromPusher()
    }
  }

  static onSavedMany(callback: Observers['savedMany'][number]) {
    if (!this.observers[this.name]) {
      this.observers[this.name] = getTheObservers()
    }
    this.observers[this.name].savedMany.push(callback)
  }

  static removeOnSavedMany(callback: Observers['savedMany'][number]) {
    if (!this.observers[this.name]) {
      this.observers[this.name] = getTheObservers()
    }
    this.observers[this.name].savedMany = this.observers[
      this.name
    ].savedMany.filter((c) => c !== callback)
  }

  static onDeletedMany(callback: Observers['deletedMany'][number]) {
    if (!this.observers[this.name]) {
      this.observers[this.name] = getTheObservers()
    }
    this.observers[this.name].deletedMany.push(callback)
  }

  static removeOnDeletedMany(callback: Observers['deletedMany'][number]) {
    if (!this.observers[this.name]) {
      this.observers[this.name] = getTheObservers()
    }
    this.observers[this.name].deletedMany = this.observers[
      this.name
    ].deletedMany.filter((c) => c !== callback)
  }

  static onDeleted(callback: Observers['deleted'][number]) {
    if (!this.observers[this.name]) {
      this.observers[this.name] = getTheObservers()
    }
    this.observers[this.name].deleted.push(callback)
    if (this.observers[this.name].deleted?.length) {
      this.subscribeToPusherDeleted()
    }
  }

  static removeOnDeleted(callback: Observers['deleted'][number]) {
    if (!this.observers[this.name]) {
      this.observers[this.name] = getTheObservers()
    }
    this.observers[this.name].deleted = this.observers[
      this.name
    ].deleted.filter((c) => c !== callback)
    if (
      !this.observers[this.name].saved?.length &&
      !this.observers[this.name].deleted?.length
    ) {
      this.unsubscribeFromPusher()
    }
  }

  static onFetchedData(callback: Observers['fetchedData'][number]) {
    if (!this.observers[this.name]) {
      this.observers[this.name] = getTheObservers()
    }
    this.observers[this.name].fetchedData.push(callback)
  }

  static removeOnFetchedData(callback: Observers['fetchedData'][number]) {
    if (!this.observers[this.name]) {
      this.observers[this.name] = getTheObservers()
    }
    this.observers[this.name].fetchedData = this.observers[
      this.name
    ].fetchedData.filter((c) => c !== callback)
  }

  static onFetched<T extends Model>(callback: Observers['fetched'][number]) {
    if (!this.observers[this.name]) {
      this.observers[this.name] = getTheObservers()
    }
    this.observers[this.name].fetched.push(callback)
  }

  static removeOnFetched(callback: Observers['fetched'][number]) {
    if (!this.observers[this.name]) {
      this.observers[this.name] = getTheObservers()
    }
    this.observers[this.name].fetched = this.observers[
      this.name
    ].fetched.filter((c) => c !== callback)
  }

  static onDataUpdated(callback: Observers['updatedData'][number]) {
    if (!this.observers[this.name]) {
      this.observers[this.name] = getTheObservers()
    }
    this.observers[this.name].updatedData.push(callback)
  }

  static removeOnDataUpdated(callback: Observers['updatedData'][number]) {
    if (!this.observers[this.name]) {
      this.observers[this.name] = getTheObservers()
    }
    this.observers[this.name].updatedData = this.observers[
      this.name
    ].updatedData.filter((c) => c !== callback)
  }

  static payloadTransform(payload: SearchPayload) {
    return payload
  }

  static cleanMethod<T extends Model>(model: T): boolean {
    return !!model && !!model.deleted_at
  }

  static notifyObservers(
    key: keyof Observers | (keyof Observers)[],
    payload?: any,
  ) {
    if (!this.observers[this.name]) return
    const keys = Array.isArray(key) ? key : [key]
    keys.forEach((key) => {
      this.observers[this.name][key].forEach((observer) => {
        observer(payload)
      })
    })
  }

  validate(
    method: 'store' | 'storeMany' | 'update' = 'store',
  ): SafeParseError<any> | SafeParseSuccess<any> {
    const Self = this.getSelf()
    if (method == 'storeMany' && Self.ZodStoreManyValidator) {
      return Self.ZodStoreManyValidator.safeParse(this) as
        | SafeParseError<any>
        | SafeParseSuccess<any>
    }
    return Self.ZodValidator.safeParse(this) as
      | SafeParseError<any>
      | SafeParseSuccess<any>
  }

  static localDataFilterMethod<T extends Model>(
    model: T,
    queryParameters: Record<string, string | number | Date | boolean>,
  ): boolean {
    return true
  }

  static async handleFetchItemResult<T extends Model>(
    result: FetchItemResult<T>,
  ) {
    const awaitedResult = await result
    const localItem = awaitedResult.localItem
    if (localItem) {
      this.notifyObservers(['updatedData', 'fetched'], localItem)
    }
    const remoteItem = await awaitedResult.remoteItem
    if (remoteItem) {
      const payload = new this(remoteItem)
      payload.saveToIndexedDB(undefined, false)
      this.notifyObservers(['updatedData', 'fetched'], remoteItem)
    }
  }

  static async handleFetchDataResult<T extends Model>(
    result: FetchDataResult<T>,
  ) {
    const awaitedResult = await result
    const localData = awaitedResult.localData
    if (localData) {
      this.notifyObservers(['updatedData', 'fetchedData'], localData)
    }
    const remoteData = await awaitedResult.remoteData
    if (remoteData) {
      this.notifyObservers(['updatedData', 'fetchedData'], remoteData)
    }
  }

  static async fetchItem<T extends Model>(
    id: string | number,
  ): Promise<FetchItemResult<T>> {
    const remoteItem = this.fetchItemFromApi<T>(id)
    const localItem = await this.fetchItemFromIndexedDB<T>(id)
    this.handleFetchItemResult<T>({
      localItem,
      remoteItem,
    })
    return {
      localItem,
      remoteItem,
    }
  }

  static async fetchItemFromIndexedDB<T extends Model>(
    id: string | number,
    fallbackToApi = false,
    notifyObservers = false,
  ): Promise<T | null> {
    const db = await this.getDB()
    const tx = db.transaction(this.tableName, 'readonly')
    const store = tx.objectStore(this.tableName)
    const item = await store.get(Number(id))
    await tx.done
    if (item) {
      if (notifyObservers) {
        this.notifyObservers(['fetched', 'updatedData'], item)
      }
      return item
    }
    if (!fallbackToApi) return null
    return await this.fetchItemFromApi<T>(id, false, notifyObservers)
  }

  static async fetchItemFromApi<T extends Model>(
    id: string | number,
    fallbackToIndexedDB = false,
    notifyObservers = false,
  ): Promise<T | null> {
    const response =
      !connection.offline.value &&
      (await window.$axios
        .get(`${this.apiEndPoint}/${id}`)
        .catch((e: AxiosError) => {
          //if we are offline, go offline, otherwise throw the error
          if (!e || e.message == 'Network Error') {
            connection.goOffline()
            return undefined
          }
          throw e
        }))
    const item = !response ? undefined : (response?.data as T)
    if (!item && fallbackToIndexedDB) {
      return await this.fetchItemFromIndexedDB<T>(id, false, notifyObservers)
    }
    if (!item) return null
    if (notifyObservers) {
      this.notifyObservers(['fetched', 'updatedData'], item)
    }
    return item
  }

  static async fetchData<T extends Model>(
    queryParameters?: Record<string, string | boolean | number>,
  ): Promise<FetchDataResult<T>> {
    const remoteData = this.fetchDataFromApi<T>(queryParameters)
    const localData = await this.fetchDataFromIndexedDB<T>(queryParameters)
    this.handleFetchDataResult<T>({
      localData,
      remoteData,
    })
    return {
      localData,
      remoteData,
    }
  }

  static async fetchDataFromApi<T extends Model>(
    queryParameters?: Record<string, string | boolean | number | Date>,
    notifyObservers = false,
  ): Promise<T[]> {
    if (connection.offline.value) return []
    //convert any date objects to strings
    if (queryParameters) {
      Object.entries(queryParameters).forEach(([key, value]) => {
        if (value instanceof Date)
          queryParameters[key] = Dates.formatISO(value, 'date')
      })
    }
    const queryString = !queryParameters
      ? ''
      : Object.keys(queryParameters)
          .map((key) => key + '=' + queryParameters[key])
          .join('&')
    const response = <
      | undefined
      | {
          data: T[]
        }
    >await window.$axios.get(`${this.apiEndPoint}?${queryString}`).catch((e: AxiosError) => {
      //if we are offline, go offline, otherwise throw the error
      if (!e || e.message == 'Network Error') {
        connection.goOffline()
        return []
      }
      throw e
    })
    const items = response?.data || []
    if (notifyObservers) {
      this.notifyObservers(['fetchedData', 'updatedData'], items)
    }
    return items
  }

  static async fetchPaginatedDataFromApi<T extends Model>(
    payload: Record<string, any>,
  ): Promise<FetchPaginatedDataResult<T>> {
    const queryString = Object.keys(payload)
      .map((key) => key + '=' + payload[key])
      .join('&')
    const result = await window.$axios.get(`${this.apiEndPoint}?${queryString}`)
    return result.data as FetchPaginatedDataResult<T>
  }

  static async fetchAllDataFromIndexedDB<T extends Model>(): Promise<T[]> {
    const db = await this.getDB()
    const table = this.tableName
    const sortKey = this.sortKey
    const sortDirection = this.sortDirection
    const allData: T[] = sortKey
      ? await db.getAllFromIndex(table, String(sortKey))
      : await db.getAll(table)
    if (sortDirection == 'asc') return allData
    return allData.reverse()
  }

  static async fetchDataFromIndexedDB<T extends Model>(
    queryParameters?: Record<string, string | Date | number | boolean>,
    notifyObservers = false,
  ) {
    const data = await this.fetchAllDataFromIndexedDB<T>()
    if (!data) return []
    const filteredData = !queryParameters
      ? data
      : data.filter((model) => {
          return this.localDataFilterMethod(model, queryParameters)
        })
    if (notifyObservers) {
      this.notifyObservers(['fetchedData', 'updatedData'], filteredData)
    }
    return filteredData
  }

  static async fetchPaginatedDataFromIndexedDB<T extends Model>(
    payload: Record<string, any>,
  ): Promise<FetchPaginatedDataResult<T>> {
    const filteredData = await this.fetchDataFromIndexedDB<T>(payload)
    const { page, per_page } = payload
    const start = (page - 1) * per_page
    const end = start + per_page
    const data = filteredData.slice(start, end)
    const total = filteredData.length
    const last_page = Math.ceil(total / per_page)
    return {
      data,
      last_page,
      current_page: page,
    }
  }

  getSelf<T extends typeof Model>() {
    return <T>this.constructor
  }

  static getKey() {
    const authUser = useUser()
    const dateString = new Date().valueOf()
    return `${this.tableName}-${authUser?.value?.id || '0'}-${dateString}`
  }
  getKey() {
    const Self = this.getSelf()
    return Self.getKey()
  }

  static checkBypassPendingRequests(
    method: 'saveMany' | 'create' | 'update' | 'delete',
  ) {
    if (this.bypassPendingRequests == 'all') return true
    return this.bypassPendingRequests.includes(method)
  }

  async saveToIndexedDB<T extends Model>(
    transaction?: IDBPTransaction,
    notifyObservers = true,
  ): Promise<'success' | 'fail'> {
    const Self = this.getSelf()
    const tx =
      transaction ||
      (await Self.getDB()).transaction(Self.tableName, 'readwrite')
    const store = tx.objectStore(Self.tableName)
    if (!store?.put) return 'fail'
    this.id = this.id || `temp-${new Date().valueOf()}`
    this.created_at = this.created_at
      ? this.created_at
      : new Date().toISOString()
    this.updated_at = new Date().toISOString()
    await store.put(JSON.parse(JSON.stringify(this)))
    //only wait for transaction if it was not passed as an argument
    if (!transaction) await tx.done
    if (notifyObservers) Self.notifyObservers(['updatedData', 'saved'], this)
    return 'success'
  }

  static async saveManyToIndexedDB<T extends Model>(
    payload: Partial<T>[],
    transaction?: IDBPTransaction,
    notifyObservers = true,
  ): Promise<'success' | 'fail'> {
    const tx =
      transaction ||
      (await this.getDB()).transaction(this.tableName, 'readwrite')
    const store = tx.objectStore(this.tableName)
    if (!store?.put) return 'fail'
    const promises = payload.map(async (item, index) => {
      return await store.put(JSON.parse(JSON.stringify(item)))
    })
    await Promise.all(promises)
    //only wait for transaction if it was not passed as an argument
    if (!transaction) await tx.done
    if (notifyObservers) {
      this.notifyObservers(['updatedData', 'savedMany'], payload)
    }
    return 'success'
  }

  async removeFromIndexedDB<T extends Model>(): Promise<'success' | 'fail'> {
    const Self = this.getSelf()
    const db = await Self.getDB()
    const tx = db.transaction(Self.tableName, 'readwrite')
    const store = tx.objectStore(Self.tableName)
    await store.delete(this.id)
    await tx.done
    return 'success'
  }

  static async clearIndexedDB<T extends Model>(): Promise<'success' | 'fail'> {
    const db = await this.getDB()
    const tx = db.transaction(this.tableName, 'readwrite')
    const store = tx.objectStore(this.tableName)
    await store.clear()
    await tx.done
    return 'success'
  }

  async saveToApi<T extends Model>(): Promise<'success' | 'fail'> {
    const Self = this.getSelf()
    this.key = this.key || this.getKey()
    const method =
      !this.id || (typeof this.id == 'string' && this.id.includes('temp'))
        ? 'post'
        : 'patch'
    const url =
      method == 'post'
        ? `${Self.apiEndPoint}`
        : `${Self.apiEndPoint}/${this.id}`
    const payload: any = {
      ...this,
    }
    if (method == 'post') delete payload.id
    const response = await window.$axios[method](url, payload)
    const data = response?.data
    if (!data) return 'fail'
    const tempItem =
      method == 'patch' || !String(this.id).includes('temp')
        ? null
        : new Self(this)
    if (tempItem) tempItem.delete()
    Object.assign(this, data)
    this.saveToIndexedDB(undefined, false)
    Self.notifyObservers(['updatedData', 'saved'], this)
    return 'success'
  }

  static async saveManyToApi<T extends Model>(
    payload: Partial<T>[],
  ): Promise<{
    data: Partial<T>[]
    errors: StoreManyErrors<T>
  }> {
    const url = `${this.apiEndPoint}/store_many`
    const cleanedPayload = payload.map((item) => {
      const cleanedItem = { ...item }
      if (cleanedItem.id && String(cleanedItem.id).includes('temp')) {
        delete cleanedItem.id
      }
      return cleanedItem
    })
    const response = <
      | undefined
      | {
          data: {
            data: T[]
            errors: StoreManyErrors<T>
          }
        }
    >await window.$axios['post'](url, { data: cleanedPayload })
    const data = response?.data?.data
    if (!data)
      return {
        data: [],
        errors: response?.data?.errors || [],
      }
    this.notifyObservers(['updatedData', 'savedMany'], data)
    const keysToDelete = payload
      .map((item) => {
        return String(item.id)
      })
      .filter((item) => item.includes('temp'))
    if (keysToDelete.length) {
      const db = await this.getDB()
      const tx = db.transaction(this.tableName, 'readwrite')
      const store = tx.objectStore(this.tableName)
      keysToDelete.forEach((key) => {
        store.delete(key)
      })
      await tx.done
      this.notifyObservers(['updatedData', 'deletedMany'], keysToDelete)
    }
    this.saveManyToIndexedDB(data, undefined, false)
    return {
      data,
      errors: response?.data?.errors,
    }
  }

  async removeFromApi<T extends Model>(
    notifyObservers = false,
  ): Promise<'success' | 'fail'> {
    const Self = this.getSelf()
    if (String(this.id).includes('temp')) return 'success'
    const response = await window.$axios['delete'](
      `${Self.apiEndPoint}/${this.id}`,
    )
    const data = response?.data
    if (!data) return 'fail'
    if (notifyObservers) {
      Self.notifyObservers(['updatedData', 'deleted'], this.id)
    }
    return 'success'
  }

  static async saveMany<T extends Model>(
    payload: Partial<T>[],
  ): Promise<'success' | 'fail'> {
    if (this.checkBypassPendingRequests('saveMany')) {
      await this.saveManyToApi(payload)
      return 'success'
    }
    const key = this.getKey()
    const processedPayload = payload.map((item, index) => ({
      ...item,
      id: item.id || `temp-${new Date().valueOf()}-${index}`,
      key: item.key || `${key}-${index}`,
      created_at: item.created_at || new Date().toISOString(),
      updated_at: new Date().toISOString(),
    }))
    await this.saveManyToIndexedDB(processedPayload)
    pendingRequests.addItemToPendingRequestsQueue({
      item: JSON.parse(JSON.stringify(processedPayload)),
      modelName: this.name,
      key: `${this.tableName}-save-many`,
      method: 'post',
    })
    return 'success'
  }

  async save<T extends Model>(): Promise<'success' | 'fail'> {
    this.key = this.key || this.getKey()
    const Self = this.getSelf()
    const method =
      !this.id || (typeof this.id == 'string' && this.id.includes('temp'))
        ? 'post'
        : 'patch'
    const bypass =
      method == 'post'
        ? Self.checkBypassPendingRequests('create')
        : Self.checkBypassPendingRequests('update')
    if (bypass) {
      await this.saveToApi()
    }
    await this.saveToIndexedDB()
    if (!bypass) {
      const self = this.getSelf()
      pendingRequests.addItemToPendingRequestsQueue({
        item: JSON.parse(JSON.stringify(this)),
        modelName: self.name,
        key: this.key,
        method:
          typeof this.id == 'string' && this.id.includes('temp')
            ? 'post'
            : 'patch',
      })
    }
    return 'success'
  }

  static async restore<T extends Model>(
    id: number | string,
  ): Promise<T | undefined> {
    const response = <undefined | { data: T }>(
      await window.$axios['post'](`${this.apiEndPoint}/${id}/restore`)
    )
    const data = response?.data
    if (!data) return
    const item = new this(data)
    item.saveToIndexedDB()
    this.notifyObservers(['updatedData', 'saved'], data)
    return data
  }

  async delete<T extends Model>(): Promise<'success' | 'fail'> {
    const Self = this.getSelf()
    const bypass = Self.checkBypassPendingRequests('delete')
    if (bypass && this.id && !String(this.id).includes('temp')) {
      await this.removeFromApi()
    }
    await this.removeFromIndexedDB()
    if (!bypass && this.id && !String(this.id).includes('temp')) {
      pendingRequests.addItemToPendingRequestsQueue({
        item: JSON.parse(JSON.stringify(this)),
        modelName: Self.name,
        key: this.getKey(),
        method: 'delete',
      })
    }
    Self.notifyObservers(['updatedData', 'deleted'], this.id)
    return 'success'
  }
}
