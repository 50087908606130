<template>
  <div>
    <div class="relative">
      <MenuButton
        @click="select()"
        :class="{ 'border-secondary': !disabled, 'border-gray-200': disabled }"
        :disabled="disabled"
        style="height: 3.5rem"
        :color="color"
        :icon="$icons.TEMPLATES"
        >{{ title }}</MenuButton
      >
      <div
        class="absolute top-0 left-0 origin-left transform pointer-events-none text-lg focus:border-secondary transition-transform duration-200 ease-in-out"
        :class="{ 'text-gray-800': !disabled, 'text-gray-400': disabled }"
        :style="labelStyle"
      >
        Job Template
      </div>
      <p class="text-xs text-gray-700 h-6" :class="{ 'text-red-600': error }">
        {{ error ? error : helper }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, PropType, watch } from 'vue'
import { useAModel } from '~/database'
import Job from '~/database/models/Job'
import { useModals } from '~/plugins/modals'
import MenuButton from './MenuButton.vue'
import LookupJobTemplateModal from '~/modals/LookupJobTemplateModal.vue'

const props = defineProps({
  job_template_id: {
    type: [Number, String] as PropType<string | number>,
    default: '',
  },
  category_id: {
    type: [Number, String] as PropType<string | number>,
    default: '',
  },
  error: {
    type: String as PropType<string>,
    default: '',
  },
  helper: {
    type: String as PropType<string>,
    default: '',
  },
  nullable: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  disabled: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const labelStyle: any = computed(() => {
  return {
    '--tw-translate-x': props.job_template_id ? '.5rem' : '2.5rem',
    '--tw-translate-y': props.job_template_id ? '-.2rem' : '1rem',
    '--tw-scale-x': props.job_template_id ? 0.65 : 1,
    '--tw-scale-y': props.job_template_id ? 0.65 : 1,
  }
})

const jobTemplate = useAModel<Job>(
  Job,
  computed(() => props.job_template_id),
)

if (!jobTemplate.value?.id && props.job_template_id) {
  Job.fetchItemFromIndexedDB(props.job_template_id, true)
}

const title = computed(() => {
  if (!jobTemplate) return ''
  return jobTemplate.value?.name || ''
})
const color = computed(() => {
  if (!jobTemplate) return ''
  return jobTemplate.value?.color || ''
})

const emits = defineEmits(['update:category_id', 'update:job_template_id'])

watch(jobTemplate, () => {
  emits('update:category_id', jobTemplate.value?.category_id || '')
})

const modals = useModals()
const select = async () => {
  if (props.disabled) return
  const selectedJobTemplate = <Job | undefined>await modals.show(
    LookupJobTemplateModal,
    {
      job_template_id: props.job_template_id,
      category_id: props.category_id,
    },
  )
  if (!selectedJobTemplate?.id) return
  emits('update:job_template_id', selectedJobTemplate.id)
}

onMounted(() => {
  if (!props.job_template_id || !props.category_id) {
    select()
  }
})
</script>

<style scoped></style>
