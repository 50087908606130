import Model from '../Model'

export default class Customer extends Model {
  static readonly tableName: string = 'customers'
  static readonly apiEndPoint: string = 'api/customers'
  static readonly sortKey: string = 'name'

  active: boolean = true
  name: string = ''

  constructor(payload: Partial<Customer> = {}) {
    super(payload)
    Object.assign(this, payload)
  }
}
