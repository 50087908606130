import { Ref, ref } from 'vue'
import Storage from '~/assets/constants/Storage'

interface Setting {
  value: string | number | boolean
  name: string
}

const defaultSettings = {
  last_status_id: {
    name: 'last_status_id',
    value: '',
  },
  show_estimated_equipment_hours: {
    name: 'show_estimated_equipment_hours',
    value: 'none',
  },
  show_estimated_labor_hours: {
    name: 'show_estimated_labor_hours',
    value: 'none',
  },
  show_estimated_qtys: {
    name: 'show_estimated_qtys',
    value: 'all',
  },
}

const STORAGE_KEY = 'settings'
function getLocalSettings() {
  const settings = Storage.local.getItem(STORAGE_KEY)
  if (!settings) return
  return settings as {
    last_status_id: Setting
    show_estimated_equipment_hours: Setting
    show_estimated_labor_hours: Setting
    show_estimated_qtys: Setting
  }
}

export default class Settings {
  data: Ref<{
    last_status_id: Setting
    show_estimated_equipment_hours: Setting
    show_estimated_labor_hours: Setting
    show_estimated_qtys: Setting
  }> = ref({ ...defaultSettings, ...getLocalSettings() })

  getSetting(setting: keyof typeof defaultSettings) {
    return this.data.value[setting]?.value || ''
  }

  async fetchSettings() {
    const response = <{ data: any } | undefined>(
      await window.$axios.get('/api/settings').catch()
    )
    if (!response?.data) return
    this.data.value = response.data
    Storage.local.setItem(STORAGE_KEY, response.data)
    return response.data
  }

  async save(payload: Setting) {
    const response = <{ data: any } | undefined>(
      await window.$axios.post('/api/settings', payload)
    )
    if (!response?.data) return
    this.data.value = response.data
    return response.data
  }
}
