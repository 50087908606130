import { App } from 'vue'
import { Database } from '~/database'
const database = new Database()

export const databaseInstall = {
  install: (app: App) => {
    app.config.globalProperties.$database = database
    app.provide('$database', database)
  },
}

export function useDatabase() {
  return database
}
