import useErrorHandling from '~/composables/useErrorHandling'
import Model from '../Model'
import models from '../models'
import { AxiosError } from 'axios'
import { Ref, ref, computed } from 'vue'
import { useLoader } from '~/plugins/loader'
import { useModals } from '~/plugins/modals'
import { useConfirm } from '~/plugins/confirm'
import useOffline from '~/composables/useOffline'

export default function useModelEdits<T extends Model>(
  modelProp: Partial<T>,
  ModelClass: (typeof models)[number],
  errorKeys: string[] = [],
  resolve: Function = () => {},
  options?: {
    bypassQueue?: boolean
    axiosError?: AxiosError
    deleteMessage?: string
  },
) {
  const model: Ref<T> = ref(new ModelClass(modelProp) as unknown as T) as Ref<T>

  const { errors, handleLocalErrors, handleApiErrors } =
    useErrorHandling(errorKeys)

  if (options?.axiosError) {
    handleApiErrors(options.axiosError)
  }

  const offline = useOffline()
  const showDelete = computed(() => {
    if (offline.value) {
      return model.value.id && String(model.value.id).includes('temp')
    }
    return !!model.value.id
  })

  const loader = useLoader()
  const modals = useModals()
  const confirm = useConfirm()

  async function deleteFromApi() {
    if (!model.value.id || model.value.id.includes('temp')) return
    loader.show()
    const result = await model.value.removeFromApi().catch(handleApiErrors)
    loader.hide()
    if (!result || result != 'success') return
    resolve('deleted')
    modals.close()
  }

  async function deleteModel() {
    if (!model.value.id) return
    const confirmed = await confirm(
      options?.deleteMessage || 'Delete this item?',
    )
    if (options?.bypassQueue) return deleteFromApi().catch(handleApiErrors)
    if (!confirmed) return
    const result = await model.value.delete().catch(handleApiErrors)
    if (result != 'success') return
    resolve('deleted')
    modals.close()
  }

  async function saveToApi(close = true): Promise<'success' | 'fail'> {
    loader.show()
    const result = await model.value.saveToApi().catch(handleApiErrors)
    loader.hide()
    if (!result || result != 'success') return 'fail'
    resolve(model.value)
    if (!close) return result
    modals.close()
    return result
  }

  async function save(close = true): Promise<'success' | 'fail'> {
    if (
      options?.bypassQueue ||
      (ModelClass.checkBypassPendingRequests('create') &&
        (!model.value.id || String(model.value.id).includes('temp'))) ||
      (ModelClass.checkBypassPendingRequests('update') &&
        model.value.id &&
        !String(model.value.id).includes('temp'))
    ) {
      return saveToApi(close)
    }
    const validation = model.value.validate()
    if (!validation.success && 'error' in validation) {
      console.warn('Validation error', validation.error)
      console.warn('model.value', model.value)
      handleLocalErrors(validation.error)
      return 'fail'
    }
    if (!validation.success) return 'fail'
    const result = await model.value.save()
    if (result != 'success') return 'fail'
    resolve(model.value)
    if (!close) return result
    modals.close()
    return result
  }

  return {
    model,
    errors,
    showDelete,
    deleteModel,
    save,
    handleApiErrors,
    handleLocalErrors,
  }
}
