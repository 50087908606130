// import Echo from 'laravel-echo'
// import Pusher from 'pusher-js'
// //@ts-ignore
// Pusher.Runtime.createXHR = () => {
//   let xhr = new XMLHttpRequest()
//   xhr.withCredentials = true
//   return xhr
// }

// declare global {
//   interface Window {
//     Pusher: typeof Pusher
//     Echo: Echo
//   }
// }

export default function installEcho() {
  //   window.Pusher = Pusher
  //   window.Echo = new Echo({
  //     broadcaster: 'pusher',
  //     key: import.meta.env.VITE_PUSHER_APP_KEY,
  //     cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
  //     forceTLS: true,
  //     authEndpoint: `${import.meta.env.VITE_API_URL}/broadcasting/auth`,
  //   })
}
