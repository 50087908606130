<template>
  <Modal>
    <template #title>{{ title }}</template>
    <TabBar v-model="category_id" small>
      <TabBarTab value="">All</TabBarTab>
      <TabBarTab
        v-for="category in filteredCategories"
        :key="category.id"
        :value="category.id"
        >{{ category.name }}</TabBarTab
      >
      <template #content>
        <div
          class="flex flex-col-reverse overflow-y-auto"
          style="height: 20rem"
        >
          <div
            v-for="(job, index) in filteredJobs"
            @click="select(job)"
            :key="job.id"
            ref="jobDivs"
            class="flex flex-row items-start px-4 py-1 cursor-pointer hover:bg-gray-200"
            :class="{
              'bg-gray-200': isSelected(job) || index == currentIndex,
            }"
          >
            <div class="flex flex-col mt-2 mr-1">
              <StatusIndicator :status_id="job.current_status_id" small />
              <Icon v-if="job.is_flagged" class="text-base text-red-600">{{
                $icons.FLAG
              }}</Icon>
            </div>
            <div>
              <div class="mr-2 text-sm font-semibold">
                {{ job.customer_name }}
              </div>
              <div class="text-sm font-semibold text-secondary">
                {{ job.address_string }}
              </div>
              <div
                class="text-xs italic text-tertiary"
                :style="{ color: job.job_template_color ?? 'black' }"
              >
                {{ job.job_template_name }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </TabBar>
    <div class="flex flex-row items-center">
      <Textfield
        v-model="search"
        @keydown-enter="select()"
        @keydown-up="incrementIndex()"
        @keydown-down="decrementIndex()"
        :icon="$icons.SEARCH"
        autocomplete="off"
        class="w-full max-w-xs mt-2"
        has-focus
        no-validate
        no-focus-next
        >Search</Textfield
      >
    </div>
    <template #actions class="mx-12">
      <CloseButton @click="$modals.close()" />
      <Button @click="select()" raised>Select</Button>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { computed, PropType, Ref, ref } from 'vue'
import { useModals } from '~/plugins/modals'
import TabBar from '~/components/TabBar.vue'
import TabBarTab from '~/components/TabBarTab.vue'
import StatusIndicator from '~/components/StatusIndicator.vue'
import Icon from '~/components/Icon.vue'
import Textfield from '~/components/Textfield.vue'
import CloseButton from '~/components/CloseButton.vue'
import Button from '~/components/Button.vue'
import { useStore } from '~/plugins/store'
import { useModel } from '~/database'
import Job from '~/database/models/Job'

const props = defineProps({
  resolve: {
    type: Function as PropType<Function>,
    required: true,
  },
  job_id: {
    type: [Number, String] as PropType<string | number>,
    default: '',
  },
  category_id: {
    type: [Number, String] as PropType<string | number>,
    default: '',
  },
  title: {
    type: String as PropType<string>,
    defualt: 'Select Job',
  },
})

const currentIndex = ref(0)

const store = useStore()
const filteredCategories = computed(() => {
  return store.categories.set.value.filter((category) => category.active)
})

//search
const search = ref('')
const category_id = ref('')

const { set: jobSet } = useModel<Job>(Job, 'fromIndexedDB')
const filteredJobs = computed(() => {
  if (!search.value && !category_id.value) return jobSet.value
  return jobSet.value.filter((job) => {
    if (category_id.value && job.category_id != category_id.value) {
      return false
    }
    if (!search.value) return true
    const searchString = search.value.toLowerCase()
    if (
      job.customer_name &&
      job.customer_name.toLowerCase().includes(searchString)
    ) {
      return true
    }
    if (
      job.address_string &&
      job.address_string.toLowerCase().includes(searchString)
    ) {
      return true
    }
    if (
      job.job_template_name &&
      job.job_template_name.toLowerCase().includes(searchString)
    ) {
      return true
    }
    return job.custom_field_values?.some((value) => {
      return value && String(value).toLowerCase().includes(searchString)
    })
  })
})

//selection
const modals = useModals()
async function select(job: Job | undefined = undefined) {
  if (!job && filteredJobs.value) {
    job = filteredJobs.value[currentIndex.value]
  }
  props.resolve(job)
  modals.close()
}
function incrementIndex() {
  if (currentIndex.value == filteredJobs.value.length - 1) {
    currentIndex.value = 0
    return
  }
  currentIndex.value++
  scrollCurrentCustomerIntoView()
}
function decrementIndex() {
  if (currentIndex.value == 0) {
    currentIndex.value = filteredJobs.value.length - 1
    return
  }
  currentIndex.value--
  scrollCurrentCustomerIntoView()
}
const jobDivs: Ref<HTMLDivElement[] | undefined> = ref()
function scrollCurrentCustomerIntoView() {
  if (!jobDivs.value) return
  const el = jobDivs.value[currentIndex.value]
  if (!el) return
  el.scrollIntoView()
}
function isSelected(job: Job) {
  if (!props.job_id) return
  return job.id == props.job_id
}
</script>
