import Model from '../Model'

export default class User extends Model {
  static readonly tableName: string = 'users'
  static readonly apiEndPoint: string = 'api/users'
  static readonly sortKey: string = 'name'
  static readonly hasStoreMany: boolean = true

  active: boolean = true
  name: string = ''
  email: string = ''
  role: 'admin' | 'supervisor' | 'employee' = 'admin'
  password?: string
  password_confirmation?: string

  constructor(payload: Partial<User> = {}) {
    super(payload)
    Object.assign(this, payload)
  }
}
