<template>
  <TransitionGroup
    enter-active-class="enter-active"
    leave-active-class="leave-active"
    enter-from-class="enter-from"
    leave-to-class="leave-to"
  >
    <slot />
  </TransitionGroup>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ModalListTransition',
})
</script>

<style>
.enter-active.modal {
  @apply transition-transform duration-200;
}
.leave-active.modal {
  @apply transition-transform duration-150;
}
.enter-from.modal {
  @apply translate-y-full;
}
.leave-to.modal {
  @apply translate-y-full;
}
@media (min-width: 768px) {
  .enter-active.modal {
    @apply transition;
  }
  .leave-active.modal {
    @apply transition;
  }
  .enter-from.modal {
    @apply translate-y-1/2 scale-150 opacity-0;
  }
  .leave-to.modal {
    @apply translate-y-1/2 scale-150 opacity-0;
  }
}
.enter-active.mask {
  @apply transition-opacity duration-500;
}
.leave-active.mask {
  @apply transition-opacity duration-500;
}
.enter-from.mask {
  @apply opacity-0;
}
.leave-to.mask {
  @apply opacity-0;
}
</style>
