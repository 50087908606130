import { App } from 'vue'
import icons from '@/assets/constants/icons'

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $icons: typeof icons
  }
}

export default {
  install: (app: App) => {
    app.config.globalProperties.$icons = icons
  },
}

export function useIcons() {
  return icons
}
