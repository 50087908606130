import { watch } from 'vue'
import useOffline from './useOffline'
import { useModals } from '~/plugins/modals'

export default function useCloseIfOffline() {
  const offline = useOffline()
  const modals = useModals()
  watch(offline, () => {
    if (offline.value) modals.close()
  })
}
