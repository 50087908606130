import { App, ref } from 'vue'
import { Dictionary } from '~/types'
import { useStore } from './store'
import Status from '~/database/models/Status'
import Item from '~/database/models/Item'
import Category from '~/database/models/Category'
import HoursType from '~/database/models/HoursType'
import Equipment from '~/database/models/Equipment'
import User from '~/database/models/User'
import Model from '~/database/Model'
import Customer from '~/database/models/Customer'

const store = useStore()

function getField(
  resource: 'statuses',
  id: string | number,
  field: keyof Status,
): Status[keyof Status]
function getField(
  resource: 'items',
  id: string | number,
  field: keyof Item,
): Item[keyof Item]
function getField(
  resource: 'customers',
  id: string,
  field: keyof Customer,
): Customer[keyof Customer]
function getField(
  resource: 'categories',
  id: string | number,
  field: keyof Category,
): Category[keyof Category]
function getField(
  resource: 'hoursTypes',
  id: string | number,
  field: keyof HoursType,
): HoursType[keyof HoursType]
function getField(
  resource: 'equipment',
  id: string | number,
  field: keyof Equipment,
): Equipment[keyof Equipment]
function getField(
  resource: 'users',
  id: string | number,
  field: keyof User,
): User[keyof User]
function getField(
  resource:
    | 'items'
    | 'users'
    | 'customers'
    | 'categories'
    | 'statuses'
    | 'hoursTypes'
    | 'equipment',
  id: string | number,
  field: any,
): any {
  if (!id) return ''
  const model = store[resource].record.value[id]
  if (!model) return ''
  if (field in model) return model[field as keyof typeof model] || ''
}

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $getField: typeof getField
  }
}

export default {
  install: (app: App) => {
    app.config.globalProperties.$getField = getField
  },
}

export function useGetField() {
  return getField
}
