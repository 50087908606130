<template>
  <div class="flex flex-row z-1">
    <IconSpinner v-if="syncing" />
    <Icon v-else-if="!hasErrors && doneSyncing" class="text-green-600">{{
      $icons.CHECK
    }}</Icon>
    <IconButton
      v-if="hasErrors"
      @click="viewErrors()"
      :icon="$icons.ERRORS"
      class="text-red-600"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useDatabase } from '~/plugins/database'
import { useModals } from '~/plugins/modals'
import { PendingRequestError } from '~/database/plugins/pendingRequests'
import Model from '~/database/Model'
import PendingRequestErrorsModal from '~/modals/PendingRequestErrorsModal.vue'
import IconSpinner from './IconSpinner.vue'
import Icon from './Icon.vue'
import IconButton from './IconButton.vue'

const database = useDatabase()

const { syncing, hasErrors } = database.pendingRequests

const doneSyncing = ref(false)

watch(syncing, () => {
  if (!syncing.value) {
    doneSyncing.value = true
    setTimeout(() => {
      doneSyncing.value = false
    }, 3000)
  }
})

const modals = useModals()
async function viewErrors() {
  const errors = <PendingRequestError<Model>[]>(
    await modals.show(PendingRequestErrorsModal)
  )
  if (!errors?.length) {
    hasErrors.value = false
  }
}
</script>

<style scoped></style>
