<template>
  <div
    @click="$emit('close')"
    class="absolute w-full h-full bg-white bg-opacity-75 z-3 mask"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ModalMask',
})
</script>

<style scoped></style>
