<template>
  <ModalMaskTransition>
    <ModalMask v-if="show" class="z-3" />
  </ModalMaskTransition>
  <ModalTransition>
    <Modal v-if="show" @close="cancel" class="z-4 pointer-events-auto">
      <p>{{ message }}</p>
      <template #actions>
        <Button @click="cancel">Cancel</Button>
        <Button @click="agree" raised>Confirm</Button>
      </template>
    </Modal>
  </ModalTransition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useConfirmItems } from '~/plugins/confirm'

export default defineComponent({
  name: 'Confirm',
  setup() {
    const { show, message, resolve } = useConfirmItems()
    const agree = () => {
      if (!resolve?.value) return console.warn('Resolve Not Promisized')
      resolve.value(true)
      show.value = false
    }
    const cancel = () => {
      if (!resolve?.value) return console.warn('Resolve Not Promisized')
      resolve.value(false)
      show.value = false
    }
    return { show, message, agree, cancel }
  },
})
</script>
