<template>
  <button
    class="p-2 w-full bg-primary bg-opacity-0 transition-colors duration-300 ease-in-out flex flex-row items-center rounded border"
    :class="{
      'border-secondary': selected,
      'hover:bg-opacity-10 focus:bg-opacity-10 cursor-pointer border-primary':
        !disabled,
      'border-gray-400 text-gray-500 cursor-default': disabled,
    }"
    :style="{ color: color }"
    :disabled="disabled"
  >
    <Icon class="mr-2">{{ icon }}</Icon>
    <slot />
  </button>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'MenuButton',
  props: {
    icon: {
      type: String,
      default: '',
    },
    selected: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#000000',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const color = computed(() => {
      return props.color
    })

    return { color }
  },
})
</script>

<style scoped>
button:focus {
  outline: none;
}
</style>
