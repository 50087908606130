<template>
  <Textfield
    @update:modelValue="setPhoneNumber($event)"
    @keydown="$emit('keydown')"
    :modelValue="phoneNumberString"
    type="text"
    :error="error"
    :icon="$icons.PHONE"
  >
    <slot />
  </Textfield>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { useFormatPhoneNumber } from '~/composables/usePhoneNumbers'

export default defineComponent({
  name: 'PhoneNumberInput',
  props: {
    modelValue: {
      type: String as PropType<string>,
      default: '',
    },
    error: {
      type: String as PropType<string>,
      default: '',
    },
  },
  setup: (props, { emit }) => {
    const formatPhoneNumber = useFormatPhoneNumber()
    const phoneNumberString = computed(() => {
      return formatPhoneNumber(props.modelValue)
    })
    const setPhoneNumber = (value: string) => {
      const cleanedNumber = `+1${value.replace(/[^\d]/g, '').slice(0, 10)}`
      emit('update:modelValue', cleanedNumber)
    }
    return { phoneNumberString, setPhoneNumber }
  },
})
</script>

<style scoped></style>
