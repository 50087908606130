import Category from './Category'
import ChecklistItem from './ChecklistItem'
import CustomField from './CustomField'
import Customer from './Customer'
import Equipment from './Equipment'
import File from './File'
import Flag from './Flag'
import HoursType from './HoursType'
import Image from './Image'
import Item from './Item'
import Job from './Job'
import JobEquipmentHour from './JobEquipmentHour'
import JobItem from './JobItem'
import JobLaborHour from './JobLaborHour'
import JobStatus from './JobStatus'
import Note from './Note'
import Status from './Status'
import Task from './Task'
import User from './User'

export default [
  Category,
  ChecklistItem,
  Customer,
  CustomField,
  Equipment,
  File,
  Flag,
  HoursType,
  Image,
  Item,
  Job,
  JobEquipmentHour,
  JobItem,
  JobLaborHour,
  JobStatus,
  Note,
  Status,
  Task,
  User,
]
