import { z } from 'zod'

export class Geotag {
  static ZodValidator = z.object({
    lat: z.number().min(-90).max(90),
    lng: z.number().min(-180).max(180),
  })
  lat: number = 45.818633
  lng: number = -122.6653767
}
