import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import { authInstall } from '~/plugins/auth'
import axios from '~/plugins/axios'
import icons from '~/plugins/icons'
import { modalsInstall } from '~/plugins/modals'
import { databaseInstall } from '~/plugins/database'
import store from '~/plugins/store'
import confirm from '~/plugins/confirm'
import alert from '~/plugins/alert'
import loader from '~/plugins/loader'
import getField from '~/plugins/getField'
import { createRouter, createWebHashHistory } from 'vue-router'
// @ts-ignore
import routes from 'virtual:generated-pages'
// @ts-ignore
import { useRegisterSW } from 'virtual:pwa-register/vue'

import LogRocket from 'logrocket'
import * as Sentry from '@sentry/vue'

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})
//axios does not plug directly into vue
axios.install()

const app = createApp(App)

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: 'https://de5fa6138f21a1ce18b4c3ac9ddc1790@o4507488530530304.ingest.us.sentry.io/4507488534528000',
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })

  setTimeout(() => {
    LogRocket.init('zqxria/seppanen-construction')
  }, 1000)
}

app
  .use(databaseInstall)
  .use(store)
  .use(icons)
  .use(authInstall)
  .use(modalsInstall)
  .use(confirm)
  .use(alert)
  .use(loader)
  .use(getField)
  .use(router)
  .mount('#app')

const { needRefresh, updateServiceWorker } = useRegisterSW()

if (needRefresh.value) {
  updateServiceWorker()
}
