<template>
  <span>
    <transition name="spinner">
      <LoadingSpinner
        v-if="showLoader"
        @close="hide()"
        @go-offline="goOffline()"
      />
    </transition>
  </span>
</template>

<script lang="ts" setup>
import { useDatabase } from '~/plugins/database'
import { useLoaderItems } from '~/plugins/loader'

const { showLoader, hide } = useLoaderItems()

const database = useDatabase()
function goOffline() {
  database.connection.goOffline()
  hide()
}
</script>

<style scoped>
/*
 * The following styles are auto-applied to elements with
 * transition="spinner" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the spinner transition by editing
 * these styles.
 */

.spinner-enter {
  opacity: 0;
}

.spinner-leave-active {
  opacity: 0;
}

.spinner-enter .spinner-container,
.spinner-leave-active .spinner-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
