<template>
  <Modal width-class="max-w-5xl">
    <template #title>{{ model.id ? 'Edit Job' : 'Add Job' }}</template>
    <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
      <div>
        <JobTemplateInput
          @update:job_template_id="setJobTemplateId($event)"
          :job_template_id="model.job_template_id"
          v-model:category_id="model.category_id"
          :error="errors.job_template_id"
          :disabled="!!model.id"
        />
        <CustomerInput
          v-model="model.customer_id"
          @update:model-value="errors.customer_id = ''"
          :error="errors.customer_id"
          create-enabled
          >Customer</CustomerInput
        >
        <CustomFieldValues
          v-model="model.custom_field_values"
          :job="model"
          fieldable_type="App\Models\Job"
        />
      </div>
      <div>
        <AddressInput
          v-if="model.address"
          v-model="model.address"
          :error="errors.address"
          @input="errors.address = ''"
          :apiKey="googleKey"
        />
      </div>
    </div>
    <JobStatus
      v-model="model.current_status_id"
      :job="model"
      class="mx-auto"
      :disabled="!model.category_id"
    />
    <template #actions>
      <CloseButton @click="$modals.close()" />
      <DeleteButton v-if="showDelete" @click="deleteModel()" />
      <div class="flex flex-col items-end gap-1">
        <Button @click="saveJob(undefined, true)" :icon="$icons.OPEN"
          >Save & Open</Button
        >
        <Button @click="saveJob(true)" :icon="$icons.SAVE">Save & Close</Button>
        <SaveButton @click="saveJob()" />
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { AxiosError } from 'axios'
import { PropType, computed, ref } from 'vue'
import useCloseIfOffline from '~/composables/useCloseIfOffline'
import { useAModel, useModel, useModelEdits } from '~/database'
import Job from '~/database/models/Job'
import { Address } from '~/database/models/Address'
import { useLoader } from '~/plugins/loader'
import { useModals } from '~/plugins/modals'
import { useRouter } from 'vue-router'
import useApiErrorHandling from '~/composables/useApiErrorHandling'
import Flag from '~/database/models/Flag'
import Note from '~/database/models/Note'
import JobItem from '~/database/models/JobItem'
import Task from '~/database/models/Task'
import File from '~/database/models/File'
import Modal from '~/components/Modal.vue'
import JobTemplateInput from '~/components/JobTemplateInput.vue'
import CustomerInput from '~/components/CustomerInput.vue'
import CustomFieldValues from '~/components/CustomFieldValues.vue'
import AddressInput from '~/components/AddressInput.vue'
import JobStatus from '~/components/JobStatus.vue'
import CloseButton from '~/components/CloseButton.vue'
import DeleteButton from '~/components/DeleteButton.vue'
import Button from '~/components/Button.vue'
import SaveButton from '~/components/SaveButton.vue'

const props = defineProps({
  job: {
    type: Object as PropType<Partial<Job>>,
    default: () => ({}),
  },
  resolve: {
    type: Function as PropType<Function>,
    required: true,
  },
  axiosError: {
    type: [Object, undefined] as PropType<AxiosError | undefined>,
    default: () => undefined,
  },
  bypassQueue: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  category_id: {
    type: [Number, String] as PropType<string>,
    default: '',
  },
  name: {
    type: String as PropType<string>,
    default: '',
  },
  duplicate: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

useCloseIfOffline()

const { model, errors, showDelete, deleteModel, save } = useModelEdits<Job>(
  props.job,
  Job,
  ['customer_id', 'category_id', 'job_template_id', 'address'],
  props.resolve,
  {
    axiosError: props.axiosError,
    bypassQueue: props.bypassQueue,
    deleteMessage: 'Delete this job?',
  },
)

const googleKey = ref(import.meta.env.VITE_GOOGLE_KEY)

if (props.category_id) {
  model.value.category_id = props.category_id
}
if (!model.value.address) {
  model.value.address = new Address({
    address: '',
    geotag: {
      lat: 45.818633,
      lng: -122.6653767,
    },
  })
}

const jobTemplate = useAModel<Job>(
  Job,
  computed(() => model.value.job_template_id),
)

async function initDuplicate() {
  const template =
    jobTemplate.value ||
    (await Job.fetchItemFromApi<Job>(model.value.job_template_id))
  model.value.current_status_id = template?.current_status_id || ''
}

if (props.duplicate && !model.value.id) {
  initDuplicate()
}

//job template
const { record: jobs } = useModel<Job>(Job)
function setJobTemplateId(job_template_id: string) {
  model.value.job_template_id = job_template_id
  const template = jobs.value[job_template_id]
  if (!template?.id) return
  const { custom_field_values, customer_id, current_status_id } = JSON.parse(
    JSON.stringify(template),
  )
  if (custom_field_values?.length) {
    model.value.custom_field_values = custom_field_values
  }
  if (customer_id) model.value.customer_id = customer_id
  if (current_status_id) model.value.current_status_id = current_status_id
}

const loader = useLoader()
const modals = useModals()
const { handleErrors } = useApiErrorHandling()
const router = useRouter()
async function saveJob(close = false, open = false) {
  const saveTemplateItems =
    !model.value.id && jobTemplate.value?.id && !props.duplicate
  if (saveTemplateItems) {
    model.value.items = (jobTemplate.value.items || []).map((jobItem) => {
      const newJobItem = JSON.parse(JSON.stringify(jobItem))
      newJobItem.job_id = model.value.id
      delete newJobItem.id
      newJobItem.key = ''
      return newJobItem
    })
  }
  loader.show()
  const result = await save(false)
  loader.hide()
  if (result == 'fail') return
  if (saveTemplateItems) {
    //copy template children
    loader.show()
    const flags = (jobTemplate.value.flags || []).map((flag) => {
      const newFlag = JSON.parse(JSON.stringify(flag))
      newFlag.flaggable_id = model.value.id
      delete newFlag.id
      newFlag.key = ''
      return newFlag
    })
    const notes = (jobTemplate.value.notes || []).map((note) => {
      const newNote = JSON.parse(JSON.stringify(note))
      newNote.notable_id = model.value.id
      delete newNote.id
      newNote.key = ''
      return newNote
    })
    const files = (jobTemplate.value.files || []).map((file) => {
      const newFile = JSON.parse(JSON.stringify(file))
      newFile.filable_id = model.value.id
      delete newFile.id
      newFile.key = ''
      return newFile
    })

    const tasks = (jobTemplate.value.tasks || []).map((task) => {
      const newTask = JSON.parse(JSON.stringify(task))
      newTask.job_id = model.value.id
      delete newTask.id
      newTask.key = ''
      return newTask
    })
    await Promise.all([
      flags.length && Flag.saveMany(flags).catch(handleErrors),
      notes.length && Note.saveMany(notes).catch(handleErrors),
      tasks.length && Task.saveMany(tasks).catch(handleErrors),
      files.length && File.saveMany(files).catch(handleErrors),
    ])
    loader.hide()
  }
  if (close) {
    router.push('/')
    modals.close()
  }
  if (open) {
    modals.close()
    router.push(`/jobs/${model.value.id}`)
  }
}
</script>

<style scoped></style>
