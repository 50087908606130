<template>
  <Modal>
    <template #title>{{ model.id ? 'Edit Flag' : 'Add Flag' }}</template>
    <Textarea
      v-model="model.description"
      @keydown="errors.description = ''"
      id="description"
      :error="errors.description"
      has-focus
      >Description</Textarea
    >
    <SelectMenu
      v-model="model.assigned_id"
      @update:model-value="errors.assigned_id = ''"
      :error="errors.assigned_id"
      :options="filteredUsers"
      valueKey="id"
      labelKey="name"
      subtitle="Flag"
    >
      Assigned To
    </SelectMenu>
    <template #actions>
      <CloseButton @click="$modals.close()" />
      <DeleteButton v-if="showDelete" @click="deleteModel()" />
      <SaveButton @click="save()" />
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'
import Flag from '~/database/models/Flag'
import { AxiosError } from 'axios'
import { useModelEdits } from '~/database'
import useOffline from '~/composables/useOffline'
import { useStore } from '~/plugins/store'
import Modal from '~/components/Modal.vue'
import Textarea from '~/components/Textarea.vue'
import SelectMenu from '~/components/SelectMenu.vue'
import CloseButton from '~/components/CloseButton.vue'
import DeleteButton from '~/components/DeleteButton.vue'
import SaveButton from '~/components/SaveButton.vue'

const props = defineProps({
  flag: {
    type: Object as PropType<Partial<Flag>>,
    default: () => ({}),
  },
  resolve: {
    type: Function as PropType<Function>,
    required: true,
  },
  axiosError: {
    type: [Object, undefined] as PropType<AxiosError | undefined>,
    default: () => undefined,
  },
  bypassQueue: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  flaggable_id: {
    type: [Number, String] as PropType<string>,
    default: '',
  },
  flaggable_type: {
    type: String as PropType<Flag['flaggable_type']>,
    default: '',
  },
})

const { model, errors, deleteModel, save } = useModelEdits<Flag>(
  props.flag,
  Flag,
  ['description', 'assigned_id'],
  props.resolve,
  {
    axiosError: props.axiosError,
    bypassQueue: props.bypassQueue,
    deleteMessage: 'Delete this flag?',
  },
)

const offline = useOffline()

if (props.flaggable_type) {
  model.value.flaggable_type = props.flaggable_type
}
if (props.flaggable_id) {
  model.value.flaggable_id = props.flaggable_id
}

const showDelete = computed(() => {
  if (offline.value) {
    return model.value.id && String(model.value.id).includes('temp')
  }
  return !!model.value.id
})

const store = useStore()
const filteredUsers = computed(() => {
  return store.users.set.value.filter((user) => {
    return user.id == props.flag.assigned_id || user.active
  })
})
</script>

<style scoped></style>
