<template>
  <Modal>
    <template v-if="subtitle" #subtitle>{{ subtitle }}</template>
    <template #title>{{ title }}</template>
    <Textfield
      v-model="text"
      @keydown-enter="save()"
      id="text"
      :type="type"
      has-focus
      no-focus-next
      >{{ label }}</Textfield
    >
    <template #actions>
      <CloseButton @click="close()" />
      <DeleteButton v-if="showDelete" @click="deleteField()" />
      <SaveButton @click="save()" />
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { PropType, ref } from 'vue'
import CloseButton from '~/components/CloseButton.vue'
import DeleteButton from '~/components/DeleteButton.vue'
import Modal from '~/components/Modal.vue'
import SaveButton from '~/components/SaveButton.vue'
import Textfield from '~/components/Textfield.vue'
import { useModals } from '~/plugins/modals'

const props = defineProps({
  subtitle: {
    type: String as PropType<string>,
    default: '',
  },
  title: {
    type: String as PropType<string>,
    default: '',
  },
  value: {
    type: String as PropType<string>,
    default: '',
  },
  type: {
    type: String as PropType<string>,
    default: 'text',
  },
  label: {
    type: String as PropType<string>,
    default: '',
  },
  showDelete: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  resolve: {
    type: Function as PropType<Function>,
    default: () => null,
  },
})

const modals = useModals()
const close = modals.close

const text = ref(props.value)
function save() {
  props.resolve(text.value)
  close()
}
function deleteField() {
  props.resolve('deleted')
  close()
}
</script>
