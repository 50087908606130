import Dates from './Dates'

function dateRangePayloadTransform(
  payload: Record<string, any>,
): Record<string, any>
function dateRangePayloadTransform(
  payload: Record<string, any>,
): Record<string, any>
function dateRangePayloadTransform(
  payload: Record<string, any> | Record<string, any>,
) {
  if (!('start' in payload) || !('end' in payload)) return payload
  if (!payload?.start || !payload?.end) return payload
  return {
    ...payload,
    start: Dates.formatISO(<any>payload.start, 'date'),
    end: Dates.formatISO(<any>payload.end, 'date'),
  }
}

export default dateRangePayloadTransform
