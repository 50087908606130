import { Loader } from '@googlemaps/js-api-loader'
import {
  InjectionKey,
  Ref,
  computed,
  inject,
  onMounted,
  provide,
  ref,
} from 'vue'

interface GoogleData {
  api: typeof google | undefined
  map: google.maps.Map | undefined
  loaded: Ref<boolean>
  zoom: Ref<number>
}

export const googleDataKey = Symbol() as InjectionKey<GoogleData>

export function provideGoogleMapsData(
  apiKey: string,
  mapId: string,
  mapdiv: Ref<HTMLDivElement | undefined>,
  mapOptions: google.maps.MapOptions = {},
) {
  const googleData: GoogleData = {
    api: undefined,
    map: undefined,
    loaded: ref(false),
    zoom: ref(8),
  }

  provide(googleDataKey, googleData)

  const loader = new Loader({
    apiKey,
    libraries: ['places', 'marker'],
  })

  const defaultOptions = {
    center: { lat: 45.81938072674977, lng: -122.66331674781095 },
    zoom: 9,
  }

  function initializeMap() {
    if (!googleData.api) {
      return console.error('Missing Google')
    }
    if (!mapdiv.value) {
      return console.error('Missing mapdiv')
    }
    const map = new googleData.api.maps.Map(mapdiv.value, {
      ...defaultOptions,
      ...mapOptions,
      mapId,
    })
    map.addListener('zoom_changed', () => {
      googleData.zoom.value = map.getZoom() || 8
    })
    googleData.map = map
    setTimeout(() => {
      googleData.loaded.value = true
    }, 10)
  }

  onMounted(() => {
    loader.load().then(() => {
      googleData.api = window.google
      initializeMap()
    })
  })

  return googleData
}

export function useProvidedGoogleMapsData() {
  const googleData = inject(googleDataKey) || {
    api: undefined,
    map: undefined,
    loaded: ref(false),
    zoom: ref(8),
  }

  const loaded = computed(() => {
    return googleData.loaded.value
  })

  const zoom = computed(() => {
    return googleData.zoom.value
  })

  return {
    googleData,
    api: googleData.api,
    map: googleData.map,
    loaded,
    zoom,
  }
}
