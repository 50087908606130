//Material Icons https://fonts.google.com/icons?selected=Material+Icons

export default {
  ADD: 'add',

  ADD_ANOTHER: 'add',

  ADD_CIRCLE: 'add_circle',

  ADD_FILE: 'insert_drive_file',

  ADD_NOTE: 'note_add',

  ADD_PHOTO: 'add_photo_alternate',

  ALL: 'open_in_new',

  ANALYSIS: 'show_chart',

  APPROVE: 'check_circle',

  APPROVED: 'check',

  APPROVED_PAYROLL: 'done_all',

  ARROW_UP: 'arrow_upward',
  ARROW_DOWN: 'arrow_downward',

  ASSIGNED: 'assignment_ind',

  BACK: 'arrow_back',

  BRANCHES: 'account_tree',

  BREAK_SYNC: 'sync_disabled',

  CALCULATE: 'calculate',

  CALENDAR: 'date_range',

  CATEGORIES: 'category',
  CATEGORY: 'category',

  CHECK: 'check',

  CHECKBOX_CHECKED: 'check_box',

  CHECKBOX_NOT_CHECKED: 'check_box_outline_blank',

  CHECK_LIST: 'done_all',

  CLIENTS: 'people_outline',

  CLOSE: 'close',

  CONNECT: 'sync',

  COLAPSE: 'keyboard_arrow_up',

  COPY: 'file_copy',

  COST_CODES: 'code',

  CREW: 'groups',

  CREWS: 'groups',

  DAY: 'schedule',

  EQUAL: 'drag_handle',

  EQUIPMENT: 'agriculture',

  ERRORS: 'error',

  ESTIMATED: 'leaderboard',

  FOREMAN: 'engineering',

  DASHBOARD: 'multiline_chart',

  DATE: 'insert_invitation',

  DELETE: 'delete',

  DETAILS: 'description',

  DOLLAR: 'attach_money',

  DOWNLOAD: 'cloud_download',

  DRAFT: 'description',

  EDIT: 'edit',

  EDITED: 'priority_high',

  EXPAND: 'keyboard_arrow_down',

  FIELDS: 'list',

  FILE: 'insert_drive_file',

  FILES: 'folder',

  FINISH: 'nature',

  FLAG: 'flag',

  FORWARD: 'arrow_forward',

  FULLSCREEN: 'fullscreen',

  GO: 'directions_run',

  HANDLE: 'drag_indicator',

  HEIGHT: 'unfold_more',

  HIDDEN: 'visibility_off',

  HISTORY: 'history',

  HOME: 'home',

  IMAGE: 'photo',

  IMAGES: 'photo',

  IMPORTANT: 'priority_high',

  INFO: 'info',

  INVOICE: 'request_quote',

  ITEM: 'widgets',

  ITEMS: 'widgets',

  JOB: 'domain',
  JOBS: 'domain',

  LEFT: 'chevron_left',

  LINE_ITEM: 'DOLLAR',

  LOCATION: 'my_location',

  LOCATION_NOT_FOUND: 'location_disabled',

  LOCK: 'lock',

  LOOP: 'loop',

  LUNCH: 'lunch_dining',

  MENU: 'menu',

  NAVIGATE: 'navigation',

  NEXT: 'arrow_forward',

  NO_ACCESS: 'block',

  NOTE: 'description',

  NOTES: 'description',

  NUMBER: 'looks_one',

  OFFLINE: 'cloud_off',

  OPEN: 'open_in_new',

  PARAMETERS: 'playlist_add_check',

  PAY: 'credit_card',

  PAYROLL: 'account_box',

  PDF: 'picture_as_pdf',

  PICKUP: '360',

  PIPE: 'water_damage',

  POWER: 'power_settings_new',

  PREVIOUS: 'arrow_back',

  PRINT: 'print',

  PICKUP_ITEM_SCHEDULED: 'local_shipping',

  PHONE: 'phone',

  PRODUCTS: 'store',

  READY: 'play_circle_filled',

  REFRESH: 'refresh',

  REMOVE: 'close',

  RESTORE: 'restore_from_trash',

  RIGHT: 'chevron_right',

  ROLES: 'admin_panel_settings',

  SAVE: 'save',

  SCHEDULE: 'date_range',

  SEND: 'send',

  SMS: 'sms',

  SEARCH: 'search',

  SELECT: 'list',

  SETTINGS: 'settings',

  SHOPPING_LIST: 'shopping_cart',

  STAGES: 'category',

  STATUS: 'assignment_turned_in',
  STATUSES: 'assignment_turned_in',

  STOP: 'report',

  SUBTRACT: 'remove',

  SYNCED: 'sync',

  TASK: 'check',

  TASKS: 'done_all',

  TEMPLATES: 'file_copy',

  TEXT: 'text_fields',

  TIME: 'watch_later', //'access_time',

  TODO: 'done_all',

  USER: 'person',

  USERS: 'people',

  VIEW: 'remove_red_eye',

  VISIBLE: 'visibility',

  VOID: 'block',

  WAITING_FOR_PRODUCTS: 'store',

  WIDTH: 'code',

  WIZARD: 'bolt',
}
