import Model from '../Model'

export default class HoursType extends Model {
  static readonly tableName: string = 'hoursTypes'
  static readonly apiEndPoint: string = 'api/hours_types'
  static readonly sortKey: string = 'created_at'
  static readonly sortDirection = 'desc'

  name: string = ''
  active: boolean = true
  category_list: string[] = []

  constructor(payload: Partial<HoursType> = {}) {
    super(payload)
    Object.assign(this, payload)
  }
}
