<template>
  <Modal>
    <template #title>{{
      model.id ? 'Edit Customer' : 'Add Customer'
    }}</template>
    <Textfield
      v-model="model.name"
      @keydown="errors.name = ''"
      id="name"
      :error="errors.name"
      >Name</Textfield
    >
    <template #actions>
      <CloseButton @click="$modals.close()" />
      <DeleteButton v-if="showDelete" @click="deleteModel()" />
      <SaveButton @click="save()" />
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { PropType } from 'vue'
import Customer from '~/database/models/Customer'
import { AxiosError } from 'axios'
import { useModelEdits } from '~/database'
import Modal from '~/components/Modal.vue'
import Textfield from '~/components/Textfield.vue'
import CloseButton from '~/components/CloseButton.vue'
import DeleteButton from '~/components/DeleteButton.vue'
import SaveButton from '~/components/SaveButton.vue'

const props = defineProps({
  customer: {
    type: Object as PropType<Partial<Customer>>,
    default: () => ({}),
  },
  resolve: {
    type: Function as PropType<Function>,
    required: true,
  },
  axiosError: {
    type: [Object, undefined] as PropType<AxiosError | undefined>,
    default: () => undefined,
  },
  bypassQueue: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const { model, errors, showDelete, deleteModel, save } =
  useModelEdits<Customer>(
    props.customer,
    Customer,
    ['name', 'email'],
    props.resolve,
    {
      axiosError: props.axiosError,
      bypassQueue: props.bypassQueue,
      deleteMessage: 'Delete this customer along with all their jobs?',
    },
  )
</script>

<style scoped></style>
