<template>
  <div
    @click.stop="handleClick()"
    class="p-1 relative cursor-pointer flex flex-row items-center text-sm"
    :id="id"
  >
    <Icon
      :class="{
        'text-gray-400': disabled,
      }"
      >{{
        !!modelValue ? $icons.CHECKBOX_CHECKED : $icons.CHECKBOX_NOT_CHECKED
      }}</Icon
    >
    <input
      @focus="focused = true"
      @blur="focused = false"
      type="checkbox"
      :checked="!!modelValue"
      :ref="`${id}-input`"
      :id="`${id}-input`"
      class="absolute top-0 left-0 opacity-0 pointer-events-none"
      :disabled="disabled"
    />
    <span v-if="$slots.default" :class="{ 'text-gray-400': disabled }">
      <slot />
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'Checkbox',
  props: {
    modelValue: {
      type: [Boolean, Number],
      default: false,
    },
    id: {
      type: String,
      default: 'switch',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const focused = ref(false)
    const input = ref<HTMLInputElement>()
    const handleClick = () => {
      if (props.disabled) return
      input?.value?.focus()
      focused.value = true
      context.emit('update:modelValue', !props.modelValue)
    }

    return { focused, handleClick }
  },
})
</script>
