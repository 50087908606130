import Model from '../Model'
import Flag from './Flag'
import File from './File'
import Note from './Note'
import Image from './Image'
import JobEquipmentHour from './JobEquipmentHour'
import { CustomFieldValue } from './CustomFieldValue'

export default class Equipment extends Model {
  static readonly tableName: string = 'equipment'
  static readonly apiEndPoint: string = 'api/equipment'
  static readonly sortKey: string = 'name'

  name: string = ''
  description: string = ''
  active: boolean = true
  custom_field_values?: CustomFieldValue[] = []
  is_flagged?: boolean
  job_hours?: JobEquipmentHour[] = []
  flags?: Flag[]
  files?: File[]
  notes?: Note[]
  images?: Image[]

  constructor(payload: Partial<Equipment> = {}) {
    super(payload)
    Object.assign(this, payload)
  }
}
