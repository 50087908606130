import { z } from 'zod'
import Model from '../Model'

export default class ChecklistItem extends Model {
  static readonly tableName: string = 'checklistItems'
  static readonly apiEndPoint: string = 'api/checklist_items'
  static readonly sortKey: string = 'created_at'
  static readonly sortDirection = 'desc'

  description: string = ''
  completed_at: Date | string = ''
  completed_id: number | string = ''

  static ZodValidator = z.object({
    description: z
      .string({
        required_error: 'The description field is required.',
      })
      .min(1, 'The description field is required.')
      .max(20000),
    completed_at: z.union([z.date(), z.string()]).nullable(),
  })

  constructor(payload: Partial<ChecklistItem> = {}) {
    super(payload)
    Object.assign(this, payload)
  }
}
