import { App } from 'vue'
import Store from '~/store.ts'

const store = new Store()

export default {
  install: (app: App) => {
    store.install()
    app.config.globalProperties.$store = store
    app.provide('$store', store)
  },
}

export function useStore() {
  return store
}
