<template>
  <Modal>
    <template #title>{{ title }}</template>
    <ToggleSwitch
      v-model="search.with_archived_jobs"
      class="mb-2 ml-2"
      @update:model-value="fetchData()"
      >Include Archived Jobs</ToggleSwitch
    >
    <TabBar
      v-model="search.category_id"
      @update:model-value="fetchData()"
      small
    >
      <TabBarTab value="">All</TabBarTab>
      <TabBarTab value="flagged" class="flex items-center">
        <Icon>
          {{ $icons.FLAG }}
        </Icon>
      </TabBarTab>
      <TabBarTab
        v-for="category in filteredCategories"
        :key="category.id"
        :value="category.id"
        >{{ category.name }}</TabBarTab
      >
      <template #content>
        <div
          class="flex flex-col-reverse overflow-y-auto"
          style="height: 20rem"
        >
          <div
            v-for="(job, index) in searchResults"
            @click="select(job)"
            :key="job.id"
            ref="jobDivs"
            class="flex flex-row items-start px-4 py-1 cursor-pointer hover:bg-gray-200"
            :class="{
              'bg-gray-200': isSelected(job) || index == currentIndex,
            }"
          >
            <div class="flex flex-col mt-2 mr-1">
              <StatusIndicator :status_id="job.current_status_id" small />
              <Icon v-if="job.is_flagged" class="text-base text-red-600">{{
                $icons.FLAG
              }}</Icon>
            </div>
            <div>
              <div class="mr-2 text-sm font-semibold">
                {{ job.customer_name }}
              </div>
              <div class="text-sm font-semibold text-secondary">
                {{ job.address_string }}
              </div>
              <div
                class="text-xs italic text-tertiary"
                :style="{ color: job.job_template_color ?? 'black' }"
              >
                {{ job.job_template_name }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </TabBar>
    <div class="flex flex-row items-center">
      <Textfield
        v-model="search.search"
        @update:model-value="handleSearchInput()"
        @keydown-enter="select()"
        @keydown-up="incrementIndex()"
        @keydown-down="decrementIndex()"
        :icon="$icons.SEARCH"
        autocomplete="off"
        class="w-full max-w-xs mt-2"
        has-focus
        no-validate
        no-focus-next
        >Search</Textfield
      >
      <FadeTransition>
        <IconSpinner v-if="loading" />
      </FadeTransition>
    </div>
    <template #actions class="mx-12">
      <CloseButton @click="$modals.close" />
      <Button @click="select()" raised>Select</Button>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { PropType, Ref, computed, ref, watch } from 'vue'
import Storage from '~/assets/constants/Storage'
import Button from '~/components/Button.vue'
import CloseButton from '~/components/CloseButton.vue'
import FadeTransition from '~/components/FadeTransition.vue'
import Icon from '~/components/Icon.vue'
import IconSpinner from '~/components/IconSpinner.vue'
import Modal from '~/components/Modal.vue'
import StatusIndicator from '~/components/StatusIndicator.vue'
import TabBar from '~/components/TabBar.vue'
import TabBarTab from '~/components/TabBarTab.vue'
import Textfield from '~/components/Textfield.vue'
import ToggleSwitch from '~/components/ToggleSwitch.vue'
import useOffline from '~/composables/useOffline'
import { useModelPaginated } from '~/database'
import Job from '~/database/models/Job'
import { useLoader } from '~/plugins/loader'
import { useModals } from '~/plugins/modals'
import { useStore } from '~/plugins/store'

const props = defineProps({
  resolve: {
    type: Function,
    default: () => null,
  },
  job_id: {
    type: [Number, String] as PropType<string | number>,
    default: '',
  },
  category_id: {
    type: [Number, String] as PropType<string | number>,
    default: '',
  },
  title: {
    type: String as PropType<string>,
    defualt: 'Select Job',
  },
})

const currentIndex = ref(0)
const loader = useLoader()

const store = useStore()
const filteredCategories = computed(() => {
  return store.categories.set.value.filter((category) => category.active)
})

const STORAGE_KEY = 'job-lookup-search'

const DEFAULT_SEARCH = {
  search: '',
  per_page: 10,
  category_id: props.category_id,
  with_attributes: true,
  with_archived_jobs: false,
}

function getSearch() {
  const search = Storage.session.getItem(STORAGE_KEY)
  if (!search) return DEFAULT_SEARCH
  return {
    ...DEFAULT_SEARCH,
    ...search,
  }
}

//search
const search: Ref<Record<string, any>> = ref(getSearch())

watch(
  search,
  (value) => {
    Storage.session.setItem(STORAGE_KEY, value)
  },
  {
    deep: true,
  },
)

const {
  searchResults,
  handleSearchInput,
  nextPage,
  previousPage,
  fetchData,
  currentPage,
  lastPage,
  loading,
} = useModelPaginated<Job>(Job, search, useOffline(), {})

//selection
const modals = useModals()
async function select(
  job: Job | undefined | { name: string; id: string } = undefined,
) {
  if (!job && searchResults.value) job = searchResults.value[currentIndex.value]
  if (job?.id && job?.id != 'create') {
    loader.show()
    await Job.fetchItem(job.id)
    loader.hide()
  }
  props.resolve(job)
  modals.close()
}
function incrementIndex() {
  if (currentIndex.value == searchResults.value.length - 1) {
    currentIndex.value = 0
    return
  }
  currentIndex.value++
  scrollCurrentCustomerIntoView()
}
function decrementIndex() {
  if (currentIndex.value == 0) {
    currentIndex.value = searchResults.value.length - 1
    return
  }
  currentIndex.value--
  scrollCurrentCustomerIntoView()
}
const jobDivs: Ref<HTMLDivElement[] | undefined> = ref()
function scrollCurrentCustomerIntoView() {
  if (!jobDivs.value) return
  const el = jobDivs.value[currentIndex.value]
  if (!el) return
  el.scrollIntoView()
}
function isSelected(job: Job | { name: string; id: string }) {
  if (!props.job_id) return
  return job.id == props.job_id
}
fetchData()
</script>
