<template>
  <DefaultLayout :loaded="loaded">
    <RouterView />
  </DefaultLayout>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import DefaultLayout from './layouts/default.vue'
import { RouterView } from 'vue-router'
import { useLoader } from './plugins/loader'
import { useDatabase } from './plugins/database'
import { useAuth, useUser } from './plugins/auth'
import useApiErrorHandling from './composables/useApiErrorHandling'

const loaded = ref(false)
const loader = useLoader()

const database = useDatabase()

const auth = useAuth()
async function getUser() {
  return await auth?.getUser()
}

// const timeclock = useTimeclock()

const authUser = useUser()
const { handleErrors } = useApiErrorHandling()
async function getData() {
  loader.show()
  await getUser()
  await database.initialize()
  if (authUser.value?.id) {
    // await timeclock.fetchCurrentEntries().catch(handleErrors)
  }
  loader.hide()
  loaded.value = true
}

onMounted(() => {
  getData()
})
</script>

<style></style>
