<template>
  <ModalTransition>
    <Modal @close="close" v-if="show" class="z-4 pointer-events-auto">
      <p class="whitespace-pre-line">{{ message }}</p>
      <template #actions>
        <CloseButton @click="close" />
      </template>
    </Modal>
  </ModalTransition>
  <ModalMaskTransition>
    <ModalMask v-if="show" class="z-3" />
  </ModalMaskTransition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useAlertItems } from '~/plugins/alert'

export default defineComponent({
  name: 'Alert',
  setup() {
    const { show, message } = useAlertItems()
    const close = () => (show.value = false)
    return { show, message, close }
  },
})
</script>
