<template>
  <Modal>
    <template #title>{{ model.id ? 'Edit User' : 'Add User' }}</template>
    <Textfield
      v-model="model.name"
      @keydown="errors.name = ''"
      id="name"
      :error="errors.name"
      :disabled="!$can('edit', 'users')"
      >Name</Textfield
    >
    <Textfield
      v-model="model.email"
      @keydown="errors.email = ''"
      id="email"
      :error="errors.email"
      :disabled="!$can('edit', 'users')"
      >Email</Textfield
    >
    <Button
      v-if="model.id && !showPasswordFields"
      @click="showPasswordFields = true"
      :icon="$icons.LOCK"
      class="mb-4"
      >Change Password</Button
    >
    <template v-if="!model.id || showPasswordFields">
      <Textfield
        v-model="model.password"
        @keydown="errors.password = ''"
        id="password"
        :error="errors.password"
        type="password"
        >Password</Textfield
      >
      <Textfield
        v-model="model.password_confirmation"
        @keydown="errors.password_confirmation = ''"
        id="password_confirmation"
        :error="errors.password_confirmation"
        type="password"
        >Confirm Password</Textfield
      >
    </template>
    <SelectMenu
      v-if="authUser && model.id != authUser.id"
      v-model="model.role"
      :options="['admin', 'employee']"
      :error="errors.role"
      :subtitle="model.name ? model.name : 'New User'"
      :disabled="!$can('edit', 'users')"
      >Role</SelectMenu
    >
    <template #actions>
      <CloseButton @click="$modals.close()" />
      <DeleteButton v-if="showDelete" @click="deleteModel()" />
      <SaveButton @click="save()" />
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { AxiosError } from 'axios'
import { PropType, computed, ref } from 'vue'
import Button from '~/components/Button.vue'
import CloseButton from '~/components/CloseButton.vue'
import DeleteButton from '~/components/DeleteButton.vue'
import Modal from '~/components/Modal.vue'
import SaveButton from '~/components/SaveButton.vue'
import SelectMenu from '~/components/SelectMenu.vue'
import Textfield from '~/components/Textfield.vue'
import useCloseIfOffline from '~/composables/useCloseIfOffline'
import { useModelEdits } from '~/database'
import User from '~/database/models/User'
import { useCan, useUser } from '~/plugins/auth'

const props = defineProps({
  user: {
    type: Object as PropType<Partial<User>>,
    default: () => ({}),
  },
  resolve: {
    type: Function as PropType<Function>,
    required: true,
  },
  axiosError: {
    type: [Object, undefined] as PropType<AxiosError | undefined>,
    default: () => undefined,
  },
  bypassQueue: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

useCloseIfOffline()

const { model, errors, deleteModel, save } = useModelEdits<User>(
  props.user,
  User,
  ['name', 'email', 'role', 'password', 'password_confirmation'],
  props.resolve,
  {
    axiosError: props.axiosError,
    bypassQueue: props.bypassQueue,
    deleteMessage:
      'Delete this user along with all the notes, photos, files, and flags they have created?',
  },
)

const showPasswordFields = ref(!props.user)
const can = useCan()
const authUser = useUser()

const showDelete = computed(() => {
  return (
    model.value.id &&
    can('edit', 'users') &&
    authUser.value?.id != model.value.id
  )
})
</script>

<style scoped></style>
