<template>
  <div class="shadow">
    <div class="flex flex-row bg-gray-300">
      <slot />
      <div class="flex-grow border-b border-tertiary" />
    </div>
    <div
      v-if="$slots.content"
      class="border-l border-r border-b border-tertiary"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, provide, ref } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: [String, Number, Boolean] as PropType<string>,
      default: '',
    },
    colored: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    small: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props, { emit }) {
    const tabBarModelValue = computed(() => props.modelValue)
    provide('tabBarModelValue', tabBarModelValue)
    provide('colored', props.colored)
    provide('disabled', props.disabled)
    provide('small', props.small)
    provide('tabBarEmit', emit)
  },
})
</script>

<style scoped></style>
