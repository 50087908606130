import { z } from 'zod'
import Model from '../Model'

export default class Category extends Model {
  static readonly tableName: string = 'categories'
  static readonly apiEndPoint: string = 'api/categories'
  static readonly sortKey: string = 'name'

  active: boolean = true
  name: string = ''
  status_list: string[] = []

  constructor(payload: Partial<Category> = {}) {
    super(payload)
    Object.assign(this, payload)
  }
}
