<template>
  <div
    @mouseenter="hover = true"
    @mouseleave="hover = false"
    class="w-full cursor-pointer hover:bg-gray-100 px-2 py-1 border-t border-gray-300"
    :class="{ 'transition-all duration-500': transition }"
  >
    <slot :hover="hover" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'ListItem',
  props: {
    transition: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const hover = ref(false)
    return { hover }
  },
})
</script>

<style scoped></style>
