import {
  addDays,
  addHours,
  addMinutes,
  addMonths,
  addWeeks,
  addYears,
  differenceInCalendarDays,
  differenceInMinutes,
  differenceInSeconds,
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  formatISO,
  formatRelative,
  getDate,
  getDay,
  getHours,
  getMinutes,
  getMonth,
  getYear,
  intervalToDuration,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isSameYear,
  isWithinInterval,
  parseISO,
  set,
  setDay,
  setMonth,
  setYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns'

interface DateRange {
  start: Date | string
  end: Date | string
}

const parse = (date: Date | string) => {
  return typeof date == 'string' ? parseISO(date) : date
}

export default {
  addDays: (date: Date | string, qty: number) => addDays(parse(date), qty),
  addHours: (date: Date | string, qty: number) => addHours(parse(date), qty),
  addMinutes: (date: Date | string, qty: number) =>
    addMinutes(parse(date), qty),
  addMonths: (date: Date | string, qty: number) => addMonths(parse(date), qty),
  addWeeks: (date: Date | string, qty: number) => addWeeks(parse(date), qty),
  addYears: (date: Date | string, qty: number) => addYears(parse(date), qty),
  differenceInCalendarDays: (end: Date | string, start: Date | string) =>
    differenceInCalendarDays(parse(end), parse(start)),
  differenceInMinutes: (end: Date | string, start: Date | string) =>
    differenceInMinutes(parse(end), parse(start)),
  differenceInSeconds: (end: Date | string, start: Date | string) =>
    differenceInSeconds(parse(end), parse(start)),
  eachDayOfInterval: (range: DateRange) =>
    eachDayOfInterval({ start: parse(range.start), end: parse(range.end) }),
  eachWeekOfInterval: (range: DateRange) =>
    eachWeekOfInterval({ start: parse(range.start), end: parse(range.end) }),
  endOfDay: (date: Date | string) => endOfDay(parse(date)),
  endOfMonth: (date: Date | string) => endOfMonth(parse(date)),
  endOfWeek: (date: Date | string) => endOfWeek(parse(date)),
  format: (date: Date | string, string: string) => format(parse(date), string),
  formatDate: (date: Date | string, short = false) =>
    format(parse(date), short ? 'M/d' : 'P'),
  formatDuration: (duration: Duration, includeSeconds = false) => {
    if (!duration) return ''
    const hours = (duration.days || 0) * 24 + (duration.hours || 0)
    const minutes = includeSeconds
      ? duration.minutes
      : Math.round((duration.minutes || 0) + (duration.seconds || 0) / 60)
    const seconds = includeSeconds ? duration.seconds : null
    let string = `${hours < 10 ? '0' : ''}${hours}:${
      (minutes || 0) < 10 ? '0' : ''
    }${minutes}`
    if (includeSeconds) string += `:${(seconds || 0) < 10 ? '0' : ''}${seconds}`
    return string
  },
  formatISO: (
    date: Date | string,
    representation: 'date' | 'time' | 'complete' = 'complete',
  ) => formatISO(parse(date), { representation }),
  formatRelativeToNow: (date: Date | string) =>
    formatRelative(parse(date), new Date()),
  formatTime: (time: Date | string) => format(parse(time), 'hh:mm b'),
  getDate: (date: Date | string) => getDate(parse(date)),
  getDateObject: (dateTime: Date | string) => {
    const year = getYear(parse(dateTime))
    const month = getMonth(parse(dateTime))
    const date = getDate(parse(dateTime))
    return { year, month, date }
  },
  getDay: (date: Date | string) => getDay(parse(date)),
  getMonth: (date: Date | string) => getMonth(parse(date)),
  getTimeObject: (dateTime: Date | string) => {
    const hours = getHours(parse(dateTime))
    const minutes = getMinutes(parse(dateTime))
    return { hours, minutes }
  },
  getYear: (date: Date | string) => getYear(parse(date)),
  intervalToDuration: ({ start, end }: DateRange) =>
    intervalToDuration({ start: parse(start), end: parse(end) }),
  isAfter: (date: Date | string, dateToCompare: Date | string) =>
    isAfter(parse(date), parse(dateToCompare)),
  isBefore: (date: Date | string, dateToCompare: Date | string) =>
    isBefore(parse(date), parse(dateToCompare)),
  isWithinInterval: (date: Date | string, range: DateRange) => {
    return isWithinInterval(parse(date), {
      start: parse(range.start),
      end: parse(range.end),
    })
  },
  isSameDay: (date1: Date | string, date2: Date | string) =>
    isSameDay(parse(date1), parse(date2)),
  isSameMonth: (date1: Date | string, date2: Date | string) =>
    isSameMonth(parse(date1), parse(date2)),
  isSameOrAfter: (date1: Date | string, date2: Date | string) => {
    const first = parse(date1)
    const second = parse(date2)
    return isSameDay(first, second) || isAfter(first, second)
  },
  isSameYear: (date1: Date | string, date2: Date | string) =>
    isSameYear(parse(date1), parse(date2)),
  parse,
  rangeIsInInterval: (range: DateRange, interval: DateRange) => {
    return (
      isBefore(parse(range.start), parse(interval.end)) &&
      isAfter(parse(range.end), parse(interval.start))
    )
  },
  set: (
    date: Date | string,
    values: {
      year?: number | undefined
      month?: number | undefined
      date?: number | undefined
      hours?: number | undefined
      minutes?: number | undefined
      seconds?: number | undefined
      milliseconds?: number | undefined
    },
  ) => set(parse(date), values),
  setDay: (date: Date | string, day: number) => setDay(parse(date), day),
  setMonth: (date: Date | string, month: number) =>
    setMonth(parse(date), month),
  setYear: (date: Date | string, year: number) => setYear(parse(date), year),
  startOfDay: (date: Date | string) => startOfDay(parse(date)),
  startOfMonth: (date: Date | string) => startOfMonth(parse(date)),
  startOfWeek: (date: Date | string) => startOfWeek(parse(date)),
}
