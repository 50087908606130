<template>
  <div class="relative">
    <input
      @input="update($event)"
      @focus="handleFocus($event)"
      @blur="handleBlur()"
      :value="modelValue"
      id="autocomplete-input"
      ref="input"
      class="w-full pl-4 pr-4 pt-6 pb-2 rounded-t bg-gray-200 border-b-2 focus:border-secondary"
      :class="{
        'hover:bg-gray-300 border-black': !disabled,
        'text-gray-400 border-gray-400': disabled,
      }"
      :disabled="disabled"
      type="text"
    />
    <label
      class="absolute top-0 left-0 transform pointer-events-none text-lg focus:border-secondary transition-transform duration-200 ease-in-out"
      :class="{
        'text-gray-800': !disabled,
        'text-gray-400': disabled,
      }"
      :style="labelStyle"
      for="autocomplete-input"
    >
      <slot />
    </label>
    <p class="text-xs text-gray-700 h-5 sca" :class="{ 'text-red-600': error }">
      {{ error ? error : helper }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, PropType, ref, Ref } from 'vue'
import { useProvidedGoogleMapsData } from '~/composables/useGoogleMaps'

const props = defineProps({
  modelValue: {
    type: String as PropType<string>,
    default: '',
  },
  error: {
    type: String as PropType<string>,
    default: '',
  },
  helper: {
    type: String as PropType<string>,
    default: '',
  },
  disabled: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})
const emits = defineEmits([
  'blur',
  'update:model-value',
  'update:address',
  'set-all',
])

const { api, map } = useProvidedGoogleMapsData()

if (!api) console.error('Missing Google at Autocomplete')
if (!map) console.error('Missing Map at Autocomplete')

const autocomplete: Ref<undefined | any> = ref(undefined)
const input: Ref<HTMLInputElement | null> = ref(null)
const focused = ref(false)
function handleFocus(event: FocusEvent) {
  ;(<HTMLInputElement>event.target)?.select()
  focused.value = true
}
function handleBlur() {
  focused.value = false
  emits('blur')
}

const labelStyle: any = computed(() => {
  return {
    '--tw-translate-x': focused.value || props.modelValue ? 0 : '1rem',
    '--tw-translate-y': focused.value || props.modelValue ? 0 : '1rem',
    '--tw-scale-x': focused.value || props.modelValue ? 0.65 : 1,
    '--tw-scale-y': focused.value || props.modelValue ? 0.65 : 1,
  }
})

onMounted(() => {
  if (!api || !map) return console.error('Missing Google at Autocomplete')
  if (!input.value) return console.error('Missing autocomplete input')
  const options = {
    types: ['geocode'],
    componentRestrictions: { country: 'us' },
  }
  autocomplete.value = new api.maps.places.Autocomplete(input.value, options)
  if (!autocomplete?.value) {
    return console.error('Autocomplete failed to initiate')
  }
  autocomplete.value.setFields(['address_components', 'geometry'])
  autocomplete.value.addListener('place_changed', () => {
    const place = autocomplete.value?.getPlace()
    if (!place) return console.warn('Failed to get place')
    const components = place.address_components
    const keys = [
      'street_number',
      'route',
      'administrative_area_level_3',
      'administrative_area_level_1',
    ]
    const address = keys.reduce((address, key) => {
      const field = components?.find((comp: any) =>
        comp.types.some((type: any) => {
          if (key == 'administrative_area_level_3' && type == 'locality')
            return true
          return type == key
        }),
      )
      if (field) {
        address += field.short_name
        if (key == 'street_number') address += ' '
        else if (key != 'administrative_area_level_1') address += ', '
      }
      return address
    }, '')
    if (!address || !place?.geometry) return
    if (place.geometry?.viewport) {
      map.fitBounds(place.geometry.viewport)
    }
    emits('set-all', {
      address,
      geometry: place.geometry,
    })
  })
})

function update(event: Event) {
  emits('update:model-value', (<HTMLInputElement>event.target).value)
}
</script>

<style scoped>
input:focus {
  outline: none;
}
</style>
