import Model from '../Model'
import CustomField from './CustomField'

export default class Item extends Model {
  static readonly tableName: string = 'items'
  static readonly apiEndPoint: string = 'api/items'
  static readonly sortKey: string = 'name'

  name: string = ''
  description: string = ''
  units: string = ''
  active: boolean = true
  custom_fields?: CustomField[]
  boolean: boolean = false

  constructor(payload: Partial<Item> = {}) {
    super(payload)
    Object.assign(this, payload)
  }
}
