<template>
  <Modal :job="job">
    <template #title>{{ model.id ? 'Edit Note' : 'Add Note' }}</template>
    <Textarea
      v-model="model.note"
      @keydown="errors.note = ''"
      id="note"
      :error="errors.note"
      has-focus
      >Note</Textarea
    >
    <template #actions>
      <CloseButton @click="$modals.close()" />
      <DeleteButton v-if="showDelete" @click="deleteModel()" />
      <SaveButton @click="save()" />
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { AxiosError } from 'axios'
import { PropType } from 'vue'
import CloseButton from '~/components/CloseButton.vue'
import DeleteButton from '~/components/DeleteButton.vue'
import Modal from '~/components/Modal.vue'
import SaveButton from '~/components/SaveButton.vue'
import Textarea from '~/components/Textarea.vue'
import { useModelEdits } from '~/database'
import Job from '~/database/models/Job'
import Note from '~/database/models/Note'

const props = defineProps({
  note: {
    type: Object as PropType<Partial<Note>>,
    default: () => ({}),
  },
  resolve: {
    type: Function as PropType<Function>,
    required: true,
  },
  axiosError: {
    type: [Object, undefined] as PropType<AxiosError | undefined>,
    default: () => undefined,
  },
  bypassQueue: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  notable_id: {
    type: [Number, String] as PropType<string>,
    default: '',
  },
  notable_type: {
    type: String as PropType<Note['notable_type']>,
    default: '',
  },
  job: {
    type: [Object, undefined] as PropType<Job | undefined>,
    default: () => undefined,
  },
})

const { model, errors, showDelete, deleteModel, save } = useModelEdits<Note>(
  props.note,
  Note,
  ['note'],
  props.resolve,
  {
    axiosError: props.axiosError,
    bypassQueue: props.bypassQueue,
    deleteMessage: 'Delete this note?',
  },
)

if (props.notable_type) {
  model.value.notable_type = props.notable_type
}
if (props.notable_id) {
  model.value.notable_id = props.notable_id
}
</script>

<style scoped></style>
