//TODO Improve Types

export default {
  session: {
    setItem: (key: string, payload: any) => {
      if (!payload) return sessionStorage.removeItem(key)
      if (typeof payload == 'object')
        return sessionStorage.setItem(key, JSON.stringify(payload))
      sessionStorage.setItem(key, payload)
    },
    getItem: (key: string) => {
      if (!sessionStorage.getItem(key)) return undefined
      try {
        const itemString = sessionStorage.getItem(key)
        if (!itemString) return undefined
        try {
          const item = JSON.parse(itemString)
          return item
        } catch (error) {
          if (typeof itemString == 'string') return itemString
          else sessionStorage.removeItem(key)
        }
      } catch (error) {
        sessionStorage.removeItem(key)
      }
      return undefined
    },
  },
  local: {
    setItem: (key: string, payload: any) => {
      if (!payload) return localStorage.removeItem(key)
      if (typeof payload == 'object')
        return localStorage.setItem(key, JSON.stringify(payload))
      localStorage.setItem(key, payload)
    },
    getItem: (key: string) => {
      if (!localStorage.getItem(key)) return undefined
      try {
        const itemString = localStorage.getItem(key)
        if (!itemString) return undefined
        try {
          const item = JSON.parse(itemString)
          return item
        } catch (error) {
          if (typeof itemString == 'string') return itemString
          else localStorage.removeItem(key)
        }
      } catch (error) {
        localStorage.removeItem(key)
      }
      return undefined
    },
  },
}
