//this is a global store for resources that will be used throughout the app that we don't want
//to have to load over and over again
//This will include resources like users, customFields, etc

import { useModel } from '~/database'
import User from '~/database/models/User'
import Settings from './Settings'
import Category from '~/database/models/Category'
import CustomField from '~/database/models/CustomField'
import Customer from '~/database/models/Customer'
import Equipment from '~/database/models/Equipment'
import HoursType from '~/database/models/HoursType'
import Item from '~/database/models/Item'
import Status from '~/database/models/Status'
import { useDatabase } from '~/plugins/database'

export default class Store {
  categories = useModel<Category>(Category, [], {
    standalone: true,
  })
  customFields = useModel<CustomField>(CustomField, [], {
    standalone: true,
  })
  customers = useModel<Customer>(Customer, [], {
    standalone: true,
  })
  equipment = useModel<Equipment>(Equipment, [], {
    standalone: true,
  })
  hoursTypes = useModel<HoursType>(HoursType, [], {
    standalone: true,
  })
  items = useModel<Item>(Item, [], {
    standalone: true,
  })
  statuses = useModel<Status>(Status, [], {
    standalone: true,
  })
  users = useModel<User>(User, [], {
    standalone: true,
  })

  settings = new Settings()

  async install() {
    //should happen after database is initialized (this populates indexedDB with latest and greatest)
    const database = useDatabase()
    database.onInitialized(() => {
      Promise.all([
        this.categories.fetchLocalData(),
        this.customFields.fetchLocalData(),
        this.customers.fetchLocalData(),
        this.equipment.fetchLocalData(),
        this.hoursTypes.fetchLocalData(),
        this.items.fetchLocalData(),
        this.statuses.fetchLocalData(),
        this.users.fetchLocalData(),
        this.settings.fetchSettings(),
      ])
    }, true)
  }
}
