<template>
  <button
    @click="changeStatus()"
    class="flex flex-row items-center justify-center w-40 py-1 text-base font-bold rounded-lg shadow sm:text-lg md:text-xl md:py-2 md:w-56"
    :class="{
      'cursor-default': disabled,
    }"
    :style="buttonStyle"
    :disabled="disabled"
  >
    {{ selectedStatus ? selectedStatus.name : 'Select Status' }}
  </button>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'
import getContrastColor from '~/assets/constants/getContractsColor'
import Job from '~/database/models/Job'
import SelectStatusModal from '~/modals/SelectStatusModal.vue'
import { useModals } from '~/plugins/modals'
import { useStore } from '~/plugins/store'

const props = defineProps({
  //status_id
  modelValue: {
    type: [Number, String] as PropType<string | number>,
    default: '',
  },
  job: {
    type: [Object, undefined] as PropType<Job | undefined>,
    default: () => undefined,
  },
  category_id: {
    type: [Number, String] as PropType<string | number>,
    default: '',
  },
  readOnly: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  disabled: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  jobActiveOnly: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  all: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

//display
const store = useStore()
const selectedStatus = computed(() => {
  return store.statuses.record.value[props.modelValue]
})
const buttonStyle = computed(() => {
  if (!selectedStatus.value) return
  return {
    'background-color': selectedStatus.value.color,
    color: getContrastColor(selectedStatus.value.color),
  }
})

//change status
const emits = defineEmits(['update:model-value'])
const modals = useModals()
async function changeStatus() {
  if (props.readOnly) return
  const newStatusId = await modals.show(SelectStatusModal, {
    job: props.job,
    category_id: props.category_id,
    all: props.all,
    jobActiveOnly: props.jobActiveOnly,
  })
  if (!newStatusId) return
  if (newStatusId == 'all') return emits('update:model-value', '')
  emits('update:model-value', newStatusId)
}
</script>

<style>
button:focus {
  outline: none;
}
</style>
