<template>
  <Modal width-class="max-w-5xl">
    <template #title>Sync Errors</template>
    <Table>
      <TableRow v-for="error in errors">
        <TableCell>
          {{ error.modelName }}
        </TableCell>
        <TableCell>
          {{ getItemDescription(error) }}
        </TableCell>
        <TableCell>
          <div class="flex flex-row items-center justify-end">
            <IconButton
              @click="viewError(error)"
              :icon="$icons.ERRORS"
              class="text-red-600"
              small
            />
            <IconButton
              v-if="!Array.isArray(error.item)"
              @click="openRequestModal(error)"
              :icon="$icons.OPEN"
              small
            />
            <IconButton
              @click="removeError(error)"
              :icon="$icons.CLOSE"
              class="text-red-600"
              small
            />
          </div>
        </TableCell>
      </TableRow>
    </Table>
    <template #actions>
      <CloseButton @click="closeModal()" />
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { ref, Ref } from 'vue'
import { useModals } from '~/plugins/modals'
import { useDatabase } from '~/plugins/database'
import { PendingRequestError } from '~/database/plugins/pendingRequests'
import Model from '~/database/Model'
import { useAlert } from '~/plugins/alert'
import Modal from '~/components/Modal.vue'
import Table from '~/components/Table.vue'
import TableRow from '~/components/TableRow.vue'
import TableCell from '~/components/TableCell.vue'
import IconButton from '~/components/IconButton.vue'
import CloseButton from '~/components/CloseButton.vue'
import CategoryModal from './CategoryModal.vue'
import FlagModal from './FlagModal.vue'
import JobEquipmentHourModal from './JobEquipmentHourModal.vue'
import JobItemModal from './JobItemModal.vue'
import JobLaborHour from '~/database/models/JobLaborHour'
import JobLaborHourModal from './JobLaborHourModal.vue'
import NoteModal from './NoteModal.vue'
import TaskModal from './TaskModal.vue'
import ImageModal from './ImageModal.vue'

const props = defineProps({
  resolve: {
    type: Function,
    default: () => null,
  },
})
const modals = useModals()

const database = useDatabase()

const errors: Ref<PendingRequestError<Model>[]> = ref([])

const fetchErrors = async () => {
  const theErrors = await database.pendingRequests.getPendingRequestErrors()
  if (!theErrors?.length) return
  errors.value = theErrors
}
fetchErrors()

function getItemDescription(error: PendingRequestError<Model>) {
  const item = error.item
  if (!item) return
  if (Array.isArray(item)) return 'Save Multiple'
  if ('name' in item) return item.name
  if ('body' in item) return item.body
  if ('description' in item) return item.description
  return item.id
}

const alert = useAlert()
function viewError(error: PendingRequestError<Model>) {
  alert(error.error.message)
}

function lowerCaseFirstLetter(string: string) {
  return string.charAt(0).toLowerCase() + string.slice(1)
}

const allModals = {
  Flag: FlagModal,
  Image: ImageModal,
  JobEquipmentHour: JobEquipmentHourModal,
  JobItem: JobItemModal,
  JobLaborHour: JobLaborHourModal,
  Note: NoteModal,
  Task: TaskModal,
}

async function openRequestModal(requestError: PendingRequestError<Model>) {
  if (requestError.key.includes('store-many')) {
    return
  }
  const modal = allModals[requestError.modelName as keyof typeof allModals]
  if (!modal) return
  const result = <Model | null | void>await modals.show(modal, {
    [lowerCaseFirstLetter(requestError.modelName)]: requestError.item,
    bypassQueue: true,
    axiosError: requestError.error,
  })
  if (!result || !result?.id) return
  removeError(requestError)
}

function removeError(error: PendingRequestError<Model>) {
  errors.value = errors.value.filter((e) => e.key != error.key)
  database.pendingRequests.removePendingRequestError(error.key)
}

function closeModal() {
  props.resolve(errors.value)
  modals.close()
}
</script>

<style scoped></style>
