<template>
  <Modal>
    <template v-if="subtitle" #subtitle>{{ subtitle }}</template>
    <template #title>{{ title }}</template>
    <div class="overflow-y-auto" style="max-height: 50vh">
      <MenuButton
        v-for="option in options"
        @click="select(option)"
        :key="typeof option == 'string' ? option : option[valueKey]"
        :selected="isSelected(option)"
        class="my-1"
        style="min-height: 2rem"
        >{{ typeof option == 'string' ? option : option[labelKey] }}</MenuButton
      >
    </div>
    <template #actions>
      <CloseButton @click="modals.close()" />
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { PropType } from 'vue'
import CloseButton from '~/components/CloseButton.vue'
import MenuButton from '~/components/MenuButton.vue'
import Modal from '~/components/Modal.vue'
import { useModals } from '~/plugins/modals'

interface OptionObject {
  [key: string]: string | number
}

const props = defineProps({
  subtitle: {
    type: String as PropType<string>,
    default: '',
  },
  title: {
    type: String as PropType<string>,
    default: '',
  },
  valueKey: {
    type: String as PropType<string>,
    default: '',
  },
  labelKey: {
    type: String as PropType<string>,
    default: '',
  },
  selected: {
    type: [String, Number] as PropType<string | number>,
    default: '',
  },
  options: {
    type: Array as PropType<(string | { [key: string]: string | number })[]>,
    default: () => [],
  },
  resolve: {
    type: Function,
    default: () => null,
  },
})

const modals = useModals()
function isSelected(option: OptionObject | string) {
  if (!props.selected) return false
  if (typeof option == 'string') {
    return option == props.selected
  }
  return option[props.valueKey] == props.selected
}
function select(option: OptionObject | string) {
  if (typeof option == 'string') props.resolve(option)
  else props.resolve(option[props.valueKey])
  modals.close()
}
</script>

<style scoped></style>
