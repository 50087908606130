<template>
  <button
    @click="tabBarEmit('update:modelValue', value)"
    class="font-semibold focus:outline-none w-auto truncate flex-shrink"
    :class="{
      'py-2      px-4      text-sm      sm:text-base      md:text-lg': !small,
      'py-1 px-2 text-sm md:text-base': small,
      'bg-white text-black bg-opacity-100 border-l border-t border-r border-tertiary':
        value == tabBarModelValue && !colored && !disabled,
      'bg-secondary text-on-secondary bg-opacity-100':
        value == tabBarModelValue && colored && !disabled,
      'bg-secondary bg-opacity-0 hover:bg-opacity-10 border-b border-tertiary':
        value != tabBarModelValue && !disabled,
      'bg-secondary bg-opacity-50 text-gray':
        tabBarModelValue == value && disabled,
      'bg-gray-200 text-gray': tabBarModelValue != value && disabled,
      'cursor-default': disabled,
    }"
    :disabled="disabled"
  >
    <slot />
  </button>
</template>

<script lang="ts">
import { defineComponent, inject, PropType } from 'vue'

//* Works as Child of TabBar.vue only

export default defineComponent({
  props: {
    value: {
      type: [Number, String, Boolean] as PropType<string>,
      required: true,
    },
  },
  setup() {
    const tabBarModelValue = inject('tabBarModelValue', '')
    const disabled = inject('disabled', false)
    const colored = inject('colored', false)
    const small = inject('small', false)
    const tabBarEmit = inject(
      'tabBarEmit',
      (emitString: string, emitValue: string) => null,
    )
    return {
      tabBarModelValue,
      disabled,
      colored,
      small,
      tabBarEmit,
    }
  },
})
</script>

<style scoped></style>
