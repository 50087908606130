// Convincing TypeScript that $axios is a global variable on window
import { AxiosStatic } from 'axios'
import { openDB } from 'idb'
import { dbName, dbVersion } from './constants'
import models from './models'
import useModel from './composables/useModel'
import useAModel from './composables/useAModel'
import useModelEdits from './composables/useModelEdits'
import useModelPaginated from './composables/useModelPaginated'
import {
  PENDING_REQUESTS,
  PENDING_REQUEST_ERRORS,
  pendingRequests,
} from './plugins/pendingRequests'
import { connection } from './plugins/connection'
import installEcho from './plugins/echo'
import Dates from '~/assets/constants/Dates'
import { useUser } from '~/plugins/auth'

declare global {
  interface Window {
    $axios: AxiosStatic
  }
}

export class Database {
  initialized: boolean = false
  initializedObservers: Function[] = []
  pendingRequests = pendingRequests
  connection = connection

  constructor() {
    if (!('indexedDB' in window)) {
      console.warn("This browser doesn't support IndexedDB")
      return
    }
  }

  get offline() {
    return this.connection.offline.value
  }

  public onInitialized(callback: Function, runIfAlreadyInitialized = false) {
    if (this.initialized && runIfAlreadyInitialized) callback()
    this.initializedObservers.push(callback)
  }

  public deleteDatabase() {
    return window.indexedDB.deleteDatabase(dbName)
  }

  public async initialize(reset = false) {
    if (reset) {
      this.initialized = false
    }
    if (this.initialized) return
    const database = await openDB(dbName, dbVersion, {
      upgrade(database, oldVersion, newVersion, transaction) {
        if (database.objectStoreNames.contains(PENDING_REQUESTS)) {
          database.deleteObjectStore(PENDING_REQUESTS)
        }
        if (database.objectStoreNames.contains(PENDING_REQUEST_ERRORS)) {
          database.deleteObjectStore(PENDING_REQUEST_ERRORS)
        }
        if (database.objectStoreNames.contains('table-names')) {
          database.deleteObjectStore('table-names')
        }
        const tablesStore = database.createObjectStore('table-names', {
          keyPath: 'name',
        })
        const pendingRequestsStore = database.createObjectStore(
          PENDING_REQUESTS,
          {
            keyPath: 'key',
          },
        )
        const pendingRequestErrorsStore = database.createObjectStore(
          PENDING_REQUEST_ERRORS,
          {
            keyPath: 'key',
          },
        )
        models.forEach((model) => {
          tablesStore.add({
            name: model.tableName,
            accessed_at: '',
            initialized_at: '',
          })
          if (database.objectStoreNames.contains(model.tableName)) {
            database.deleteObjectStore(model.tableName)
          }
          const objectStore = database.createObjectStore(model.tableName, {
            keyPath: model.primaryKey,
          })
          if (model.foreignKeys.length) {
            model.foreignKeys.forEach((key) => {
              objectStore.createIndex(key, key, { unique: false })
            })
          }
          if (
            ![model.primaryKey, ...model.foreignKeys].includes(model.sortKey)
          ) {
            objectStore.createIndex(model.sortKey, model.sortKey, {
              unique: false,
            })
          }
        })
      },
    })
    const authUser = useUser()
    if (!authUser.value?.id) return
    if (!this.offline) {
      const db = await openDB(dbName, dbVersion)
      const info = await db.get('table-names', 'users')
      let payload: Record<string, any> = {}
      //reset once a month
      const resetData =
        reset ||
        !info?.initialized_at ||
        !Dates.isSameMonth(info.initialized_at, new Date())
      if (!resetData && info.accessed_at) {
        payload.timestamp = info.accessed_at
      }
      const timestamp = new Date().toISOString()
      const params = new URLSearchParams(payload)
      const response = <
        | undefined
        | {
            data: Record<string, any>
          }
      >await window.$axios.get(`/api/data?${params.toString()}`)
      if (!response?.data) return
      const transaction = db.transaction(
        Object.keys(response.data),
        'readwrite',
      )
      Object.keys(response.data).forEach((key) => {
        const store = transaction.objectStore(key)
        if (!store) return
        if (!response?.data[key]?.length) return
        if (resetData) store.clear()
        response.data[key].forEach((item: any) => {
          if (item.deleted_at) store.delete(item.id)
          else store.put(item)
        })
        database.put('table-names', {
          name: 'users',
          initialized_at: resetData ? timestamp : info.initialized_at,
          accessed_at: timestamp,
        } as any)
        return transaction.done
      })
    }
    await pendingRequests.init()
    this.initialized = true
    this.initializedObservers.forEach((observer) => observer())
  }
}

export { useModel, useAModel, useModelEdits, useModelPaginated }

installEcho()
