import { z } from 'zod'
import { Geotag } from './Geotag'

export class Address {
  static ZodValidator = z.object({
    geotag: Geotag.ZodValidator.nullable(),
    address: z
      .string({
        required_error: 'The address is required.',
      })
      .min(1, 'The address is required.')
      .max(255),
  })

  geotag?: Geotag | null = null
  address: string = ''

  constructor(payload: Partial<Address> = {}) {
    Object.assign(this, payload)
  }
}
