import Model from '../Model'

export default class CustomField extends Model {
  static readonly tableName: string = 'customFields'
  static readonly apiEndPoint: string = 'api/custom_fields'
  static readonly sortKey: string = 'name'

  name: string = ''
  description: string = ''
  type: 'boolean' | 'string' | 'number' | 'select' = 'string'
  fieldable_type:
    | 'App\\Models\\Job'
    | 'App\\Models\\Item'
    | 'App\\Models\\Equipment' = 'App\\Models\\Job'
  fieldable_id: string = ''
  options: null | string[] = null
  active: boolean = true
  show_on_dashboard: boolean = false
  do_not_duplicate: boolean = false

  constructor(payload: Partial<CustomField> = {}) {
    super(payload)
    Object.assign(this, payload)
  }
}
