import { ComputedRef, Ref, computed, watch } from 'vue'
import Model from '../Model'
import models from '../models'
import useModel from './useModel'

export default function useAModel<T extends Model>(
  ModelClass: (typeof models)[number],
  id: string | number | Ref<string | number>,
  initialItem?: T | undefined | 'fetch',
): ComputedRef<T | undefined> {
  const { record } = useModel<T>(
    ModelClass,
    initialItem && initialItem != 'fetch' ? [initialItem] : [],
  )
  const theId = computed(() => {
    return typeof id === 'string' || typeof id === 'number' ? id : id.value
  })
  const computedItem = computed(() => {
    return (
      record.value[theId.value] ||
      (initialItem == 'fetch' ? undefined : initialItem)
    )
  })
  if (initialItem && initialItem == 'fetch' && theId.value) {
    ModelClass.fetchItemFromApi(theId.value, true, true)
    if (typeof id !== 'string' && typeof id !== 'number') {
      watch(id, () => {
        if (!theId.value) return
        ModelClass.fetchItemFromApi(theId.value, true, true)
      })
    }
  } else if (!computedItem.value && theId.value) {
    ModelClass.fetchItemFromIndexedDB(theId.value, true)
  }
  return computedItem
}
