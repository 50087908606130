<template>
  <div
    class="absolute h-full w-full px-2 md:px-8 pb-24"
    :class="{
      'overflow-y-auto': !overflow,
      'overflow-y-scroll': overflow,
    }"
  >
    <div v-if="$slots.title || $slots.subtitle" class="my-4">
      <h1>
        <slot name="title" />
      </h1>
      <div class="italic">
        <slot name="subtitle" />
      </div>
    </div>
    <div class="container" :class="containerClass">
      <slot />
    </div>
    <ActionBar
      @add="$emit('add')"
      @clock="$emit('clock')"
      :custom-clock="customClock"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Layout',
  props: {
    overflow: {
      type: Boolean,
      default: false,
    },
    containerClass: {
      type: String,
      default: '',
    },
    full: {
      type: Boolean,
      default: false,
    },
    customClock: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style scoped></style>
