<template>
  <Button>Close</Button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CloseButton',
})
</script>

<style scoped></style>
