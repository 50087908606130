import Model from '../Model'

export default class File extends Model {
  static readonly tableName: string = 'files'
  static readonly apiEndPoint: string = 'api/files'
  static readonly sortKey: string = 'name'

  user_id: string | number = ''
  filable_id: string | number = ''
  filable_type: 'App\\Models\\Equipment' | 'App\\Models\\Job' =
    'App\\Models\\Job'
  path: string | null = null
  url?: string
  description: string = ''

  constructor(payload: Partial<File> = {}) {
    super(payload)
    Object.assign(this, payload)
  }
}
