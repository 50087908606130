<template>
  <div
    class="flex"
    :class="{
      'flex-row flex-wrap justify-around': !vertical,
      'flex-col': vertical,
    }"
  >
    <div
      v-for="field in filteredBooleanFields"
      class="mx-1"
      :class="{
        'text-lg': !small && !extraSmall,
        'text-base': small,
        'text-sm': extraSmall,
      }"
    >
      {{ field.name }}
    </div>
    <ValuePair
      v-for="field in filteredNonBooleanFields"
      class="mx-1"
      :small="small"
      :extra-small="extraSmall"
    >
      <template #label>{{ field.name }}</template>
      <div @click.stop v-html="getCustomFieldHtml(field.id)" />
    </ValuePair>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'
import usePhoneNumbers from '~/composables/usePhoneNumbers'
import Job from '~/database/models/Job'
import { useStore } from '~/plugins/store'
import ValuePair from './ValuePair.vue'

const props = defineProps({
  job: {
    type: Object as PropType<Job>,
    required: true,
  },
  vertical: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  small: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  extraSmall: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  dashboardView: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const store = useStore()
function getJobHasField(custom_field_id: string) {
  return (props.job.custom_field_values || []).some((field) => {
    return !!field.value && field.custom_field_id == custom_field_id
  })
}
function getCustomFieldValue(custom_field_id: string) {
  const field = (props.job.custom_field_values || []).find((field) => {
    return !!field.value && field.custom_field_id == custom_field_id
  })
  if (!field?.value) return ''
  return field.value
}
const filteredNonBooleanFields = computed(() => {
  return store.customFields.set.value.filter((field) => {
    if (props.dashboardView && !field.show_on_dashboard) return false
    return field.type != 'boolean' && getJobHasField(field.id)
  })
})
const filteredBooleanFields = computed(() => {
  return store.customFields.set.value.filter((field) => {
    if (props.dashboardView && !field.show_on_dashboard) return false
    return field.type == 'boolean' && getJobHasField(field.id)
  })
})
const { clickToCall } = usePhoneNumbers()
function getCustomFieldHtml(custom_field_id: string) {
  const value = getCustomFieldValue(custom_field_id)
  if (value === true) return value
  return clickToCall(String(value))
}
</script>

<style scoped></style>
