const __pages_import_0__ = () => import("/src/pages/templates/index.vue");
const __pages_import_1__ = () => import("/src/pages/settings/statuses.vue");
const __pages_import_2__ = () => import("/src/pages/settings/job_fields.vue");
const __pages_import_3__ = () => import("/src/pages/settings/index.vue");
const __pages_import_4__ = () => import("/src/pages/settings/hours_types.vue");
const __pages_import_5__ = () => import("/src/pages/settings/estimated_qtys.vue");
const __pages_import_6__ = () => import("/src/pages/settings/equipment_fields.vue");
const __pages_import_7__ = () => import("/src/pages/settings/categories.vue");
const __pages_import_8__ = () => import("/src/pages/jobs/index.vue");
const __pages_import_9__ = () => import("/src/pages/equipment/index.vue");
const __pages_import_10__ = () => import("/src/pages/welcome.vue");
const __pages_import_11__ = () => import("/src/pages/users.vue");
const __pages_import_12__ = () => import("/src/pages/shopping_list.vue");
const __pages_import_13__ = () => import("/src/pages/items.vue");
import __pages_import_14__ from "/src/pages/index.vue";
const __pages_import_15__ = () => import("/src/pages/customers.vue");
const __pages_import_16__ = () => import("/src/pages/analysis.vue");
const __pages_import_17__ = () => import("/src/pages/equipment/[equipment_id].vue");
const __pages_import_18__ = () => import("/src/pages/jobs/[job_id].vue");
const __pages_import_19__ = () => import("/src/pages/templates/[template_id].vue");

const routes = [{"name":"templates","path":"/templates","component":__pages_import_0__,"props":true},{"name":"settings-statuses","path":"/settings/statuses","component":__pages_import_1__,"props":true},{"name":"settings-job_fields","path":"/settings/job_fields","component":__pages_import_2__,"props":true},{"name":"settings","path":"/settings","component":__pages_import_3__,"props":true},{"name":"settings-hours_types","path":"/settings/hours_types","component":__pages_import_4__,"props":true},{"name":"settings-estimated_qtys","path":"/settings/estimated_qtys","component":__pages_import_5__,"props":true},{"name":"settings-equipment_fields","path":"/settings/equipment_fields","component":__pages_import_6__,"props":true},{"name":"settings-categories","path":"/settings/categories","component":__pages_import_7__,"props":true},{"name":"jobs","path":"/jobs","component":__pages_import_8__,"props":true},{"name":"equipment","path":"/equipment","component":__pages_import_9__,"props":true},{"name":"welcome","path":"/welcome","component":__pages_import_10__,"props":true},{"name":"users","path":"/users","component":__pages_import_11__,"props":true},{"name":"shopping_list","path":"/shopping_list","component":__pages_import_12__,"props":true},{"name":"items","path":"/items","component":__pages_import_13__,"props":true},{"name":"index","path":"/","component":__pages_import_14__,"props":true},{"name":"customers","path":"/customers","component":__pages_import_15__,"props":true},{"name":"analysis","path":"/analysis","component":__pages_import_16__,"props":true},{"name":"equipment-equipment_id","path":"/equipment/:equipment_id","component":__pages_import_17__,"props":true},{"name":"jobs-job_id","path":"/jobs/:job_id","component":__pages_import_18__,"props":true},{"name":"templates-template_id","path":"/templates/:template_id","component":__pages_import_19__,"props":true}];

export default routes;