import Model from '../Model'
import models from '../models'
import { Ref, ref, onUnmounted, watch } from 'vue'
import debounce from 'just-debounce-it'
import { useRoute, useRouter } from 'vue-router'
import Storage from '~/assets/constants/Storage'
import useErrorHandling from '~/composables/useErrorHandling'
import dateRangePayloadTransform from '~/assets/constants/dateRangePayloadTransform'

export default function useModelPaginated<T extends Model>(
  model: (typeof models)[number],
  search: Ref<Record<string, any>>,
  offline: Ref<boolean> = ref(false),
  {
    immediate = false,
    storageKey = '',
    recordHistory = false,
  }: {
    immediate?: boolean
    storageKey?: string
    recordHistory?: boolean
  } = {},
) {
  const route = useRoute()
  const router = useRouter()
  const currentlyPaginatedPath = route.path

  const currentPage = ref(recordHistory ? Number(route.query['page']) || 1 : 1)
  const lastPage = ref(1)
  const loading = ref(false)

  if (storageKey) {
    const storedSearch = Storage.local.getItem(storageKey)
    if (storedSearch) {
      search.value = {
        ...search.value,
        ...storedSearch,
        per_page: search.value.per_page,
        search: '',
      }
    }
  }

  const searchResults: Ref<T[]> = ref([])

  const handleSearchInput = debounce(async function () {
    if (recordHistory) {
      router.replace({
        query: {
          ...route.query,
          search: String(search.value.search),
        },
      })
    }
    fetchData()
  }, 500)

  function sort(sortKey: T[any]) {
    if (sortKey == search.value.sort_column) {
      search.value.sort_direction =
        search.value.sort_direction == 'asc' ? 'desc' : 'asc'
    } else {
      search.value.sort_column = sortKey
    }
    fetchData()
  }

  function setSearch(payload: Record<string, any>) {
    search.value = {
      ...search.value,
      ...payload,
    }
    fetchData()
  }

  function routerPush(
    path: string,
    query: Record<string, string | number> = {},
  ) {
    router.push({ path, query })
  }

  const { handleLocalErrors, handleApiErrors } = useErrorHandling()

  function handleErrors(e: any) {
    if (offline.value) return handleLocalErrors(e)
    return handleApiErrors(e)
  }

  //Using Database do to all our filtering and sorting for speed
  let executeSearchNonce: Object | undefined
  const fetchData = debounce(
    async function (page = 1) {
      handleSearchInput.cancel()
      const nonce = (executeSearchNonce = new Object())
      if (recordHistory && Number(route.query['page']) != page) {
        router.replace({ query: { ...route.query, tab: storageKey, page } })
      }
      if (storageKey) {
        Storage.local.setItem(storageKey, search.value)
      }
      loading.value = true
      const method = offline.value
        ? 'fetchPaginatedDataFromIndexedDB'
        : 'fetchPaginatedDataFromApi'
      const payload =
        'start' in search.value
          ? dateRangePayloadTransform(search.value)
          : search.value
      const results = await model[method]<T>({
        ...payload,
        page,
      }).catch(handleErrors)
      if (executeSearchNonce !== nonce) return
      loading.value = false
      if (!results) return
      searchResults.value = results.data
      currentPage.value = results.current_page
      lastPage.value = results.last_page
    },
    100,
    false,
  )

  function nextPage() {
    if (recordHistory) {
      routerPush('', {
        ...route.query,
        page: currentPage.value + 1,
      })
    } else {
      fetchData(currentPage.value + 1)
    }
  }
  function previousPage() {
    if (recordHistory) {
      routerPush('', {
        ...route.query,
        page: currentPage.value - 1,
      })
    } else {
      fetchData(currentPage.value - 1)
    }
  }

  if (recordHistory) {
    if (route.query['search'] && search.value.search != route.query['search']) {
      search.value.search = String(route.query['search'])
    }
    watch(route, () => {
      if (currentlyPaginatedPath != route.path) return
      fetchData(Number(route.query['page']) || 1)
    })
  }

  async function init() {
    if (immediate || recordHistory) {
      fetchData(currentPage.value)
    }
  }
  init()

  function onDataUpdated() {
    fetchData()
  }

  model.onDataUpdated(onDataUpdated)

  onUnmounted(() => {
    model.removeOnDataUpdated(onDataUpdated)
  })

  return {
    currentPage,
    lastPage,
    loading,
    searchResults,
    handleSearchInput,
    fetchData,
    nextPage,
    previousPage,
    sort,
    setSearch,
  }
}
