<template>
  <GoogleMapsLoader :apiKey="apiKey" map-id="address-input-map">
    <GoogleAutocomplete
      :modelValue="modelValue.address"
      @update:modelValue="setAddress($event)"
      @set-all="setAll($event)"
      :error="error"
      >Address</GoogleAutocomplete
    >
    <GoogleMapMarker
      v-if="location && location.lat && location.lng"
      @update:modelValue="setLocation($event)"
      :modelValue="location"
    />
  </GoogleMapsLoader>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, ref, Ref } from 'vue'
import { Address } from '~/database/models/Address'

export default defineComponent({
  props: {
    modelValue: {
      type: Object as PropType<Address>,
      required: true,
    },
    apiKey: {
      type: String as PropType<string>,
      default: '',
    },
    error: {
      type: String as PropType<string>,
      default: '',
    },
  },
  setup(props, { emit }) {
    const location: Ref<{ lat: number; lng: number } | undefined> =
      ref(undefined)

    const setAddress = (address: string) => {
      emit('update:modelValue', {
        ...props.modelValue,
        address,
      })
    }

    interface Geometry {
      location: {
        lat: Function
        lng: Function
      }
    }
    const getLocationFromGeometry = (geometry: Geometry) => ({
      lat: geometry.location.lat(),
      lng: geometry.location.lng(),
    })

    const setAll = ({
      geometry,
      address,
    }: {
      geometry: Geometry
      address: string
    }) => {
      const loc = getLocationFromGeometry(geometry)
      emit('update:modelValue', {
        ...props.modelValue,
        address,
        geotag: {
          ...props.modelValue?.geotag,
          ...loc,
        },
      })
      location.value = loc
    }

    const setLocation = (loc: { lat: number; lng: number }) => {
      emit('update:modelValue', {
        ...props.modelValue,
        geotag: {
          ...props.modelValue?.geotag,
          ...loc,
        },
      })
      location.value = loc
    }

    onMounted(() => {
      if (props.modelValue?.geotag) {
        location.value = {
          lat: props.modelValue?.geotag.lat,
          lng: props.modelValue?.geotag.lng,
        }
      }
    })

    return { setAddress, setAll, location, setLocation }
  },
})
</script>
