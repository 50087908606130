import { computed } from 'vue'
import { useDatabase } from '~/plugins/database'

export default function useOffline() {
  const database = useDatabase()

  const offline = computed(() => {
    return database.connection.offline.value
  })

  return offline
}
