<template>
  <div class="relative">
    <Icon
      v-if="icon"
      class="absolute transform translate-x-1 translate-y-full text-secondary"
      >{{ icon }}</Icon
    >
    <input
      @input="update($event)"
      @keydown="$emit('keydown')"
      @keydown.enter.stop="focusNext()"
      @keydown.up="$emit('keydown-up')"
      @keydown.down="$emit('keydown-down')"
      @focus="handleFocus($event)"
      @blur="handleBlur()"
      :value="modelValue"
      :inputmode="inputmode"
      :type="type"
      :autocomplete="autocomplete"
      :id="id"
      ref="textfield"
      class="w-full pt-6 pb-2 pr-4 bg-gray-200 border-b-2 rounded-t focus:border-secondary"
      :class="{
        'pl-4': !icon,
        'pl-6': icon,
        'hover:bg-gray-300 border-black': !disabled,
        'text-gray-400 border-gray-400': disabled,
      }"
      :disabled="disabled"
    />
    <label
      class="absolute origin-left top-0 left-0 text-lg transition-transform duration-200 ease-in-out transform pointer-events-none focus:border-secondary"
      :class="{
        'text-gray-800': !disabled,
        'text-gray-400': disabled,
      }"
      :style="labelStyle"
      :for="id"
    >
      <slot />
    </label>
    <p
      v-if="!noValidate"
      class="h-5 text-xs text-gray-700 sca"
      :class="{ 'text-red-600': error }"
    >
      {{ error ? error : helper }}
    </p>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, computed, defineComponent, Ref } from 'vue'

export default defineComponent({
  name: 'Textfield',
  props: {
    modelValue: {
      type: [Number, String],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    id: {
      type: String,
      default: 'text',
    },
    error: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    helper: {
      type: String,
      default: '',
    },
    noValidate: {
      type: Boolean,
      default: false,
    },
    noFocusNext: {
      type: Boolean,
      default: false,
    },
    hasFocus: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'on',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
    'keydown',
    'keydown-enter',
    'keydown-down',
    'keydown-up',
    'blur',
  ],
  setup: (props, context) => {
    const textfield: Ref<HTMLInputElement | undefined> = ref()
    const div: Ref<HTMLDivElement | undefined> = ref()
    const focused = ref(false)
    const focus = () => {
      textfield?.value?.focus()
    }
    const focusNext = () => {
      context.emit('keydown-enter')
      if (props.noFocusNext) return
      let focussableElements =
        'input:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])'
      let focussable = Array.prototype.filter.call(
        document.querySelectorAll(focussableElements),
        function (element) {
          //check for visibility while always include the current activeElement
          return (
            element.offsetWidth > 0 ||
            element.offsetHeight > 0 ||
            element === document.activeElement
          )
        },
      )
      let index = focussable.indexOf(document.activeElement)
      if (index > -1) {
        let nextElement = focussable[index + 1] || focussable[0]
        nextElement.focus()
      }
    }
    const handleFocus = (event: FocusEvent) => {
      ;(<HTMLInputElement>event.target)?.select()
      focused.value = true
    }
    const handleBlur = () => {
      focused.value = false
      context.emit('blur')
    }
    const labelStyle: any = computed(() => {
      return {
        '--tw-translate-x':
          props.type == 'number' || focused.value || props.modelValue
            ? '.5rem'
            : props.icon
            ? '1.5rem'
            : '1rem',
        '--tw-translate-y':
          props.type == 'number' || focused.value || props.modelValue
            ? 0
            : props.icon
            ? '1.25rem'
            : '1rem',
        '--tw-scale-x':
          props.type == 'number' || focused.value || props.modelValue
            ? 0.65
            : 1,
        '--tw-scale-y':
          props.type == 'number' || focused.value || props.modelValue
            ? 0.65
            : 1,
      }
    })

    const inputmode = computed(() => {
      if (props.type == 'number') return 'decimal'
      return undefined
    })

    const update = (event: Event) => {
      context.emit('update:modelValue', (<HTMLInputElement>event.target).value)
    }
    onMounted(() => {
      if (props.hasFocus) {
        setTimeout(() => {
          focus()
        }, 300)
      }
    })

    return {
      focused,
      focus,
      focusNext,
      handleFocus,
      handleBlur,
      labelStyle,
      update,
      textfield,
      inputmode,
    }
  },
})
</script>

<style scoped>
input:focus {
  outline: none;
}
</style>
