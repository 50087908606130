<template>
  <div
    @click.stop="handleClick()"
    class="relative flex flex-row items-center"
    :class="{
      'cursor-pointer': !disabled,
    }"
    :id="id"
  >
    <div
      id="track"
      class="h-4 rounded-full w-8 overflow-visible"
      :class="{
        'bg-secondary-light': modelValue,
        'bg-gray-400': !modelValue,
        'bg-opacity-50': disabled,
        'cursor-pointer': !disabled,
      }"
    >
      <div
        id="slider"
        class="absolute top-1/2 left-0 transform -translate-y-1/2 h-5 w-5 rounded-full shadow transtion-transform duration-200"
        :class="{
          'bg-secondary translate-x-4': modelValue,
          'bg-gray-600 -translate-x-1': !modelValue,
          'bg-opacity-50': disabled,
        }"
      />
      <div
        id="slider-focus-overlay"
        class="absolute top-1/2 left-0 transform -translate-y-1/2 h-12 w-12 rounded-full transtion-transform duration-200 bg-opacity-10"
        :class="{
          'bg-secondary translate-x-1': modelValue,
          'bg-gray-600 -translate-x-5': !modelValue,
          'opacity-0': !focused,
        }"
      />
      <input
        @click.stop="$emit('change', !modelValue)"
        @focus="focused = true"
        @blur="focused = false"
        type="checkbox"
        :checked="!!modelValue"
        ref="input"
        :id="`${id}-input`"
        class="opacity-0 pointer-events-none"
        :disabled="disabled"
      />
    </div>
    <span v-if="$slots.default" class="ml-2">
      <slot />
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'ToggleSwitch',
  props: {
    modelValue: {
      type: [Boolean, Number],
      default: false,
    },
    id: {
      type: String,
      default: 'switch',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const focused = ref(false)
    const input = ref<HTMLInputElement>()

    const handleClick = () => {
      if (props.disabled) return
      input?.value?.focus()
      focused.value = true
      context.emit('update:modelValue', !props.modelValue)
    }

    return { focused, handleClick }
  },
})
</script>
