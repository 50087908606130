<template></template>

<script lang="ts" setup>
import { computed, inject, onMounted, PropType, Ref, watch } from 'vue'
import { useProvidedGoogleMapsData } from '~/composables/useGoogleMaps'

const props = defineProps({
  modelValue: {
    type: [Object, undefined] as PropType<
      { lat: number; lng: number } | undefined
    >,
    default: () => undefined,
  },
  //THIS WILL NOT BE REACTIVE
  disabled: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const { api, map } = useProvidedGoogleMapsData()

if (!map) console.error('Map marker missing map')
if (!api) console.error('Map marker missing google')

let marker: google.maps.marker.AdvancedMarkerElement | undefined = undefined

const modelValue = computed(() => props.modelValue)

watch(
  modelValue,
  () => {
    if (!api || !map) return
    if (!marker) {
      marker = new api.maps.marker.AdvancedMarkerElement({
        position: props.modelValue,
        map: map,
        gmpDraggable: !props.disabled,
      })
    }
    if (!props.modelValue?.lat || !props.modelValue?.lng) {
      marker.position = null
    }
    marker.position = <{ lat: number; lng: number }>props.modelValue
    map.setCenter(<{ lat: number; lng: number }>props.modelValue)
  },
  {
    deep: true,
  },
)

const emits = defineEmits(['update:model-value'])

onMounted(() => {
  if (!api || !map) return
  marker = new api.maps.marker.AdvancedMarkerElement({
    position: props.modelValue,
    map: map,
    gmpDraggable: !props.disabled,
  })
  marker?.addListener('dragend', () => {
    const position = marker?.position
    emits('update:model-value', {
      lat: position?.lat,
      lng: position?.lng,
    })
  })
  if (props.modelValue?.lat && props.modelValue?.lng) {
    map?.setCenter(<{ lat: number; lng: number }>props.modelValue)
  }
})
</script>
