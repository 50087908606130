<template>
  <div
    class="fixed bottom-0 flex flex-row justify-around w-full max-w-sm transform -translate-x-1/2 rounded-t-lg shadow left-1/2 bg-secondary bg-opacity-90 text-on-secondary"
  >
    <IconButton @click="$router.go(-1)" :icon="$icons.BACK">back</IconButton>
    <IconButton @click="lookup()" :icon="$icons.SEARCH">lookup</IconButton>
    <IconButton @click="$emit('add')" :icon="$icons.ADD_CIRCLE" large
      >add</IconButton
    >
    <IconButton @click="$router.push('/jobs')" :icon="$icons.JOBS"
      >jobs</IconButton
    >
    <IconButton
      @click="$router.push('/equipment')"
      :icon="$icons.EQUIPMENT"
      :disabled="!!offline"
      >equipment</IconButton
    >
    <IconButton
      @click="$router.push('/shopping_list')"
      :icon="$icons.SHOPPING_LIST"
      :disabled="!!offline"
      >shopping</IconButton
    >
  </div>
</template>

<script lang="ts" setup>
import { useModals } from '~/plugins/modals'
import { useRouter } from 'vue-router'
import { PropType } from 'vue'
import IconButton from './IconButton.vue'
import useOffline from '~/composables/useOffline'
import SelectJobModal from '~/modals/SelectJobModal.vue'
import LookupJobModal from '~/modals/LookupJobModal.vue'
import Job from '~/database/models/Job'

const props = defineProps({
  customClock: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const offline = useOffline()

const modals = useModals()
const router = useRouter()

async function lookup() {
  const modal = offline.value ? SelectJobModal : LookupJobModal
  const job = <Job | undefined>await modals.show(modal)
  if (!job?.id) return
  router.push(`/jobs/${job.id}`)
}
</script>
