<template>
  <Modal :job="job">
    <template v-if="!!job && !job.job_template_id" #subTitle>
      {{ $getField('categories', job?.category_id || '', 'name') }} Job Template
    </template>
    <template #title>Job Status</template>
    <div class="flex flex-col items-center">
      <button
        v-if="all"
        @click="select('all')"
        class="flex flex-row items-center justify-center w-64 py-2 my-1 text-lg font-bold bg-white border-2 rounded hover:bg-gray-100"
      >
        All
      </button>
      <button
        v-for="status in filteredStatuses"
        @click="select(status.id)"
        :key="status.id"
        class="flex flex-row items-center justify-center w-64 py-2 my-1 text-lg font-bold border-2 rounded"
        :class="{
          shadow: isSelected(status.id),
          'bg-white hover:bg-gray-100': !isSelected(status.id),
        }"
        :style="getStatusStyle(status)"
      >
        {{ status.name }}
      </button>
    </div>
    <template #actions>
      <CloseButton @click="$modals.close()" />
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { computed, PropType, StyleValue } from 'vue'
import getContrastColor from '~/assets/constants/getContractsColor'
import CloseButton from '~/components/CloseButton.vue'
import Modal from '~/components/Modal.vue'
import Job from '~/database/models/Job'
import Status from '~/database/models/Status'
import { useModals } from '~/plugins/modals'
import { useStore } from '~/plugins/store'

const props = defineProps({
  job: {
    type: [Object, undefined] as PropType<Job | undefined>,
    default: () => undefined,
  },
  selected_id: {
    type: [String, String] as PropType<string | number>,
    default: '',
  },
  category_id: {
    type: [Number, String] as PropType<string | number>,
    default: '',
  },
  all: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  jobActiveOnly: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  resolve: {
    type: Function as PropType<Function>,
    default: () => null,
  },
})

function isSelected(status_id: string) {
  return (props.job?.current_status_id || props.selected_id) == status_id
}

const store = useStore()
const filteredStatuses = computed(() => {
  const category_id = props.category_id || props.job?.category_id
  const category = category_id
    ? store.categories.record.value[category_id]
    : undefined
  return store.statuses.set.value.filter((status) => {
    if (isSelected(status.id)) return true
    if (props.jobActiveOnly && !status.job_active) return false
    return (
      status.active &&
      (!category?.status_list ||
        category.status_list.some((id) => {
          return id == status.id
        }))
    )
  })
})

const modals = useModals()
function select(status_id: string) {
  props.resolve(status_id)
  modals.close()
}

function getStatusStyle(status: Status): StyleValue {
  const statusSelected = isSelected(status.id)
  return {
    backgroundColor: statusSelected ? status.color : '',
    color: statusSelected ? getContrastColor(status.color) : status.color,
    borderColor: status.color,
  }
}
</script>
