<template>
  <Modal>
    <template #title>{{ title }}</template>
    <TabBar v-model="search.status" @update:model-value="fetchData()" small>
      <TabBarTab value="all">All</TabBarTab>
      <TabBarTab value="active">Active</TabBarTab>
      <template #content>
        <div
          class="flex flex-col-reverse overflow-y-auto"
          style="height: 20rem"
        >
          <div
            v-for="(equipment, index) in options"
            @click="select(equipment)"
            :key="equipment.id"
            ref="equipmentDivs"
            class="px-4 py-1 cursor-pointer hover:bg-gray-200"
            :class="{
              'bg-gray-200': isSelected(equipment) || index == currentIndex,
            }"
          >
            {{ equipment.name }}
            <div class="text-xs italic text-gray-600">
              {{ equipment.description }}
            </div>
          </div>
        </div>
      </template>
    </TabBar>
    <div class="flex flex-row items-center">
      <Textfield
        v-model="search.search"
        @update:model-value="handleSearchInput()"
        @keydown-enter="select()"
        @keydown-up="incrementIndex()"
        @keydown-down="decrementIndex()"
        :icon="$icons.SEARCH"
        autocomplete="off"
        class="w-full max-w-xs mt-2"
        has-focus
        no-validate
        no-focus-next
      >
        Search
      </Textfield>
      <FadeTransition>
        <IconSpinner v-if="loading" />
      </FadeTransition>
    </div>
    <template #actions class="mx-12">
      <CloseButton @click="$modals.close" />
      <Button @click="select()" raised>Select</Button>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { computed, PropType, Ref, ref } from 'vue'
import { useModals } from '~/plugins/modals'
import { useLoader } from '~/plugins/loader'
import { useModelPaginated } from '~/database'
import Equipment from '~/database/models/Equipment'
import useOffline from '~/composables/useOffline'
import Modal from '~/components/Modal.vue'
import TabBar from '~/components/TabBar.vue'
import TabBarTab from '~/components/TabBarTab.vue'
import Textfield from '~/components/Textfield.vue'
import FadeTransition from '~/components/FadeTransition.vue'
import IconSpinner from '~/components/IconSpinner.vue'
import CloseButton from '~/components/CloseButton.vue'
import Button from '~/components/Button.vue'

const props = defineProps({
  resolve: {
    type: Function,
    default: () => null,
  },
  createEnabled: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  equipment_id: {
    type: [Number, String] as PropType<string | number>,
    default: '',
  },
  status: {
    type: [Number, String] as PropType<string>,
    default: 'active',
  },
  title: {
    type: String as PropType<string>,
    defualt: 'Select Equipment',
  },
  subtitle: {
    type: String as PropType<string>,
    defualt: '',
  },
})

const currentIndex = ref(0)
const loader = useLoader()

//search
const search: Ref<Record<string, any>> = ref({
  status: 'active',
  search: '',
  per_page: 10,
})
const {
  searchResults,
  handleSearchInput,
  nextPage,
  previousPage,
  fetchData,
  currentPage,
  lastPage,
  loading,
} = useModelPaginated<Equipment>(Equipment, search, useOffline())

const options = computed(() => {
  if (!props.createEnabled) return searchResults.value
  return [
    { id: 'create', name: 'Add Equipment', description: '' },
    ...searchResults.value,
  ]
})

//selection
const modals = useModals()
async function select(
  equipment: Equipment | undefined | { name: string; id: string } = undefined,
) {
  if (!equipment) equipment = options.value[currentIndex.value]
  if (equipment?.id != 'create') {
    loader.show()
    await Equipment.fetchItem(equipment.id)
    loader.hide()
  }
  props.resolve(equipment)
  modals.close()
}

function incrementIndex() {
  if (currentIndex.value == options.value.length - 1) {
    currentIndex.value = 0
    return
  }
  currentIndex.value++
  scrollCurrentCustomerIntoView()
}
function decrementIndex() {
  if (currentIndex.value == 0) {
    currentIndex.value = options.value.length - 1
    return
  }
  currentIndex.value--
  scrollCurrentCustomerIntoView()
}
const equipmentDivs: Ref<HTMLDivElement[] | undefined> = ref()
function scrollCurrentCustomerIntoView() {
  if (!equipmentDivs.value) return
  const el = equipmentDivs.value[currentIndex.value]
  if (!el) return
  el.scrollIntoView()
}
function isSelected(equipment: Equipment | { name: string; id: string }) {
  if (!props.equipment_id) return
  return equipment.id == props.equipment_id
}
fetchData()
</script>
