<template>
  <RouterLink
    :to="link"
    class="
      p-4
      my-1
      bg-primary bg-opacity-0
      flex flex-row
      items-center
      rounded
      border
    "
    :class="{
      'hover:bg-opacity-10 focus:bg-opacity-10 transition-colors duration-300 ease-in-out cursor-pointer border-primary':
        !disabled,
      'cursor-default text-gray-400': disabled,
    }"
  >
    <Icon
      class="mr-2"
      :class="{
        'text-tertiary': !disabled,
        'text-gray-400': disabled,
      }"
      >{{ icon }}</Icon
    >
    <slot />
  </RouterLink>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'MenuRouterLink',
  props: {
    to: {
      type: String,
      default: '/',
    },
    icon: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const link = computed(() => {
      if (props.disabled) return ''
      return props.to
    })
    return { link }
  },
})
</script>

<style scoped></style>
