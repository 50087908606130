import { z } from 'zod'
import Model from '../Model'

export default class JobStatus extends Model {
  static readonly tableName: string = 'jobStatuses'
  static readonly apiEndPoint: string = 'api/job_statuses'
  static readonly sortKey: string = 'date'
  static readonly sortDirection = 'desc'
  // static readonly bypassPendingRequests:
  //   | 'all'
  //   | ('delete' | 'saveMany' | 'create' | 'update')[] = []

  job_id: string | number = ''
  status_id: string | number = ''
  user_id: string | number = ''
  date: Date | string = new Date()

  static ZodValidator = z.object({
    job_id: z.coerce
      .string({
        required_error: 'The job field is required.',
      })
      .min(1, 'The job field is required.'),
    status_id: z.coerce
      .string({
        required_error: 'The status field is required.',
      })
      .min(1, 'The status field is required.'),
    date: z.union([z.date(), z.string()]).nullable(),
  })

  constructor(payload: Partial<JobStatus> = {}) {
    super(payload)
    Object.assign(this, payload)
  }
}
