<template>
  <Modal>
    <template #title>Login</template>
    <Textfield
      v-model="user.email"
      @keydown="errors.email = ''"
      id="email"
      type="email"
      :error="errors.email"
      has-focus
      >Email</Textfield
    >
    <Textfield
      v-model="user.password"
      @keydown="errors.password = ''"
      @keydown-enter="submit"
      id="password"
      type="password"
      :error="errors.password"
      no-focus-next
      >Password</Textfield
    >
    <a :href="passwordResetUrl">Forgot Password?</a>
    <template #actions>
      <CloseButton @click="$modals.close()" />
      <Button @click="submit" raised>Submit</Button>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { AxiosError } from 'axios'
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import Button from '~/components/Button.vue'
import CloseButton from '~/components/CloseButton.vue'
import Textfield from '~/components/Textfield.vue'
import useApiErrorHandling from '~/composables/useApiErrorHandling'
import { useAuth } from '~/plugins/auth'
import { useDatabase } from '~/plugins/database'
import { useLoader } from '~/plugins/loader'
import { useModals } from '~/plugins/modals'

const auth = useAuth()
const modals = useModals()
const router = useRouter()
auth.getToken()
const { errors, handleErrors } = useApiErrorHandling(['email', 'password'])
const user = ref({
  email: '',
  password: '',
})
const store = useDatabase()
const loader = useLoader()
async function submit() {
  loader.show()
  const success = await auth
    .login(user.value)
    .catch((e: AxiosError) => handleErrors(e))
  if (!success) return loader.hide()
  await auth.getUser()
  await store.initialize()
  loader.hide()
  router.push('/')
  modals?.close()
}

const passwordResetUrl = computed(() => {
  return `${import.meta.env.VITE_API_URL}/forgot-password`
})
</script>

<style scoped></style>
