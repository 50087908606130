<template>
  <div>
    <div class="relative">
      <MenuButton
        @click="select"
        :class="{ 'border-secondary': !disabled, 'border-gray-200': disabled }"
        :disabled="disabled"
        :icon="$icons.CLIENTS"
        style="height: 3.5rem"
        >{{ title }}</MenuButton
      >
      <div
        class="absolute top-0 left-0 origin-left transform pointer-events-none text-lg focus:border-secondary transition-transform duration-200 ease-in-out"
        :class="{ 'text-gray-800': !disabled, 'text-gray-400': disabled }"
        :style="labelStyle"
      >
        <slot />
      </div>
      <p
        v-if="!all && !noValidate"
        class="text-xs text-gray-700 h-6"
        :class="{ 'text-red-600': error }"
      >
        {{ error ? error : helper }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType, useSlots } from 'vue'
import { useGetField } from '~/plugins/getField'
import { useModals } from '~/plugins/modals'
import MenuButton from './MenuButton.vue'
import LookupCustomerModal from '~/modals/LookupCustomerModal.vue'
import Customer from '~/database/models/Customer'
import CustomerModal from '~/modals/CustomerModal.vue'

const props = defineProps({
  modelValue: {
    type: [Number, String] as PropType<string | number>,
    default: '',
  },
  error: {
    type: String as PropType<string>,
    default: '',
  },
  helper: {
    type: String as PropType<string>,
    default: '',
  },
  subtitle: {
    type: String as PropType<string>,
    default: '',
  },
  all: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  nullable: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  disabled: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  noValidate: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  createEnabled: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const labelStyle: any = computed(() => {
  return {
    '--tw-translate-x': props.modelValue || props.all ? '.5rem' : '2.5rem',
    '--tw-translate-y': props.modelValue || props.all ? 0 : '1rem',
    '--tw-scale-x': props.modelValue || props.all ? 0.65 : 1,
    '--tw-scale-y': props.modelValue || props.all ? 0.65 : 1,
  }
})

const getField = useGetField()
const title = computed(() => {
  if (!props.modelValue) return props.all ? 'All' : ''
  return getField('customers', String(props.modelValue), 'name')
})

const modals = useModals()

const slots = useSlots()
const emits = defineEmits(['update:model-value'])

async function select() {
  if (props.disabled) return
  const selected = <
    Customer | { name: 'Create'; id: 'create' } | undefined | 'All' | 'null'
  >await modals.show(LookupCustomerModal, {
    title: String(slots.default ? slots.default()[0].children : ''),
    all: props.all,
    nullable: props.nullable,
    createEnabled: props.createEnabled,
    selected_id: props.modelValue,
  })
  if (!selected) return
  if (props.all && selected == 'All') {
    emits('update:model-value', null)
  } else if (props.nullable && selected == 'null') {
    emits('update:model-value', null)
  } else if (
    props.createEnabled &&
    typeof selected == 'object' &&
    selected?.id == 'create'
  ) {
    return addCustomer()
  } else if (typeof selected == 'object')
    emits('update:model-value', selected.id)
}

async function addCustomer() {
  const customer = <Customer | undefined>await modals.show(CustomerModal, {})
  if (!customer?.id) return
  emits('update:model-value', customer.id)
}
</script>

<style scoped></style>
