<template>
  <div
    class="flex flex-col items-center w-full h-full px-1 pb-1 mx-1 border-2 rounded"
    :style="{
      borderColor: status.color,
      minWidth: '16rem',
    }"
  >
    <div
      class="my-4 text-xl font-bold"
      :style="{
        color: status.color,
      }"
    >
      {{ status.name }}
    </div>
    <div
      :id="`sortable-list-${status.id}`"
      class="flex-grow w-full px-1 overflow-y-auto"
    >
      <ListItem
        v-for="job in columnJobs"
        @click="open(job)"
        :key="job.id"
        :id="job.id"
        class="border-gray-400 rounded-t-sm"
      >
        <template v-slot="{ hover }">
          <div class="flex flex-row">
            <div class="w-full">
              <div class="text-sm font-semibold">
                {{ job.customer_name }}
                <span :style="{ color: job.job_template_color ?? '#0C3B8D' }">{{
                  job.address_string
                }}</span>
              </div>
              <div
                class="text-sm font-semibold"
                :style="{ color: job.job_template_color ?? 'black' }"
              >
                {{ job.job_template_name }}
              </div>
              <JobInfo :job="job" extra-small vertical dashboard-view />
              <div class="flex flex-row flex-wrap">
                <div
                  class="flex flex-row justify-end ml-auto"
                  :class="{
                    'text-gray-200': !hover,
                    'text-secondary': hover,
                  }"
                >
                  <IconButton
                    v-if="$can('edit', 'jobs')"
                    @click.stop="editStatus(job)"
                    :icon="$icons.STATUSES"
                    small
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </ListItem>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, PropType, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useCan } from '~/plugins/auth'
import { useModals } from '~/plugins/modals'
import { useLoader } from '~/plugins/loader'
import Sortable, { SortableEvent } from 'sortablejs'
import Status from '~/database/models/Status'
import { useModel } from '~/database'
import Job from '~/database/models/Job'
import useApiErrorHandling from '~/composables/useApiErrorHandling'
import SelectStatusModal from '~/modals/SelectStatusModal.vue'
import useOffline from '~/composables/useOffline'
import ListItem from './ListItem.vue'
import JobInfo from './JobInfo.vue'
import IconButton from './IconButton.vue'

const props = defineProps({
  status: {
    type: Object as PropType<Status>,
    required: true,
  },
  category_id: {
    type: [Number, String] as PropType<string | number>,
    default: '',
  },
})

const isMobile = navigator.userAgent.match(
  /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i,
)

const { set, record } = useModel<Job>(Job, [])
const columnJobs = computed(() => {
  return set.value
    .filter((job) => {
      if (job.is_template) return false
      if (props.category_id && job.category_id != props.category_id) {
        return false
      }
      return job.current_status_id == props.status.id
    })
    .sort((a, b) => {
      if (a.sort > b.sort) return 1
      else if (a.sort < b.sort) return -1
      else return 0
    })
})

const { handleErrors } = useApiErrorHandling()
const loader = useLoader()
const modals = useModals()
async function editStatus(job: Job) {
  const newStatusId = <string | number | undefined>await modals.show(
    SelectStatusModal,
    {
      job,
    },
  )
  if (!newStatusId) return
  loader.show()
  await new Job({
    ...job,
    current_status_id: newStatusId,
  })
    .save()
    .catch((e) => handleErrors(e))
  loader.hide()
}

const router = useRouter()
function open(job: Job) {
  router.push(`/jobs/${job.id}`)
}

const can = useCan()

let sortable: Sortable | undefined = undefined

const offline = useOffline()

async function initializeSortable(sort = false) {
  const el = document.querySelector(`#sortable-list-${props.status.id}`)
  if (!el) return console.error('Missing Sortable Element')
  sortable = new Sortable(<HTMLElement>el, {
    group: 'jobs',
    sort: sort,
    dataIdAttr: 'id',
    animation: 200,
    disabled: offline.value || !!isMobile || !can('edit', 'jobs'),
    onAdd: async (event: SortableEvent) => {
      if (!sortable) return
      const idList = sortable.toArray()
      const newId = idList.find(
        (id) => !columnJobs.value.some((job) => job.id == id),
      )
      if (!newId) return
      const currentStatus = props.status.id
      const mergedJobs: Partial<Job>[] = idList.map((id, index) => {
        let payload = {
          ...record.value[id],
          sort: index,
          current_status_id: currentStatus,
        }
        delete payload.items
        return payload
      })
      await Job.saveMany(mergedJobs)
    },
    onUpdate: async (event: SortableEvent) => {
      if (!sortable) return
      const idList = sortable.toArray()
      const mergedJobs: Partial<Job>[] = idList.map((id, index) => {
        let payload = {
          ...record.value[id],
          sort: index,
        }
        delete payload.items
        return payload
      })
      await Job.saveMany(mergedJobs)
    },
  })
}

const category_id = computed(() => props.category_id)

watch(category_id, () => {
  if (!sortable) return
  if (!props.category_id) return (sortable.options.sort = false)
  return (sortable.options.sort = true)
})

onMounted(() => {
  if (!!props.category_id) return initializeSortable(true)
  return initializeSortable()
})
</script>
