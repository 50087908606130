import {
  AllowedComponentProps,
  App,
  Component,
  Ref,
  VNodeProps,
  ref,
  shallowRef,
} from 'vue'

export interface ModalOptions {
  replace: boolean
}

export interface ModalPayload {
  modal: Component
  props: {
    resolve: Function
    reject: Function
  }
  options?: ModalOptions
}

let shown = shallowRef<ModalPayload[]>([])

type ComponentProps<C extends Component> = C extends new (...args: any) => any
  ? Omit<
      Omit<
        InstanceType<C>['$props'],
        keyof VNodeProps | keyof AllowedComponentProps
      >,
      'resolve'
    >
  : never
function showModal<Comp extends Component>(
  modal: Comp,
  props?: ComponentProps<Comp>,
  options?: ModalOptions,
) {
  return new Promise((resolve, reject) => {
    const index = shown.value.length - 1
    const payload: ModalPayload = {
      modal,
      props: {
        ...(props || {}),
        resolve,
        reject,
      },
    }
    if (index < 0 || !options?.replace) {
      shown.value = [...shown.value, payload]
    } else {
      shown.value = [...shown.value.slice(0, index), payload]
    }
  })
}

function closeModal(closeAll = false) {
  if (closeAll) {
    shown.value = []
    return
  }
  const index = shown.value.length - 1
  if (index < 0) return
  shown.value[index].props.resolve(null)
  if (index == 0) {
    shown.value = []
    return
  }
  shown.value = shown.value.slice(0, index)
}

const modals = {
  shown,
  show: showModal,
  close: closeModal,
}

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $modals: typeof modals
  }
}

export const modalsInstall = {
  install: (app: App) => {
    app.config.globalProperties.$modals = modals
    app.provide('$modals', modals)
    app.provide('shownModals', modals.shown)
  },
}

export function useModals() {
  return modals
}

export function useShownModals() {
  return modals.shown
}
