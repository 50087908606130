import { App, ref } from 'vue'

const show = ref(false)
const message = ref('')
const resolve = ref(<Function | undefined>undefined)

const confirm = (confirmMessage: string) => {
  return new Promise((promiseResolve: Function) => {
    resolve.value = promiseResolve
    message.value = confirmMessage
    show.value = true
  })
}

export default {
  install: (app: App) => {
    app.config.globalProperties.$confirm = confirm
  },
}

export function useConfirm() {
  return confirm
}

export function useConfirmItems() {
  return { show, message, resolve }
}
