<template>
  <div
    @click="$emit('click')"
    class="flex-none rounded-full"
    :class="{
      'bg-gray-300': !computedStatus || !computedStatus.active,
      'h-6 w-6': !small,
      'h-3 w-3': small,
    }"
    :style="{
      backgroundColor:
        computedStatus && computedStatus.active
          ? computedStatus.color
          : '#A6A6A6',
    }"
  />
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'
import Status from '~/database/models/Status'
import { useStore } from '~/plugins/store'

const props = defineProps({
  //works with status or status_id
  status: {
    type: [Object, undefined] as PropType<Status | undefined>,
    default: undefined,
  },
  status_id: {
    type: [Number, String] as PropType<number | string>,
    default: null,
  },
  small: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})
const store = useStore()

const computedStatus = computed(() => {
  return props.status || store.statuses.record.value[props.status_id]
})
</script>
