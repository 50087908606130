<template>
  <Modal>
    <template #title>{{ title }}</template>
    <div class="mb-2 border border-tertiary">
      <div class="flex flex-col-reverse overflow-y-auto" style="height: 20rem">
        <div
          v-for="(customer, index) in customerOptions"
          @click="select(customer)"
          :key="customer.id"
          ref="customerDivs"
          class="flex flex-row items-center px-4 py-1 text-lg cursor-pointer hover:bg-gray-200"
          :class="{
            'bg-gray-200': isSelected(customer) || index == currentIndex,
          }"
        >
          {{ customer.name }}
        </div>
      </div>
    </div>
    <Textfield
      v-model="search"
      @update:model-value="executeSearch()"
      @keydown-enter="select()"
      @keydown-up="incrementIndex()"
      @keydown-down="decrementIndex()"
      :icon="$icons.SEARCH"
      autocomplete="off"
      has-focus
      no-validate
      no-focus-next
    >
      Name
    </Textfield>
    <template #actions class="mx-12">
      <CloseButton @click="$modals.close" />
      <Button @click="select()" raised>Select</Button>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { computed, PropType, Ref, ref } from 'vue'
import { useModals } from '~/plugins/modals'
import debounce from 'just-debounce-it'
import Customer from '~/database/models/Customer'
import Modal from '~/components/Modal.vue'
import Textfield from '~/components/Textfield.vue'

const props = defineProps({
  resolve: {
    type: Function as PropType<Function>,
    default: () => null,
  },
  createEnabled: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  nullable: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  all: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  selected_id: {
    type: [Number, String] as PropType<string | number>,
    default: '',
  },
  search: {
    type: String as PropType<string>,
    default: '',
  },
  title: {
    type: String as PropType<string>,
    defualt: 'Select Customer',
  },
})

const search = ref('')
const currentIndex = ref(0)

const results: Ref<Customer[]> = ref([])
const customerOptions = computed(() => {
  return [
    { name: 'Add New Customer', id: 'create' },
    { name: 'All', id: 'all' },
    { name: 'None', id: 'null' },
    ...results.value,
  ].filter((customer) => {
    if (!props.createEnabled && customer.id == 'create') return false
    if (!props.all && customer.id == 'all') return false
    if (!props.nullable && customer.id == 'null') return false
    return true
  })
})

//search
const executeSearch = debounce(async function () {
  const response = await Customer.fetchPaginatedDataFromApi<Customer>({
    per_page: 20,
    search: search.value,
  })
  if (!response?.data) return
  results.value = response.data
}, 500)

//selection
const modals = useModals()
function select(
  customer: Customer | undefined | { name: string; id: string } = undefined,
) {
  if (!customer) customer = customerOptions.value[currentIndex.value]
  props.resolve(customer)
  modals.close()
}
function incrementIndex() {
  if (currentIndex.value == customerOptions.value.length - 1) {
    currentIndex.value = 0
    return
  }
  currentIndex.value++
  scrollCurrentCustomerIntoView()
}
function decrementIndex() {
  if (currentIndex.value == 0) {
    currentIndex.value = customerOptions.value.length - 1
    return
  }
  currentIndex.value--
  scrollCurrentCustomerIntoView()
}
const customerDivs: Ref<HTMLDivElement[] | undefined> = ref()
function scrollCurrentCustomerIntoView() {
  if (!customerDivs.value) return
  const el = customerDivs.value[currentIndex.value]
  if (!el) return
  el.scrollIntoView()
}
function isSelected(customer: Customer | { name: string; id: string }) {
  if (!props.selected_id) return
  return customer.id == props.selected_id
}
executeSearch()
</script>
