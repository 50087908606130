<template>
  <Modal>
    <template #title> Edit Image </template>
    <div class="w-full" style="height: 50vh">
      <img
        :src="model.url"
        alt="Image Not Found"
        class="max-w-full max-h-full m-auto"
      />
    </div>
    <Textfield
      v-model="model.description"
      @keydown="errors.description = ''"
      :error="errors.description"
      id="description"
      class="w-full mt-1"
      >Description</Textfield
    >
    <template #actions>
      <CloseButton @click="$modals.close()" />
      <DeleteButton @click="deleteModel()" />
      <SaveButton @click="save()" />
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { AxiosError } from 'axios'
import { PropType } from 'vue'
import CloseButton from '~/components/CloseButton.vue'
import DeleteButton from '~/components/DeleteButton.vue'
import Modal from '~/components/Modal.vue'
import SaveButton from '~/components/SaveButton.vue'
import Textfield from '~/components/Textfield.vue'
import { useModelEdits } from '~/database'
import Image from '~/database/models/Image'

const props = defineProps({
  image: {
    type: Object as PropType<Partial<Image>>,
    default: () => ({}),
  },
  resolve: {
    type: Function as PropType<Function>,
    required: true,
  },
  axiosError: {
    type: [Object, undefined] as PropType<AxiosError | undefined>,
    default: () => undefined,
  },
  bypassQueue: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const { model, errors, showDelete, deleteModel, save } = useModelEdits<Image>(
  props.image,
  Image,
  ['description'],
  props.resolve,
  {
    axiosError: props.axiosError,
    bypassQueue: props.bypassQueue,
    deleteMessage: 'Delete this image?',
  },
)
</script>
