<template>
  <div>
    <div class="relative">
      <MenuButton
        @click="select"
        class="pt-6 pb-2"
        :class="{ 'border-secondary': !disabled, 'border-gray-200': disabled }"
        :disabled="disabled"
        style="height: 3.5rem"
        :icon="icon"
        >{{ title }}</MenuButton
      >
      <div
        class="absolute origin-left top-0 left-0 transform pointer-events-none text-lg focus:border-secondary transition-transform duration-200 ease-in-out"
        :class="{ 'text-gray-800': !disabled, 'text-gray-400': disabled }"
        :style="labelStyle"
      >
        <slot />
      </div>
      <p
        v-if="!all && !noValidate"
        class="text-xs text-gray-700 h-6"
        :class="{ 'text-red-600': error }"
      >
        {{ error ? error : helper }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, watch } from 'vue'
import SelectModal from '~/modals/SelectModal.vue'
import { useModals } from '~/plugins/modals'

export default defineComponent({
  name: 'SelectMenu',
  props: {
    modelValue: {
      type: [Number, String],
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    valueKey: {
      type: String,
      default: '',
    },
    labelKey: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    helper: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    all: {
      type: Boolean,
      default: false,
    },
    nullable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    noValidate: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    const labelStyle: any = computed(() => {
      return {
        '--tw-translate-x': props.modelValue || props.all ? '.5rem' : '1rem',
        '--tw-translate-y': props.modelValue || props.all ? 0 : '1rem',
        '--tw-scale-x': props.modelValue || props.all ? 0.65 : 1,
        '--tw-scale-y': props.modelValue || props.all ? 0.65 : 1,
      }
    })

    const selectedOption = computed(() => {
      if (!props.valueKey) return props.modelValue
      return props.options.find(
        (option: any) => option[props.valueKey] == props.modelValue,
      )
    })
    const options = computed(() => props.options)
    watch(options, () => {
      if (!selectedOption.value) context.emit('update:modelValue', '')
    })

    const title = computed(() => {
      if (!props.modelValue) return props.all ? 'All' : ''
      return props.labelKey
        ? selectedOption.value
          ? (<any>selectedOption.value)[props.labelKey]
          : props.modelValue
        : props.modelValue
    })

    const modals = useModals()
    if (!modals) return console.warn('Missing Modals')
    const select = async () => {
      if (props.disabled) return
      let options = props.options
      if (props.all) {
        options = [
          props.valueKey
            ? { [props.valueKey]: 'All', [props.labelKey]: 'All' }
            : 'All',
          ...props.options,
        ]
      }
      if (props.nullable) {
        options = [
          props.valueKey
            ? { [props.valueKey]: 'null', [props.labelKey]: '' }
            : '',
          ...props.options,
        ]
      }
      const selected = await modals.show(SelectModal, {
        title: String(
          context.slots.default ? context.slots.default()[0].children : '',
        ),
        subtitle: props.subtitle,
        options: options as any,
        valueKey: props.valueKey,
        labelKey: props.labelKey,
        selected: props.modelValue,
      })
      if (!selected) return
      if (props.all && selected == 'All') {
        context.emit('update:modelValue', '')
      } else if (props.nullable && selected == 'null') {
        context.emit('update:modelValue', null)
      } else context.emit('update:modelValue', selected)
    }

    return { labelStyle, selectedOption, title, select }
  },
})
</script>

<style scoped></style>
