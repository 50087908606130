<template>
  <Modal>
    <template #title>{{ title }}</template>
    <TabBar
      v-model="search.category_id"
      @update:model-value="fetchData()"
      small
    >
      <TabBarTab value="">All</TabBarTab>
      <TabBarTab
        v-for="category in filteredCategories"
        :key="category.id"
        :value="category.id"
        >{{ category.name }}</TabBarTab
      >
      <template #content>
        <div
          class="flex flex-col-reverse overflow-y-auto"
          style="height: 20rem"
        >
          <div
            v-for="(jobTemplate, index) in options"
            @click="select(jobTemplate)"
            :key="jobTemplate.id"
            ref="jobTemplateDivs"
            class="flex flex-row items-center px-4 py-1 cursor-pointer hover:bg-gray-200"
            :class="{
              'bg-gray-200': isSelected(jobTemplate) || index == currentIndex,
            }"
            :style="{ color: jobTemplate.color }"
          >
            {{ jobTemplate.name }}
          </div>
        </div>
      </template>
    </TabBar>
    <div class="flex flex-row items-center">
      <Textfield
        v-model="search.name"
        @update:model-value="handleSearchInput()"
        @keydown-enter="select()"
        @keydown-up="incrementIndex()"
        @keydown-down="decrementIndex()"
        :icon="$icons.SEARCH"
        autocomplete="off"
        class="w-full max-w-xs mt-2"
        has-focus
        no-validate
        no-focus-next
        >Name</Textfield
      >
      <FadeTransition>
        <IconSpinner v-if="loading" />
      </FadeTransition>
    </div>
    <template #actions class="mx-12">
      <CloseButton @click="$modals.close" />
      <Button @click="select()" raised>Select</Button>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { PropType, Ref, computed, ref } from 'vue'
import Button from '~/components/Button.vue'
import CloseButton from '~/components/CloseButton.vue'
import FadeTransition from '~/components/FadeTransition.vue'
import IconSpinner from '~/components/IconSpinner.vue'
import Modal from '~/components/Modal.vue'
import TabBar from '~/components/TabBar.vue'
import TabBarTab from '~/components/TabBarTab.vue'
import Textfield from '~/components/Textfield.vue'
import useOffline from '~/composables/useOffline'
import { useModelPaginated } from '~/database'
import Job from '~/database/models/Job'
import { useLoader } from '~/plugins/loader'
import { useModals } from '~/plugins/modals'
import { useStore } from '~/plugins/store'

const props = defineProps({
  resolve: {
    type: Function as PropType<Function>,
    default: () => null,
  },
  createEnabled: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  job_template_id: {
    type: [Number, String] as PropType<string | number>,
    default: '',
  },
  category_id: {
    type: [Number, String] as PropType<string | number>,
    default: '',
  },
  title: {
    type: String as PropType<string>,
    defualt: 'Select Job Template',
  },
})

const currentIndex = ref(0)
const loader = useLoader()

const store = useStore()
const filteredCategories = computed(() => {
  return store.categories.set.value.filter((category) => category.active)
})

//search
const search: Ref<Record<string, any>> = ref({
  templates_only: true,
  name: '',
  per_page: 10,
  category_id: props.category_id || '',
})
const {
  searchResults,
  handleSearchInput,
  nextPage,
  previousPage,
  fetchData,
  currentPage,
  lastPage,
  loading,
} = useModelPaginated<Job>(Job, search, useOffline())

const options = computed(() => {
  if (!props.createEnabled) return searchResults.value
  return [
    { id: 'create', name: 'Add New Template', color: '' },
    ...searchResults.value,
  ]
})

//selection
const modals = useModals()
async function select(
  jobTemplate: Job | undefined | { name: string; id: string } = undefined,
) {
  if (!jobTemplate) jobTemplate = options.value[currentIndex.value]
  if (jobTemplate?.id != 'create') {
    loader.show()
    await Job.fetchItem(jobTemplate.id)
    loader.hide()
  }
  props.resolve(jobTemplate)
  modals.close()
}
function incrementIndex() {
  if (currentIndex.value == options.value.length - 1) {
    currentIndex.value = 0
    return
  }
  currentIndex.value++
  scrollCurrentCustomerIntoView()
}
function decrementIndex() {
  if (currentIndex.value == 0) {
    currentIndex.value = options.value.length - 1
    return
  }
  currentIndex.value--
  scrollCurrentCustomerIntoView()
}
const jobTemplateDivs: Ref<HTMLDivElement[] | undefined> = ref()
function scrollCurrentCustomerIntoView() {
  if (!jobTemplateDivs.value) return
  const el = jobTemplateDivs.value[currentIndex.value]
  if (!el) return
  el.scrollIntoView()
}
function isSelected(jobTemplate: Job | { name: string; id: string }) {
  if (!props.job_template_id) return
  return jobTemplate.id == props.job_template_id
}
fetchData()
</script>
