<template>
  <div>
    <div class="relative">
      <MenuButton
        @click="select"
        :class="{ 'border-secondary': !disabled, 'border-gray-200': disabled }"
        class="pt-3"
        :disabled="disabled"
        :icon="$icons.EQUIPMENT"
        style="height: 3.5rem"
        >{{ title }}</MenuButton
      >
      <div
        class="absolute top-0 left-0 text-lg transition-transform duration-200 ease-in-out transform pointer-events-none focus:border-secondary"
        :class="{ 'text-gray-800': !disabled, 'text-gray-400': disabled }"
        :style="labelStyle"
      >
        Equipment
      </div>
      <p
        v-if="!all && !noValidate"
        class="h-6 text-xs text-gray-700"
        :class="{ 'text-red-600': error }"
      >
        {{ error ? error : helper }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, Prop, PropType, watch } from 'vue'
import useOffline from '~/composables/useOffline'
import { useGetField } from '~/plugins/getField'
import { useModals } from '~/plugins/modals'
import MenuButton from './MenuButton.vue'
import SelectEquipmentModal from '~/modals/SelectEquipmentModal.vue'
import LookupEquipmentModal from '~/modals/LookupEquipmentModal.vue'
import Equipment from '~/database/models/Equipment'

const props = defineProps({
  modelValue: {
    type: [Number, String] as PropType<string | number>,
    default: '',
  },
  error: {
    type: String as PropType<string>,
    default: '',
  },
  helper: {
    type: String as PropType<string>,
    default: '',
  },
  subtitle: {
    type: String as PropType<string>,
    default: '',
  },
  all: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  nullable: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  disabled: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  noValidate: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const labelStyle: any = computed(() => {
  return {
    '--tw-translate-x': props.modelValue || props.all ? 0 : '2.5rem',
    '--tw-translate-y': props.modelValue || props.all ? 0 : '1rem',
    '--tw-scale-x': props.modelValue || props.all ? 0.65 : 1,
    '--tw-scale-y': props.modelValue || props.all ? 0.65 : 1,
  }
})

const getField = useGetField()
const title = computed(() => {
  if (!props.modelValue) return props.all ? 'All' : ''
  return getField('equipment', props.modelValue, 'name')
})

const emits = defineEmits(['update:model-value'])

const offline = useOffline()

const modals = useModals()
async function select() {
  if (props.disabled) return
  const modal = offline.value ? SelectEquipmentModal : LookupEquipmentModal
  const selected = <Equipment | undefined>await modals.show(modal, {
    title: 'Equipment',
    subtitle: props.subtitle,
    equipment_id: props.modelValue,
  })
  if (!selected) return
  emits('update:model-value', selected.id)
}
</script>

<style scoped></style>
