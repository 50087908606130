<template>
  <div class="relative">
    <textarea
      @input="update($event)"
      @focus="focused = true"
      @blur="focused = false"
      :value="modelValue"
      :id="id"
      :rows="4"
      ref="input"
      class="w-full px-4 pt-6 pb-2 rounded-sm bg-gray-200 hover:bg-gray-300 border-2 border-black focus:border-secondary"
    />
    <label
      class="absolute origin-left top-0 left-0 transform pointer-events-none text-lg text-gray-800 focus:border-secondary transition-transform duration-200 ease-in-out"
      :style="labelStyle"
      :for="id"
    >
      <slot />
    </label>
    <p
      class="text-xs h-6"
      :class="{ 'text-red-600': error, 'text-gray-700': !error }"
    >
      {{ error ? error : helper }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'

const props = defineProps({
  modelValue: {
    type: [Number, String],
    default: '',
  },
  id: {
    type: String,
    default: 'text',
  },
  error: {
    type: String,
    default: '',
  },
  helper: {
    type: String,
    default: '',
  },
  hasFocus: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits(['update:modelValue'])

const input = ref<HTMLTextAreaElement>()
const focused = ref(false)
const focus = () => {
  input?.value?.focus()
}
const handleFocus = (event: FocusEvent) => {
  ;(<HTMLTextAreaElement>event.target)?.select()
  focused.value = true
}
const labelStyle: any = computed(() => {
  return {
    '--tw-translate-x': focused.value || props.modelValue ? '.5rem' : '1rem',
    '--tw-translate-y': focused.value || props.modelValue ? 0 : '1rem',
    '--tw-scale-x': focused.value || props.modelValue ? 0.65 : 1,
    '--tw-scale-y': focused.value || props.modelValue ? 0.65 : 1,
  }
})
const update = (event: Event) => {
  emits('update:modelValue', (<HTMLInputElement>event.target).value)
}
onMounted(() => {
  if (props.hasFocus) {
    setTimeout(() => input?.value?.focus(), 300)
  }
})
</script>

<style scoped>
textarea:focus {
  outline: none;
}
</style>
