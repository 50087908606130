<template>
  <span class="material-icons" :class="{ placeholder: placeholder }">
    <slot />
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Icon',
  props: {
    placeholder: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style scoped>
.x-large {
  font-size: 48px;
  padding: 0;
}

.large {
  font-size: 36px;
  padding: 0;
}

.small {
  font-size: 18px;
  padding: 0;
}

.placeholder {
  width: 24px;
  height: 24px;
}
</style>
