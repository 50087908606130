import { z } from 'zod'
import Model from '../Model'

export default class Task extends Model {
  static readonly tableName: string = 'tasks'
  static readonly apiEndPoint: string = 'api/tasks'
  static readonly sortKey: string = 'created_at'
  static readonly sortDirection = 'desc'
  static readonly bypassPendingRequests = []

  job_id: number | string = ''
  description: string = ''
  completed_at: Date | string = ''
  completed_id: number | string = ''

  static ZodValidator = z.object({
    job_id: z.coerce.string({
      required_error: 'The job field is required.',
    }),
    description: z
      .string({
        required_error: 'The description field is required.',
      })
      .min(1, 'description field is required.')
      .max(20000),
    completed_at: z.union([z.date(), z.string()]).nullable(),
  })

  constructor(payload: Partial<Task> = {}) {
    super(payload)
    Object.assign(this, payload)
  }
}
