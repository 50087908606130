<template>
  <Modal>
    <template #title>Offline</template>
    When offline, you will not have access to some portions of the app, but you
    will still be able to view and work with active jobs. Any notes, photos and
    other items you create while offline will be synced when you go back online.
    <template #actions>
      <CloseButton @click="modals.close()" />
      <Button @click="connect()" :icon="$icons.CONNECT" raised
        >Reconnect</Button
      >
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { useAlert } from '~/plugins/alert'
import { useLoader } from '~/plugins/loader'
import { useModals } from '~/plugins/modals'
import CloseButton from '~/components/CloseButton.vue'
import Button from '~/components/Button.vue'
import { useDatabase } from '~/plugins/database'

const database = useDatabase()

const loader = useLoader()
const alert = useAlert()
async function connect() {
  loader.show()
  const result = await database.connection.reconnect()
  loader.hide()
  if (result == 'success') return handleSuccess()
  else handleFailure()
}
const modals = useModals()
function handleSuccess() {
  alert('Connection Successful')
  modals.close()
}
function handleFailure() {
  alert('Unable to Connect')
}
</script>
