import { z } from 'zod'

export class CustomFieldValue {
  custom_field_id: string | number = ''
  value: string | boolean | number = ''
  // valuable_id: string | number = ''
  // valuable_type:
  //   | 'App\\Models\\Job'
  //   | 'App\\Models\\JobItem'
  //   | 'App\\Models\\Equipment' = 'App\\Models\\Job'

  // These will always be attached to their "valuable" so
  // we do not need to validate for it
  static ZodValidator = z.object({
    custom_field_id: z.coerce
      .string({
        required_error: 'The custom field is required.',
      })
      .min(1, 'The custom field field is required.'),
    value: z.any(),
  })
}
