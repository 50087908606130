import axios, { AxiosStatic } from 'axios'
declare global {
  interface Window {
    $axios: AxiosStatic
  }
}

export default {
  install: () => {
    ;(window as any).$axios = axios.create({
      withCredentials: true,
      baseURL: import.meta.env.VITE_API_URL,
    })
  },
}
