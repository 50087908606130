<template>
  <Layout
    @add="addJob()"
    container-class="flex flex-col w-full h-full max-w-full px-0 mx-0"
  >
    <TabBar v-model="category_id" class="my-4" colored>
      <TabBarTab value="">All</TabBarTab>
      <TabBarTab
        v-for="category in categorySet"
        :key="category.id"
        :value="category.id"
        >{{ category.name }}</TabBarTab
      >
    </TabBar>
    <div class="flex flex-row flex-grow w-full overflow-x-auto scrollbar">
      <StatusColumn
        v-for="status in filteredStatuses"
        @add.stop
        :key="status.id"
        :status="status"
        :category_id="category_id"
      />
    </div>
  </Layout>
</template>

<script lang="ts" setup>
import { computed, ref, watch, onMounted } from 'vue'
import { useRouter, onBeforeRouteLeave } from 'vue-router'
import Storage from '~/assets/constants/Storage'
import Layout from '~/components/Layout.vue'
import StatusColumn from '~/components/StatusColumn.vue'
import TabBar from '~/components/TabBar.vue'
import TabBarTab from '~/components/TabBarTab.vue'
import useOffline from '~/composables/useOffline'
import { useSettings } from '~/composables/useSettings'
import Job from '~/database/models/Job'
import JobModal from '~/modals/JobModal.vue'
import { useCan, useUser } from '~/plugins/auth'
import { useLoader } from '~/plugins/loader'
import { useModals } from '~/plugins/modals'
import { useStore } from '~/plugins/store'

//Navigation Gaurd
const user = useUser()
const router = useRouter()
if (!user?.value?.id) {
  router.push('/welcome')
}

const category_id = ref('')
const store = useStore()
const categorySet = computed(() => {
  return store.categories.set.value
})

const settings = useSettings()
const lastStatusId = settings.getSetting('last_status_id')

const offline = useOffline()
const filteredStatuses = computed(() => {
  const statuses = store.statuses.set.value.filter((status) => {
    if (!status.active) return false
    if (offline.value && !status.job_active) return false
    if (!category_id.value) return true
    const cateogry = store.categories.record.value[category_id.value]
    return cateogry.status_list.some((status_id) => {
      return status_id == status.id
    })
  })
  const index = !lastStatusId
    ? -1
    : statuses.findIndex((status) => {
        return status.id == lastStatusId
      })
  if (index < 0) return statuses
  return statuses.slice(0, index + 1)
})

const loader = useLoader()

async function getData() {
  if (!user.value?.id) return
  loader.show()
  let payload: {
    with_custom_field_values: true
    for_dashboard: true
    category_id?: string
  } = {
    for_dashboard: true,
    with_custom_field_values: true,
  }
  if (category_id.value) {
    payload.category_id = category_id.value
  }
  await Job.fetchData(payload)
  loader.hide()
}

const can = useCan()
const modals = useModals()

function addJob() {
  if (!can('edit', 'jobs') || offline.value) return
  modals.show(JobModal, {
    category_id: category_id.value,
  })
}

watch(category_id, () => {
  Storage.local.setItem('dashboard-category-id', category_id.value)
  getData()
})

let horizontalBar = {} as HTMLElement | null

onMounted(() => {
  horizontalBar = document.querySelector('.scrollbar')
  if (!horizontalBar) return
  let top = Storage.session.getItem('horizontal-scroll')
  if (!top) {
    horizontalBar.scrollLeft = 0
  } else {
    horizontalBar.scrollLeft = parseInt(top)
  }
})

window.addEventListener('beforeunload', () => {
  Storage.session.setItem('horizontal-scroll', horizontalBar?.scrollLeft)
})

onBeforeRouteLeave((to, from, next) => {
  Storage.session.setItem('horizontal-scroll', horizontalBar?.scrollLeft)
  next()
})

const id = Storage.local.getItem('dashboard-category-id')
category_id.value = id
</script>

<style scoped></style>
