<template>
  <div class="w-full">
    <Checkbox
      v-for="field in getFieldsForType('boolean')"
      @update:modelValue="setFieldValue(field.id, $event)"
      :modelValue="!!getFieldValue(field.id)"
      :key="field.id"
    >
      {{ field.name }}
    </Checkbox>
    <div
      v-for="field in getFieldsForType('select')"
      :key="field.id"
      class="flex flex-row items-center w-full my-1"
    >
      <SelectMenu
        v-if="field.options?.length"
        @update:modelValue="setFieldValue(field.id, $event)"
        :model-value="String(getFieldValue(field.id))"
        :options="field.options"
        class="flex-grow mr-1"
        no-validate
        >{{ field.name }}</SelectMenu
      >
      <IconButton
        v-if="$can('edit', 'custom_fields')"
        @click="addFieldOption(field)"
        :icon="$icons.ADD"
        >add</IconButton
      >
    </div>
    <Textfield
      v-for="field in getFieldsForType('string')"
      @update:modelValue="setFieldValue(field.id, $event)"
      :model-value="String(getFieldValue(field.id))"
      class="my-2"
      :id="`field-${field.id}`"
      :key="field.id"
      no-validate
    >
      {{ field.name }}
    </Textfield>
    <Textfield
      v-for="field in getFieldsForType('number')"
      @update:modelValue="setFieldValue(field.id, $event)"
      :model-value="String(getFieldValue(field.id))"
      type="number"
      class="my-2"
      :id="`field-${field.id}`"
      :key="field.id"
      no-validate
    >
      {{ field.name }}
    </Textfield>
    <PhoneNumberInput
      v-for="field in getFieldsForType('phoneNumber')"
      @update:modelValue="setFieldValue(field.id, $event)"
      :model-value="String(getFieldValue(field.id))"
      class="my-2"
      :id="`field-${field.id}`"
      :key="field.id"
    >
      {{ field.name }}
    </PhoneNumberInput>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { useModals } from '~/plugins/modals'
import Job from '~/database/models/Job'
import { CustomFieldValue } from '~/database/models/CustomFieldValue'
import { useStore } from '~/plugins/store'
import useApiErrorHandling from '~/composables/useApiErrorHandling'
import CustomField from '~/database/models/CustomField'
import TextfieldModal from '~/modals/TextfieldModal.vue'
import Checkbox from './Checkbox.vue'
import IconButton from './IconButton.vue'
import SelectMenu from './SelectMenu.vue'
import Textfield from './Textfield.vue'
import PhoneNumberInput from './PhoneNumberInput.vue'

const props = defineProps({
  job: {
    type: Object as PropType<Job | undefined>,
    default: () => undefined,
  },
  modelValue: {
    type: Array as PropType<CustomFieldValue[]>,
    default: () => [],
  },
  fieldable_id: {
    type: [Number, String] as PropType<string>,
    default: null,
  },
  fieldable_type: {
    type: [Number, String] as PropType<
      'App\\Models\\Job' | 'App\\Models\\Item' | 'App\\Models\\Equipment'
    >,
    required: true,
  },
})

const emits = defineEmits(['update:modelValue'])

//custom fields
const store = useStore()
const filteredCustomFields = computed(() => {
  return store.customFields.set.value.filter((field) => {
    if (props.fieldable_type != field.fieldable_type) return false
    if (props.fieldable_id && field.fieldable_id != props.fieldable_id)
      return false
    return !!getFieldValue(field.id) || field.active
  })
})

function getFieldsForType(type: string) {
  return filteredCustomFields.value.filter((p) => p.type == type)
}

function getFieldValue(custom_field_id: string) {
  const customFieldValue: CustomFieldValue | undefined = props.modelValue.find(
    (customFieldValue: CustomFieldValue) => {
      return customFieldValue.custom_field_id == custom_field_id
    },
  )
  return customFieldValue?.value || ''
}

function setFieldValue(custom_field_id: string, value: string | boolean) {
  let customFieldValues = JSON.parse(JSON.stringify(props.modelValue))
  const index = customFieldValues.findIndex(
    (customFieldValue: CustomFieldValue) =>
      customFieldValue.custom_field_id == custom_field_id,
  )
  const customFieldValue = customFieldValues[index]
  if (index < 0) customFieldValues.push({ custom_field_id, value })
  else customFieldValues.splice(index, 1, { ...customFieldValue, value })
  emits('update:modelValue', customFieldValues)
}

const modals = useModals()
const { handleErrors } = useApiErrorHandling()
async function addFieldOption(field: CustomField) {
  const newOption = <string | undefined>await modals.show(TextfieldModal, {
    subtitle: 'Custom Fields',
    title: `${field.name} Add Option`,
    label: 'Option',
  })
  if (!newOption) return
  const result = await new CustomField({
    ...field,
    options: [...(field.options || []), newOption],
  })
    .saveToApi()
    .catch((error) => handleErrors(error))
  if (result != 'success') return
  setFieldValue(field.id, newOption)
}
</script>
