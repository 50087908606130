import { findPhoneNumbersInText } from 'libphonenumber-js'

const clickToCall = (origText: string) => {
  let newText = origText
  const getPhoneNum = findPhoneNumbersInText(newText, 'US')
  if (newText?.length) {
    getPhoneNum.forEach((match) => {
      const pattern = origText.substring(match.startsAt, match.endsAt)
      newText = newText.replace(
        pattern,
        `<a href="tel:${match.number.number}">${formatPhoneNumber(
          pattern,
        )}</a>`,
      )
    })
  }
  return newText
}

const formatPhoneNumber = (value: string) => {
  if (!value) return value
  const phoneNumber = value.replace(/[^\d]/g, '').replace(/^1/g, '')
  const phoneNumberLength = phoneNumber.length
  if (phoneNumberLength < 4) return phoneNumber
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6,
  )}-${phoneNumber.slice(6, 10)}`
}

export default function usePhoneNumbers() {
  return {
    clickToCall,
    formatPhoneNumber,
  }
}

export function useFormatPhoneNumber() {
  return formatPhoneNumber
}
